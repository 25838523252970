import { DELETE_HTML_CONFIGURATION_MUTATION } from './useDeleteHtmlConfiguration.graphql';
import {
  IDeleteHtmlConfigurationMutationGraphQLResponse,
  IDeleteHtmlConfigurationMutationVariables,
  IHandleDeleteHtmlConfigurationParams,
  IUseDeleteHtmlConfigurationOptions,
  IUseDeleteHtmlConfiguration,
} from './useDeleteHtmlConfiguration.interfaces';

import { useKanziMutation } from '../../apollo';

const useDeleteHtmlConfiguration = (options?: IUseDeleteHtmlConfigurationOptions): IUseDeleteHtmlConfiguration => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteHtmlConfigurationOptions);

  const [executeDeleteHtmlConfiguration, { loading: isLoading }] = useKanziMutation<
    IDeleteHtmlConfigurationMutationGraphQLResponse,
    IDeleteHtmlConfigurationMutationVariables
  >({
    mutation: mutation ?? DELETE_HTML_CONFIGURATION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          htmlConfiguration: { deleteHtmlConfiguration: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteHtmlConfiguration = async ({ id }: IHandleDeleteHtmlConfigurationParams) => {
    await executeDeleteHtmlConfiguration({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteHtmlConfiguration,
    isLoading,
  };
};

export { useDeleteHtmlConfiguration };
