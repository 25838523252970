/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, MutationTuple, OperationVariables } from '@apollo/client';
import { getApolloLink, useApolloMutation, useCookie } from '@netfront/common-library';

import { IUseMuriquiMutationOptions } from './useMuriquiMutation.interfaces';

const useMuriquiMutation = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>({
  apolloLink,
  mutation,
  options,
  product,
  token,
}: IUseMuriquiMutationOptions<TData, TVariables, TContext>): MutationTuple<TData, TVariables, TContext, TCache> => {
  const { getAccessTokenCookie, getTemporaryTokenCookie } = useCookie();

  const accessToken = getAccessTokenCookie();
  const temporaryToken = getTemporaryTokenCookie();

  const authenticationLink = getApolloLink({
    apiName: 'muriqui',
    product,
    token: token ?? accessToken ?? temporaryToken,
  });

  return useApolloMutation<TData, TVariables, TContext, TCache>({
    apiName: 'muriqui',
    apolloLink: apolloLink ?? authenticationLink,
    mutation,
    options,
  });
};

export { useMuriquiMutation };
