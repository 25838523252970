import { getUpdatedApiKey } from '../apiKey.helpers';

import { GET_API_KEYS_BY_PROJECT_QUERY } from './useGetApiKeysByProject.graphql';
import {
  IGetApiKeysByProjectQueryGraphQLResponse,
  IGetApiKeysByProjectQueryVariables,
  IHandleGetApiKeysByProjectParams,
  IUseGetApiKeysByProject,
  IUseGetApiKeysByProjectOptions,
} from './useGetApiKeysByProject.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useGetApiKeysByProject = (options?: IUseGetApiKeysByProjectOptions): IUseGetApiKeysByProject => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetApiKeysByProjectOptions);

  const [executeGetApiKeysByProject, { loading: isLoading }] = useKanziLazyQuery<
    IGetApiKeysByProjectQueryGraphQLResponse,
    IGetApiKeysByProjectQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          apiKey: { getApiKeysByProject: apiKeys },
        } = data;

        const updatedApiKeys = apiKeys.map((apiKey) => getUpdatedApiKey(apiKey));

        onCompleted({
          apiKeys: updatedApiKeys,
        });
      },
      onError,
    },
    query: query ?? GET_API_KEYS_BY_PROJECT_QUERY,
    token,
  });

  const handleGetApiKeysByProject = ({ projectId }: IHandleGetApiKeysByProjectParams) => {
    executeGetApiKeysByProject({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetApiKeysByProject,
    isLoading,
  };
};

export { useGetApiKeysByProject };
