import { IIcon } from './Icons.interfaces';

const IconSpeech = ({ color }: IIcon) => {
  return (
    <svg
      aria-hidden="true"
      className="c-kanzi-action-button__icon js-kanzi-action-button-icon"
      style={{ color }}
      viewBox="0 0 24.054 22.666"
    >
      <g id="speech-inactive-icon" transform="translate(1 1)">
        <path
          d="M13.6,3.856c6.09,0,11.027,4.011,11.027,8.959S19.688,21.775,13.6,21.775a13.619,13.619,0,0,1-1.719-.109c-2.369,2.369-5.2,2.793-7.929,2.856v-.58c1.476-.723,2.757-2.04,2.757-3.546a4.16,4.16,0,0,0-.047-.618,8.435,8.435,0,0,1-4.089-6.963C2.571,7.867,7.508,3.856,13.6,3.856Z"
          data-name="Icon metro-bubble"
          fill="none"
          id="Icon_metro-bubble"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-2.571 -3.856)"
        />
      </g>
    </svg>
  );
};

export { IconSpeech };
