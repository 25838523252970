import { MutableRefObject, LegacyRef, useEffect, useRef } from 'react';

import { IUseLayoutPreviewerParams, IUseLayoutPreviewerResponse } from './LayoutPreviewer.interfaces';

const useLayoutPreviewer = ({ colour, position }: IUseLayoutPreviewerParams): IUseLayoutPreviewerResponse => {
  const previewerRef = useRef() as LegacyRef<SVGSVGElement>;

  const resetMarkers = (svg: SVGSVGElement) => {
    const markers = Array.from(svg.getElementsByClassName('js-previewer__marker') as HTMLCollectionOf<HTMLElement>);
    markers.forEach((marker) => (marker.style.fill = 'transparent'));
  };

  useEffect(() => {
    const selectedPosition = position.includes('FIXED_') ? position.split('FIXED_')[1] : position;

    const previewerNode = previewerRef as MutableRefObject<SVGSVGElement | null>;

    if (previewerNode.current !== null) {
      resetMarkers(previewerNode.current);

      const marker: SVGSVGElement | null = previewerNode.current.querySelector(`#${selectedPosition}`);
      if (marker !== null) {
        marker.style.fill = colour;
      }
    }
  }, [previewerRef, colour, position]);

  return {
    ref: previewerRef,
  };
};

export { useLayoutPreviewer };
