import { RestrictionType } from '../../../../interfaces';

const RESTRICTION_TYPE_FRIENDLY_LABEL_MAP: Record<RestrictionType, string> = Object.freeze({
  HTTP_REFERER: 'HTTP referer',
  IP_ADDRESS: 'IP address',
  NONE: 'None',
  MOBILE_APP: 'Mobile app',
});

export { RESTRICTION_TYPE_FRIENDLY_LABEL_MAP };
