import { gql } from '@apollo/client';

const DELETE_HTML_CONFIGURATION_MUTATION = gql`
  mutation deleteHtmlConfiguration($id: Int!) {
    htmlConfiguration {
      deleteHtmlConfiguration: remove(id: $id)
    }
  }
`;

export { DELETE_HTML_CONFIGURATION_MUTATION };
