import { ApiKeyContextDispatchAction } from './ApiKeyContext.interfaces';

import { getUpdatedApiKey } from '../../hooks';
import { IApiKey } from '../../interfaces';

const apiKeyReducer = (state: IApiKey[] | undefined, action: ApiKeyContextDispatchAction): IApiKey[] | undefined => {
  switch (action.type) {
    case 'addRestrictionToApiKey': {
      if (!state) {
        return [] as IApiKey[];
      }

      const {
        payload: { apiKeyId: payloadApiKeyId, restriction },
      } = action;

      return state.map((apiKey) => {
        const { id: apiKeyId, restrictions = [] } = apiKey;

        if (apiKeyId !== payloadApiKeyId) {
          return apiKey;
        }

        return getUpdatedApiKey({
          ...apiKey,
          restrictions: [...restrictions, restriction],
        });
      });
    }

    case 'deleteSelectedApiKey': {
      if (!state) {
        return [] as IApiKey[];
      }

      return state.filter(({ isSelected }) => !isSelected);
    }

    case 'saveApiKeys': {
      const {
        payload: { apiKeys },
      } = action;

      return apiKeys;
    }

    case 'removeHtmlConfigurationFromApiKey': {
      if (!state) {
        return [] as IApiKey[];
      }

      const {
        payload: { apiKeyId: payloadApiKeyId, htmlConfigurationId },
      } = action;

      return state.map((apiKey) => {
        const { id: apiKeyId, htmlConfigurations = [] } = apiKey;

        if (apiKeyId !== payloadApiKeyId) {
          return apiKey;
        }

        return {
          ...apiKey,
          htmlConfigurations: htmlConfigurations.filter(({ id }) => id != htmlConfigurationId),
        };
      });
    }

    case 'removeRestrictionFromApiKey': {
      if (!state) {
        return [] as IApiKey[];
      }

      const {
        payload: { apiKeyId: payloadApiKeyId, restrictionId },
      } = action;

      return state.map((apiKey) => {
        const { id: apiKeyId, restrictions = [] } = apiKey;

        if (apiKeyId !== payloadApiKeyId) {
          return apiKey;
        }

        return getUpdatedApiKey({
          ...apiKey,
          restrictions: restrictions.filter(({ id }) => id != restrictionId),
        });
      });
    }

    case 'setSelectedApiKeyId': {
      if (!state) {
        return [] as IApiKey[];
      }

      const {
        payload: { apiKeyId: payloadApiKeyId },
      } = action;

      return state.map((apiKey) => {
        const { id: apiKeyId } = apiKey;

        return {
          ...apiKey,
          isSelected: apiKeyId === payloadApiKeyId,
        };
      });
    }

    case 'updateApiKey': {
      if (!state) {
        return [] as IApiKey[];
      }

      const {
        payload: { apiKey: payloadApiKey },
      } = action;

      return state.map((apiKey) => {
        const { id: apiKeyId } = apiKey;

        if (apiKeyId !== payloadApiKey.id) {
          return apiKey;
        }

        return {
          ...apiKey,
          ...payloadApiKey,
        };
      });
    }

    case 'updateApiKeyHtmlConfigurations': {
      if (!state) {
        return [] as IApiKey[];
      }

      const {
        payload: { apiKeyId: payloadApiKeyId, htmlConfigurations },
      } = action;

      return state.map((apiKey) => {
        const { id: apiKeyId } = apiKey;

        if (apiKeyId !== payloadApiKeyId) {
          return apiKey;
        }

        return {
          ...apiKey,
          htmlConfigurations,
        };
      });
    }

    case 'updateApiKeyVoices': {
      if (!state) {
        return [] as IApiKey[];
      }

      const {
        payload: { apiKeyId: payloadApiKeyId, voices },
      } = action;

      return state.map((apiKey) => {
        const { id: apiKeyId } = apiKey;

        if (apiKeyId !== payloadApiKeyId) {
          return apiKey;
        }

        return {
          ...apiKey,
          voices,
        };
      });
    }

    default: {
      return state;
    }
  }
};

export { apiKeyReducer };
