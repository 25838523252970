import { GET_PLAN_QUERY } from './useGetPlan.graphql';
import {
  IGetPlanQueryGraphQLResponse,
  IGetPlanQueryVariables,
  IHandleGetPlanParams,
  IUseGetPlan,
  IUseGetPlanOptions,
} from './useGetPlan.interfaces';

import { useMuriquiLazyQuery } from '../../apollo';

const useGetPlan = (options?: IUseGetPlanOptions): IUseGetPlan => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPlanOptions);

  const [executeGetPlan, { loading: isLoading }] = useMuriquiLazyQuery<IGetPlanQueryGraphQLResponse, IGetPlanQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          plans: { getPlan: plan },
        } = data;

        onCompleted({
          plan,
        });
      },
      onError,
    },
    query: query ?? GET_PLAN_QUERY,
    token,
  });

  const handleGetPlan = async ({ id }: IHandleGetPlanParams) => {
    await executeGetPlan({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetPlan,
    isLoading,
  };
};

export { useGetPlan };
