import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { useRouter } from 'next/router';

import { ISendContactFormOnCompletedResponse, useSendContactForm, useToast } from '../../../hooks';
import { ContactForm, CONTACT_FORM_DEFAULTS } from '../../Forms';
import { PublicPageLayout } from '../../PublicPageLayout';

const ContactPage = () => {
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const handleSendContactFormCompleted = (data?: ISendContactFormOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { isCompleted } = data;

    if (isCompleted) {
      push(`/thank-you`).catch((error) => {
        handleToastError({
          error,
        });
      });

      return;
    }

    const {
      MESSAGES: {
        ERROR: { UNEXPECTED },
      },
    } = COMMON_LIBRARY_CONSTANTS;

    handleToastError({
      error: new Error(UNEXPECTED),
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleSendContactFormError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleSendContactForm, isLoading = false } = useSendContactForm({
    onCompleted: handleSendContactFormCompleted,
    onError: handleSendContactFormError,
  });

  return (
    <PublicPageLayout
      afterTitle="Get in touch with the Kanzi team using the contact form below."
      isPreloaderVisible={isLoading}
      meta={{
        metaTitle: 'Get in touch with the Kanzi tribe',
        description: 'Get in touch with the Kanzi team using the contact form below.',
      }}
      title={CONTACT_FORM_DEFAULTS.heading}
    >
      <div className="max-w-lg mx-auto">
        <ContactForm
          isSubmitting={isLoading}
          onSend={({ email, firstName, lastName, message: value, organisationName, phoneNumber }) =>
            handleSendContactForm({
              email,
              firstName,
              lastName,
              organisationName,
              phoneNumber,
              value,
            })
          }
        />
      </div>
    </PublicPageLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export { ContactPage };
