import { useState, useEffect, ChangeEvent } from 'react';

import { useCookie, useGeladaAccessTokenDecoder } from '@netfront/common-library';
import {
  DEFAULT_STORAGE_EXPIRY_OPTION,
  useDomain,
  useGetGeladaProject,
  useIdentitySiteUrls,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import { AvatarBreadcrumbSectionTemplate, InformationBox } from '@netfront/ui-library';
import { PageTemplate } from 'components';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { getProjectUsageTableData, getPdfUsageTableData } from './/ReportingPage.helpers';
import { REPORTING_PAGE_CONSTANTS } from './ReportingPage.constants';
import { IProjectUsageTableData, IPdfUsageTableData } from './ReportingPage.interfaces';
import { TranslateTableView, PdfTableView } from './ReportingPageViews';

import { useGetProject, useLastProjectVisited, useToast } from '../../../hooks';
import { IDBProject } from '../../../interfaces';
import { BUTTON_CLASSES } from '../../LinkButton';
import { getPlanUsagePercentage, getPlanUsageText, PlanUsage } from '../../PlanUsage';

const { pageTitle, tableSelectionItems } = REPORTING_PAGE_CONSTANTS;

const ReportingPage = () => {
  const { createLastProjectVisitedCookie, getAccessTokenCookie, isSecureCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getDecodedJwt, getJwtUserId } = useGeladaAccessTokenDecoder();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { getLastProjectVisitedPath } = useLastProjectVisited();
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    query: { organisationKey, projectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [kanziProject, setKanziProject] = useState<IDBProject>();
  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [geladaProjectName, setGeladaProjectName] = useState<string>();
  const [pdfUsageTableData, setPdfUsageTableData] = useState<IPdfUsageTableData[]>();
  const [projectUsageTableData, setProjectUsageTableData] = useState<IProjectUsageTableData[]>();
  const [selectedTable, setSelectedTable] = useState<string>(String(tableSelectionItems[0].value));
  const [userId, setUserId] = useState<number>();

  const accessToken = getAccessTokenCookie();

  const handleTableChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTable(value);
  };

  const { handleGetProject } = useGetProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ project }) => {
      setKanziProject(project);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetGeladaProject, isLoading: isGetProjectLoading = false } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject: { name } }) => {
      setGeladaProjectName(name);
      setHasRequiredPageData(true);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const decodedJwt = getDecodedJwt(accessToken);

    setUserId(getJwtUserId(decodedJwt));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (!(isAuthenticated && isDomainReady && organisationKey && projectId && userId)) {
      return;
    }

    setIdentityUrl(getBaseUrl());

    createLastProjectVisitedCookie({
      optionalCookieAttributesInput: {
        domain: getDomain(),
        secure: isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE),
        storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
      },
      value: getLastProjectVisitedPath(organisationKey, projectId, userId),
    });

    handleGetProject({
      id: String(projectId),
    });

    handleGetGeladaProject({
      projectId: String(projectId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady, organisationKey, projectId, userId]);

  const isLoading = isGetProjectLoading;

  return (
    <PageTemplate activePage="reporting" isLoading={isLoading} pageTitle={pageTitle}>
      <>
        {hasRequiredPageData && (
          <>
            {kanziProject && (
              <PlanUsage
                plans={[
                  {
                    color: 'var(--g-color-red-400)',
                    label: 'Translate usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedTranslate, kanziProject.remainingTranslate),
                    usage: getPlanUsageText(kanziProject.allowedTranslate, kanziProject.remainingTranslate),
                  },
                  {
                    color: 'var(--g-color-orange-200)',
                    label: 'Speech usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedSpeech, kanziProject.remainingSpeech),
                    usage: getPlanUsageText(kanziProject.allowedSpeech, kanziProject.remainingSpeech),
                  },
                  {
                    color: 'var(--g-color-blue-200)',
                    label: 'PDF usage',
                    percentage: getPlanUsagePercentage(kanziProject.allowedPdf, kanziProject.remainingPdf),
                    usage: getPlanUsageText(kanziProject.allowedPdf, kanziProject.remainingPdf),
                  },
                ]}
              />
            )}
            <div className="flex flex-col mb-8 lg:flex-row lg:items-center">
              {geladaProjectName ? (
                <AvatarBreadcrumbSectionTemplate
                  additionalClassNames="flex-1 mb-8 md:mb-0"
                  breadcrumbItems={[
                    {
                      key: '0',
                      content: (
                        <Link
                          className={`${BUTTON_CLASSES['subtle-link']} color-black`}
                          href={`${identityUrl}/dashboard`}
                        >
                          <span>Dashboard</span>
                        </Link>
                      ),
                    },
                    {
                      key: '1',
                      content: <span>{pageTitle}</span>,
                    },
                  ]}
                  title={geladaProjectName}
                />
              ) : null}
            </div>

            <InformationBox additionalClassNames="mb-8" iconId="id_info_icon" message="Export customised reports" />

            <div className="flex flex-1 flex-wrap -mx-4">
              {selectedTable === String(tableSelectionItems[0].value) ? (
                <TranslateTableView
                  selectedTable={selectedTable}
                  tableData={projectUsageTableData}
                  onProjectUserTableData={(edges) => {
                    setProjectUsageTableData(
                      getProjectUsageTableData({
                        projectUsageEdges: edges,
                      }),
                    );
                  }}
                  onTableChange={handleTableChange}
                />
              ) : (
                <PdfTableView
                  selectedTable={selectedTable}
                  tableData={pdfUsageTableData}
                  onPdfUserTableData={(edges) => {
                    setPdfUsageTableData(
                      getPdfUsageTableData({
                        pdfUsageEdges: edges,
                      }),
                    );
                  }}
                  onTableChange={handleTableChange}
                />
              )}
            </div>
          </>
        )}
      </>
    </PageTemplate>
  );
};

export { ReportingPage };
