import { gql } from '@apollo/client';

const PRODUCT_FRAGMENT = gql`
  fragment productFragment on ProductGraphType {
    creationDate
    description
    id
    isActive
    isDeleted
    status
    type
    updatedDate
  }
`;

export { PRODUCT_FRAGMENT };
