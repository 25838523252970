import { GET_PROJECT_CONFIGURATION_QUERY } from './useGetProjectConfiguration.graphql';
import {
  IGetProjectConfigurationQueryGraphQLResponse,
  IGetProjectConfigurationQueryVariables,
  IHandleGetProjectConfigurationParams,
  IUseGetProjectConfiguration,
  IUseGetProjectConfigurationOptions,
} from './useGetProjectConfiguration.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useGetProjectConfiguration = (options?: IUseGetProjectConfigurationOptions): IUseGetProjectConfiguration => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetProjectConfigurationOptions);

  const [executeGetProjectConfiguration, { loading: isLoading }] = useKanziLazyQuery<
    IGetProjectConfigurationQueryGraphQLResponse,
    IGetProjectConfigurationQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projectConfiguration: { getProjectConfiguration: projectConfiguration },
        } = data;

        onCompleted({
          projectConfiguration,
        });
      },
      onError,
    },
    query: query ?? GET_PROJECT_CONFIGURATION_QUERY,
    token,
  });

  const handleGetProjectConfiguration = ({ id }: IHandleGetProjectConfigurationParams) => {
    executeGetProjectConfiguration({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetProjectConfiguration,
    isLoading,
  };
};

export { useGetProjectConfiguration };
