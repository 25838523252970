
import { ISpotlight } from '../../Spotlight';

const PAGE_TITLE = 'Settings';

const SPOTLIGHT_ITEMS: ISpotlight = {
  run: false,
  steps: [
    {
      content: <h2>Let&apos;s begin your Kanzi tour!</h2>,
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: 'center',
      target: 'body',
    },
    {
      content: (
        <div>
          <h3>Settings</h3>
          <p>
            Control Kanzi core features (translate, speech and PDF generation) as well as which languages you would like to make available
            to your audience. Any setting changes will be immediately available to your audience.
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: '.c-spotlight__settings',
    },
    {
      content: (
        <div>
          <h3>Style and layout</h3>
          <p>
            Easily control where Kanzi will be positioned on screen, modify the Kanzi plugin colour to match your brand and use the inbuilt
            preview to see these changes in real-time
          </p>
        </div>
      ),
      placement: 'top',
      styles: {
        options: {
          width: 300,
        },
      },
      spotlightPadding: 20,
      target: '.c-spotlight__style-layout',
    },
    {
      content: (
        <div>
          <h3>Install codes</h3>
          <p>
            Manage access to the Kanzi services. Generate keys (one-line script) required to run the plugin, copy and send the installation
            script for installation. Control advanced features including voice, content inclusion and exclusion rules, as well as security.
          </p>
        </div>
      ),
      placement: 'top',
      target: '.c-spotlight__integrations',
    },
    {
      content: (
        <div>
          <h3>Reports</h3>
          <p>Find out how much and when people are interacting with Kanzi</p>
        </div>
      ),
      placement: 'top',
      target: '.c-spotlight__reporting',
    },
    {
      content: (
        <div>
          <h3>Save your changes</h3>
          <p>Once you are finished with any changes click here</p>
        </div>
      ),
      placement: 'left',
      target: '.c-save-changes .c-button',
    },
  ],
};

const PROJECT_SETTINGS_PAGE_CONSTANTS = Object.freeze({
  pageTitle: PAGE_TITLE,
  spotLightItems: SPOTLIGHT_ITEMS,
});

export { PROJECT_SETTINGS_PAGE_CONSTANTS, SPOTLIGHT_ITEMS };
