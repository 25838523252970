/* eslint-disable @typescript-eslint/indent */
import { GET_MURIQUI_PROJECT_WITH_DOWNGRADE_REQUEST } from './useGetMuriquiProject.graphql';
import {
  IGetMuriquiProjectQueryGraphQLResponse,
  IGetMuriquiProjectQueryVariables,
  IHandleGetMuriquiProjectParams,
  IUseGetMuriquiProject,
  IUseGetMuriquiProjectOptions,
} from './useGetMuriquiProject.interfaces';

import { useMuriquiLazyQuery } from '../../apollo';

const useGetMuriquiProject = (options?: IUseGetMuriquiProjectOptions): IUseGetMuriquiProject => {
  const { fetchPolicy, onCompleted, onError } = options ?? ({} as IUseGetMuriquiProjectOptions);

  const [executeGetMuriquiProject, { loading: isLoading }] = useMuriquiLazyQuery<
    IGetMuriquiProjectQueryGraphQLResponse,
    IGetMuriquiProjectQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projects: { get },
        } = data;
        onCompleted({
          project: get,
        });
      },
      onError,
    },
    query: GET_MURIQUI_PROJECT_WITH_DOWNGRADE_REQUEST,
  });

  const handleGetMuriquiProject = async ({ projectId }: IHandleGetMuriquiProjectParams) => {
    await executeGetMuriquiProject({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetMuriquiProject,
    isLoading,
  };
};

export { useGetMuriquiProject };
