import { CLIENT_LIST } from './ClientsBanner.constants';
import styles from './ClientsBanner.module.css';

const ClientsBanner = () => {
  return (
    <article>
      <div className="c-container py-16">
        <h4 className="h2 mb-12 text-center font-header">Clients</h4>

        <div className={styles['c-clients-banner__wrapper']}>
          {CLIENT_LIST.map(({ alt, id, src }) => {
            return (
              <div key={id} className="w-full md:w-1/5 mb-6 md:mb-0 flex justify-center p-4">
                <img alt={alt} className="max-w-3/5 md:max-w-full" loading="lazy" src={src} />
              </div>
            );
          })}
        </div>
      </div>
    </article>
  );
};

export { ClientsBanner };
