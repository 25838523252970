import { GET_PROJECT_CONFIGURATION_BY_PROJECT_QUERY } from './useGetProjectConfigurationByProject.graphql';
import {
  IGetProjectConfigurationByProjectQueryGraphQLResponse,
  IGetProjectConfigurationByProjectQueryVariables,
  IHandleGetProjectConfigurationByProjectParams,
  IUseGetProjectConfigurationByProject,
  IUseGetProjectConfigurationByProjectOptions,
} from './useGetProjectConfigurationByProject.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useGetProjectConfigurationByProject = (
  options?: IUseGetProjectConfigurationByProjectOptions,
): IUseGetProjectConfigurationByProject => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetProjectConfigurationByProjectOptions);

  const [executeGetProjectConfigurationByProject, { loading: isLoading }] = useKanziLazyQuery<
    IGetProjectConfigurationByProjectQueryGraphQLResponse,
    IGetProjectConfigurationByProjectQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projectConfiguration: { getProjectConfigurationByProject: projectConfiguration },
        } = data;

        onCompleted({
          projectConfiguration,
        });
      },
      onError,
    },
    query: query ?? GET_PROJECT_CONFIGURATION_BY_PROJECT_QUERY,
    token,
  });

  const handleGetProjectConfigurationByProject = ({ projectId }: IHandleGetProjectConfigurationByProjectParams) => {
    executeGetProjectConfigurationByProject({
      variables: {
        projectId,
      },
    });
  };

  return {
    handleGetProjectConfigurationByProject,
    isLoading,
  };
};

export { useGetProjectConfigurationByProject };
