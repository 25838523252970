import { gql } from '@apollo/client';

const API_KEY_FRAGMENT = gql`
  fragment apiKeyFragment on ApiKeyGraphType {
    creationDate
    creatorId
    id
    googleMeasurementKey
    guid
    maintainTranslationOnNavigation
    isAccessibilityMaintainedOnNavigation
    monitoringStatus
    htmlConfigurations {
      apiKeyId
      feature
      id
      property
      type
      value
    }
    id
    packageName
    projectId
    restrictions {
      id
      type
      value
      hasBeenApproved
    }
    status
    title
    type
    voices {
      id
      language
      name
    }
  }
`;

export { API_KEY_FRAGMENT };
