import { EXPORT_PDF_REPORT_QUERY } from './useExportPdfReport.graphql';
import {
  IExportPdfReportQueryGraphQLResponse,
  IExportPdfReportQueryVariables,
  IHandleExportPdfReportParams,
  IUseExportPdfReport,
  IUseExportPdfReportOptions,
} from './useExportPdfReport.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useExportPdfReport = (options?: IUseExportPdfReportOptions): IUseExportPdfReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseExportPdfReportOptions);

  const [executeExportPdfReport, { loading: isLoading }] = useKanziLazyQuery<
    IExportPdfReportQueryGraphQLResponse,
    IExportPdfReportQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          report: { exportPdfReport: report },
        } = data;

        onCompleted({
          report,
        });
      },
      onError,
    },
    query: query ?? EXPORT_PDF_REPORT_QUERY,
    token,
  });

  const handleExportPdfReport = ({ from, ianaTimeZone, projectId, to }: IHandleExportPdfReportParams) => {
    executeExportPdfReport({
      variables: {
        from,
        ianaTimeZone,
        projectId,
        to,
      },
    });
  };

  return {
    handleExportPdfReport,
    isLoading,
  };
};

export { useExportPdfReport };
