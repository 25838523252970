import { gql } from '@apollo/client';

const UPDATE_PROJECT_SUBSCRIPTION_MUTATION = gql`
  mutation updateProjectSubscription($planIds: [Int!]!, $projectId: String!, $product: EProduct!) {
    subscriptions {
      updateSubscriptions(input: { planIds: $planIds, projectId: $projectId, product: $product }) {
        result
      }
      __typename
    }
  }
`;

export { UPDATE_PROJECT_SUBSCRIPTION_MUTATION };
