import { Spinner } from '@netfront/ui-library';

import { PageLayout } from '../PageLayout';
import { PageTitleBanner } from '../PageTitleBanner';

import { PublicPageLayoutProps } from './PublicPageLayout.interfaces';

const PublicPageLayout: React.FC<PublicPageLayoutProps> = ({
  afterTitle,
  children,
  hasContainer = true,
  isPreloaderVisible = false,
  isTitleVisible = true,
  meta,
  title,
}) => {
  return (
    <PageLayout meta={meta} title={title}>
      {Boolean(title) && <PageTitleBanner afterTitle={afterTitle} isTitleVisible={isTitleVisible} title={title} />}
      <Spinner isLoading={isPreloaderVisible} />
      {hasContainer ? <div className="c-container">{children}</div> : children}
    </PageLayout>
  );
};

export { PublicPageLayout };
