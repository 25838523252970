import { IIcon } from './Icons.interfaces';

const IconVolume = ({ color }: IIcon) => {
  return (
    <svg aria-hidden="true" className="c-kanzi-action-button__icon c-kanzi-volume-icon" style={{ color }} viewBox="0 0 31.997 24.038">
      <g id="volume-high-icon" transform="translate(1 1.414)">
        <path
          d="M16.5,7.5,9,13.5H3v9H9l7.5,6Z"
          data-name="Path 42228"
          fill="none"
          id="Path_42228"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-3 -7.395)"
        />
        <path
          d="M28.6,7.395a15,15,0,0,1,0,21.21M23.31,12.69a7.5,7.5,0,0,1,0,10.605"
          data-name="Path 42229"
          fill="none"
          id="Path_42229"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(-3 -7.395)"
        />
      </g>
    </svg>
  );
};

export { IconVolume };
