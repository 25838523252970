import { getUpdatedApiKey } from '../apiKey.helpers';

import { GET_API_KEY_QUERY } from './useGetApiKey.graphql';
import {
  IGetApiKeyQueryGraphQLResponse,
  IGetApiKeyQueryVariables,
  IHandleGetApiKeyParams,
  IUseGetApiKey,
  IUseGetApiKeyOptions,
} from './useGetApiKey.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useGetApiKey = (options?: IUseGetApiKeyOptions): IUseGetApiKey => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetApiKeyOptions);

  const [executeGetApiKey, { loading: isLoading }] = useKanziLazyQuery<IGetApiKeyQueryGraphQLResponse, IGetApiKeyQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          apiKey: { getApiKey: apiKey },
        } = data;

        onCompleted({
          apiKey: getUpdatedApiKey(apiKey),
        });
      },
      onError,
    },
    query: query ?? GET_API_KEY_QUERY,
    token,
  });

  const handleGetApiKey = ({ id }: IHandleGetApiKeyParams) => {
    executeGetApiKey({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetApiKey,
    isLoading,
  };
};

export { useGetApiKey };
