import { CREATE_RESTRICTION_MUTATION } from './useCreateRestriction.graphql';
import {
  ICreateRestrictionMutationGraphQLResponse,
  ICreateRestrictionMutationVariables,
  IHandleCreateRestrictionParams,
  IUseCreateRestrictionOptions,
  IUseCreateRestriction,
} from './useCreateRestriction.interfaces';

import { useKanziMutation } from '../../apollo';

const useCreateRestriction = (options?: IUseCreateRestrictionOptions): IUseCreateRestriction => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateRestrictionOptions);

  const [executeCreateRestriction, { loading: isLoading }] = useKanziMutation<
    ICreateRestrictionMutationGraphQLResponse,
    ICreateRestrictionMutationVariables
  >({
    mutation: mutation ?? CREATE_RESTRICTION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          restriction: { createRestriction: restriction },
        } = data;

        onCompleted({
          restriction,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateRestriction = async ({ apiKeyId, type, value }: IHandleCreateRestrictionParams) => {
    await executeCreateRestriction({
      variables: {
        apiKeyId,
        type,
        value,
      },
    });
  };

  return {
    handleCreateRestriction,
    isLoading,
  };
};

export { useCreateRestriction };
