import { parseISO } from 'date-fns';

import { API_KEY_RESTRICTION_NONE, API_KEY_STATUS_TYPE_STATUS_TEXT_MAP } from './apiKey.constants';

import { IDBApiKey, IApiKey } from '../../interfaces';

const getUpdatedApiKey = (apiKey: IDBApiKey): IApiKey => {
  const { creationDate, restrictions = [], status } = apiKey;

  return {
    ...apiKey,
    creationDateLocaleDateString: parseISO(creationDate).toLocaleDateString(),
    serializedRestrictions: restrictions.length ? restrictions.map(({ value }) => value).join(', ') : API_KEY_RESTRICTION_NONE,
    statusText: API_KEY_STATUS_TYPE_STATUS_TEXT_MAP[status],
  };
};

export { getUpdatedApiKey };
