import { useEffect } from 'react';

import { useRouter } from 'next/router';

const FacebookPixel = () => {
  const router = useRouter();

  useEffect(() => {
    const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

    if (!facebookPixelId) {
      throw new Error(`Facebook pixel ID has not been set`);
    }

    void import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(facebookPixelId);
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return null;
};

export { FacebookPixel };
