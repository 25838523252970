import { useEffect } from 'react';

import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { DocsLandingPageProps } from './DocsLandingPage.interfaces';

import { useToast } from '../../../hooks';
import { PublicPageLayout } from '../../PublicPageLayout';

export const DocsLandingPage = ({ contentGroups }: DocsLandingPageProps) => {
  const { push } = useRouter();
  const { handleToastError } = useToast();

  useEffect(() => {
    if (contentGroups.length === 0) {
      return;
    }

    const [firstContentGroup] = contentGroups;
    const { contentPages } = firstContentGroup;
    const [firstPage] = contentPages;

    push(`/docs/${firstPage.url}`).catch((error) =>
      handleToastError({
        error,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicPageLayout title="Docs">
      <nav aria-label="Documentation menu">
        <ul className="list-none p-0">
          {contentGroups.map((group) => (
            <li key={group.id} className="mb-6">
              <span className="block h4 weight-600 mb-4">{group.title}</span>
              <ul className="list-none p-0">
                {group.contentPages.map((page) => (
                  <li key={page.id}>
                    <NextLink href={`/docs/${page.url}`}>
                      <span className="block mb-3 text-sm transition hover:color-primary">{page.title}</span>
                    </NextLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </nav>
    </PublicPageLayout>
  );
};
