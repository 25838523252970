import { DELETE_API_KEY_MUTATION } from './useDeleteApiKey.graphql';
import {
  IDeleteApiKeyMutationGraphQLResponse,
  IDeleteApiKeyMutationVariables,
  IHandleDeleteApiKeyParams,
  IUseDeleteApiKeyOptions,
  IUseDeleteApiKey,
} from './useDeleteApiKey.interfaces';

import { useKanziMutation } from '../../apollo';

const useDeleteApiKey = (options?: IUseDeleteApiKeyOptions): IUseDeleteApiKey => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteApiKeyOptions);

  const [executeDeleteApiKey, { loading: isLoading }] = useKanziMutation<
    IDeleteApiKeyMutationGraphQLResponse,
    IDeleteApiKeyMutationVariables
  >({
    mutation: mutation ?? DELETE_API_KEY_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          apiKey: { deleteApiKey: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteApiKey = async ({ id }: IHandleDeleteApiKeyParams) => {
    await executeDeleteApiKey({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteApiKey,
    isLoading,
  };
};

export { useDeleteApiKey };
