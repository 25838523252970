import { UPDATE_PROJECT_CONFIGURATION_MUTATION } from './useUpdateProjectConfiguration.graphql';
import {
  IUpdateProjectConfigurationMutationGraphQLResponse,
  IUpdateProjectConfigurationMutationVariables,
  IHandleUpdateProjectConfigurationParams,
  IUseUpdateProjectConfigurationOptions,
  IUseUpdateProjectConfiguration,
} from './useUpdateProjectConfiguration.interfaces';

import { useKanziMutation } from '../../apollo';

const useUpdateProjectConfiguration = (options?: IUseUpdateProjectConfigurationOptions): IUseUpdateProjectConfiguration => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateProjectConfigurationOptions);

  const [executeUpdateProjectConfiguration, { loading: isLoading }] = useKanziMutation<
    IUpdateProjectConfigurationMutationGraphQLResponse,
    IUpdateProjectConfigurationMutationVariables
  >({
    mutation: mutation ?? UPDATE_PROJECT_CONFIGURATION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projectConfiguration: { updateProjectConfiguration: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateProjectConfiguration = async ({
    colour,
    cornerRadius,
    hasAccessibility,
    hasPdf,
    hasSpeech,
    hasTranslate,
    hasVolumeControl,
    id,
    isCollapse,
    isDraggable,
    isSticky,
    languages,
    offset,
    position,
    size,
    theme,
  }: IHandleUpdateProjectConfigurationParams) => {
    await executeUpdateProjectConfiguration({
      variables: {
        colour,
        cornerRadius,
        hasAccessibility,
        hasPdf,
        hasSpeech,
        hasTranslate,
        hasVolumeControl,
        id,
        isCollapse,
        isDraggable,
        isSticky,
        languages,
        offset,
        position,
        size,
        theme,
      },
    });
  };

  return {
    handleUpdateProjectConfiguration,
    isLoading,
  };
};

export { useUpdateProjectConfiguration };
