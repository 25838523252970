import { gql } from '@apollo/client';

import { PROJECT_CONFIGURATION_FRAGMENT } from '../../../graphql';

const GET_PROJECT_CONFIGURATION_QUERY = gql`
  query getProjectConfiguration($id: Int!) {
    projectConfiguration {
      getProjectConfiguration: get(id: $id) {
        ...projectConfigurationFragment
      }
    }
  }

  ${PROJECT_CONFIGURATION_FRAGMENT}
`;

export { GET_PROJECT_CONFIGURATION_QUERY };
