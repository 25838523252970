import { IOption } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IPdfUsageTableData, IProjectUsageTableData } from './ReportingPage.interfaces';

import { ExternalLink } from '../../ExternalLink';

const PAGE_TITLE = 'Reporting';


const TABLE_SELECTION_ITEMS: IOption[] = [
  {
    id: 1,
    value: 'translate-speech-table',
    name: 'Translate & Speech',
  },
  {
    id: 2,
    value: 'pdf-table',
    name: 'PDF',
  },
];

const TABLE_COLUMNS_TEXT_SPEECH: ReadonlyArray<Column<IProjectUsageTableData>> = [
  {
    accessor: 'action',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Feature</div>,
  },
  {
    accessor: 'dateLocaleDateString',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Date</div>,
  },
  {
    accessor: 'dateLocaleTimeString',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Time</div>,
  },
  {
    accessor: 'sourceLanguage',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Source</div>,
  },
  {
    accessor: 'targetLanguage',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Target</div>,
  },
  {
    accessor: 'referer',
    Cell: ({ value }) => <ExternalLink href={value} isTooltipVisible />,
    Header: () => <div>Referrer</div>,
  },
  {
    accessor: 'characters',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Characters</div>,
  },
  {
    accessor: 'apiKeyTitle',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>API key</div>,
  },
];

const TABLE_COLUMNS_PDF: ReadonlyArray<Column<IPdfUsageTableData>> = [
  {
    accessor: 'dateLocaleDateString',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Date</div>,
  },
  {
    accessor: 'count',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>PDFs generated</div>,
  },
  {
    accessor: 'url',
    Cell: ({ value }) => <ExternalLink href={value} isLinkVisible isTooltipVisible />,
    Header: () => <div>Referrer</div>,
  },
  {
    accessor: 'apiKey',
    Cell: ({ value: { title } }) => <div>{title}</div>,
    Header: () => <div>API key</div>,
  },
];

const REPORTING_PAGE_CONSTANTS = Object.freeze({
  numberOfPageSizeOptions: 5,
  pageSize: 10,
  pageTitle: PAGE_TITLE,
  tableColumnsPdf: TABLE_COLUMNS_PDF,
  tableColumnsTextSpeech: TABLE_COLUMNS_TEXT_SPEECH,
  tableSelectionItems: TABLE_SELECTION_ITEMS,
});

export { REPORTING_PAGE_CONSTANTS };
