import { IIcon } from './Icons.interfaces';

const IconTranslate = ({ color }: IIcon) => {
  return (
    <svg aria-hidden="true" className="c-kanzi-action-button__icon kanzi-translate-icon" style={{ color }} viewBox="0 0 26.804 20.713">
      <path
        d="M1007.389,130.83H983.172A1.274,1.274,0,0,0,981.9,132.1v13.6s-.086,5.929,0,5.844,5.25-4.571,5.25-4.571h20.243a1.274,1.274,0,0,0,1.273-1.273V132.1A1.273,1.273,0,0,0,1007.389,130.83Zm-13.632,5.456H991.9a7.16,7.16,0,0,1-2.347,4.324,6.443,6.443,0,0,0,2.059,1.03l-.247.989a7.178,7.178,0,0,1-2.594-1.358,15.8,15.8,0,0,1-3.829,2.306l-.371-.906a15.923,15.923,0,0,0,3.418-2.018,22.13,22.13,0,0,1-2.594-2.924l.824-.577a15.86,15.86,0,0,0,2.512,2.841,6.073,6.073,0,0,0,2.182-3.747l-7.495.041V135.3h5.106v-1.071h.989V135.3h4.241v.989Zm14.614,9.413a.985.985,0,0,1-.985.985H996.655l-1.928-15.564h12.66a.985.985,0,0,1,.985.985Z"
        data-name="Path 42194"
        fill="currentColor"
        id="Path_42194"
        transform="translate(-981.861 -130.83)"
      />
      <path
        d="M1003.235,133.524,999.06,143.7h1.388l.825-2.02h4.01l.824,2.02h1.389Zm1.478,6.874h-2.9l1.434-3.514Z"
        data-name="Path 42195"
        fill="currentColor"
        id="Path_42195"
        transform="translate(-982.664 -130.955)"
      />
    </svg>
  );
};

export { IconTranslate };
