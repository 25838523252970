export * from './BlogDetailPage';
export * from './BlogLandingPage';
export * from './BusinessDetailsPage';
export * from './ContactPage';
export * from './ContentPage';
export * from './DocsContentPage';
export * from './DocsLandingPage';
export * from './ErrorPage';
export * from './FAQPage';
export * from './FeaturesPage';
export * from './InstallInstructionsPage';
export * from './IntegrationsPage';
export * from './LetsStartUsingKanziPage';
export * from './LoginPage';
export * from './PartnersPage';
export * from './PaymentPage';
export * from './PluginInstalLocationPage';
export * from './PricingPage';
export * from './ProjectSettingsPage';
export * from './ReportingPage';
export * from './StyleLayoutPage';
export * from './WelcomeNamingPage';
