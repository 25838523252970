import { Footer, PageLayoutTemplate, Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import { useIsMounted } from 'hooks';
import Head from 'next/head';

import { PollingBanner } from '../PollingBanner';

import { IMeta, PageLayoutProps } from './PageLayout.interfaces';

import { PrivateHeader, PublicHeader, PublicFooter } from '..';

const PageLayout: React.FC<PageLayoutProps> = ({
  additionalClassNames,
  children,
  geladaProject,
  hasPrivateLayout,
  isPreloaderVisible = false,
  meta,
  title,
  isDisplayLayoutTemplate = true,
}) => {
  const { description = 'Description', metaTitle = 'Title' } = meta ?? ({} as IMeta);
  const { isMounted } = useIsMounted();

  return isMounted ? (
    <>
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />
        <meta content={metaTitle} property="og:title" />
        <meta content={description} property="og:description" />
        <meta content="/images/kanzi-logo-full.svg" property="og:image" />
        <link href={`${window.location.origin}${window.location.pathname}`} rel="canonical" />

        <link href="/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />
        <link href="/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
        <link href="/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
        <link href="/site.webmanifest" rel="manifest" />
        <link color="#5bbad5" href="/safari-pinned-tab.svg" rel="mask-icon" />
        <meta content="#da532c" name="msapplication-TileColor" />
        <meta content="#ffffff" name="theme-color"></meta>
      </Head>

      {isDisplayLayoutTemplate ? (
        <PageLayoutTemplate
          additionalClassNames={cx('c-page-layout-template', additionalClassNames)}
          footer={hasPrivateLayout ? <Footer projectName="Kanzi" /> : <PublicFooter />}
          header={hasPrivateLayout ? <PrivateHeader /> : <PublicHeader />}
        >
          <Spinner isLoading={isPreloaderVisible} />

          <main className="c-page-layout-template__main">
            {hasPrivateLayout ? (
              <>
                {geladaProject ? <PollingBanner project={geladaProject} /> : null}
                {children}
              </>
            ) : (
              children
            )}
          </main>
        </PageLayoutTemplate>
      ) : (
        children
      )}
    </>
  ) : null;
};

export { PageLayout };
