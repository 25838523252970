import { gql } from '@apollo/client';

import { RESTRICTION_FRAGMENT } from '../../../graphql';

const CREATE_RESTRICTION_MUTATION = gql`
  mutation createRestriction($apiKeyId: Int!, $type: ERestrictionType!, $value: String!) {
    restriction {
      createRestriction: add(apiKeyId: $apiKeyId, type: $type, value: $value) {
        ...restrictionFragment
      }
    }
  }

  ${RESTRICTION_FRAGMENT}
`;

export { CREATE_RESTRICTION_MUTATION };
