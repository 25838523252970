/* eslint-disable @typescript-eslint/indent */
import { UPDATE_DEFAULT_PAYMENT_METHOD_MUTATION } from './useUpdateDefaultPaymentMethod.graphql';
import {
  IHandleUpdateDefaultPaymentMethodParams,
  IUpdateDefaultPaymentMethodMutationGraphQLResponse,
  IUpdateDefaultPaymentMethodMutationVariables,
  IUseUpdateDefaultPaymentMethodOptions,
  IUseUpdateDefaultPaymentMethod,
} from './useUpdateDefaultPaymentMethod.interfaces';

import { useMuriquiMutation } from '../../apollo';

const useUpdateDefaultPaymentMethod = (options?: IUseUpdateDefaultPaymentMethodOptions): IUseUpdateDefaultPaymentMethod => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateDefaultPaymentMethodOptions);

  const [executeUpdateDefaultPaymentMethod, { loading: isLoading }] = useMuriquiMutation<
    IUpdateDefaultPaymentMethodMutationGraphQLResponse,
    IUpdateDefaultPaymentMethodMutationVariables
  >({
    mutation: mutation ?? UPDATE_DEFAULT_PAYMENT_METHOD_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          subscriptions: { changeDefaultPaymentMethod: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateDefaultPaymentMethod = async ({ cardId, projectId, product }: IHandleUpdateDefaultPaymentMethodParams) => {
    await executeUpdateDefaultPaymentMethod({
      variables: {
        cardId,
        product,
        projectId,
      },
    });
  };

  return {
    handleUpdateDefaultPaymentMethod,
    isLoading,
  };
};

export { useUpdateDefaultPaymentMethod };
