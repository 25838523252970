import { gql } from '@apollo/client';

import { PRODUCT_FRAGMENT } from '../../../graphql';

const GET_PRODUCTS_QUERY = gql`
  query getProducts {
    product {
      getProducts: getAll {
        ...productFragment
      }
    }
  }

  ${PRODUCT_FRAGMENT}
`;

export { GET_PRODUCTS_QUERY };
