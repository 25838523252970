import { IApolloConnectionEdge } from '@netfront/common-library';

import { GET_PDF_USAGE_QUERY } from './useGetPdfUsage.graphql';
import { getUpdatedPdfUsage } from './useGetPdfUsage.helpers';
import {
  IGetPdfUsageQueryGraphQLResponse,
  IGetPdfUsageQueryVariables,
  IHandleGetPdfUsageParams,
  IUseGetPdfUsage,
  IUseGetPdfUsageOptions,
} from './useGetPdfUsage.interfaces';

import { IPdfUsage } from '../../../interfaces';
import { useKanziLazyQuery } from '../../apollo';

const useGetPdfUsage = (options?: IUseGetPdfUsageOptions): IUseGetPdfUsage => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPdfUsageOptions);

  const [executeGetPdfUsage, { loading: isLoading }] = useKanziLazyQuery<IGetPdfUsageQueryGraphQLResponse, IGetPdfUsageQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          report: {
            getPdfUsages: { edges, pageInfo, totalCount },
          },
        } = data;

        const updatedEdges = edges.map((edge) => {
          const { node } = edge;

          const updatedEdge: IApolloConnectionEdge<IPdfUsage> = {
            ...edge,
            node: getUpdatedPdfUsage(node),
          };

          return updatedEdge;
        });

        onCompleted({
          edges: updatedEdges,
          pageInfo,
          totalCount,
        });
      },
      onError,
    },
    query: query ?? GET_PDF_USAGE_QUERY,
    token,
  });

  const handleGetPdfUsage = ({ after, filter, first, from, ianaTimeZone, projectId, to }: IHandleGetPdfUsageParams) => {
    executeGetPdfUsage({
      variables: {
        after,
        filter,
        first,
        from,
        ianaTimeZone,
        projectId,
        to,
      },
    });
  };

  return {
    handleGetPdfUsage,
    isLoading,
  };
};

export { useGetPdfUsage };
