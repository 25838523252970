import { useEffect, useState } from 'react';

import { CMSContentPage, useContentPageContext } from '@netfront/ekardo-content-library';
import { PagePreviewCard, IImage } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';

import { BlogDetailPageProps } from './BlogDetailPage.interfaces';

import { PublicPageLayout } from '../../PublicPageLayout';

const BlogDetailPage = ({ contentPage }: BlogDetailPageProps) => {
  const { dispatch } = useContentPageContext();

  const [blogImage, setBlogImage] = useState<IImage>();
  const [blogTitle, setBlogTitle] = useState<string>('');
  const [tag, setTag] = useState<string>('');

  useEffect(() => {
    const { asset, tags = [], title } = contentPage;
    const [firstTag] = tags;

    if (asset) {
      const { presignedUrl } = asset;

      setBlogImage({
        altText: title,
        src: String(presignedUrl),
      });
    }

    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });

    setBlogTitle(title);

    if (!isEmpty(firstTag)) {
      setTag(firstTag.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);

  return (
    <PublicPageLayout title={`${tag}: ${blogTitle}`}>
      {blogImage ? <PagePreviewCard additionalClassNames="font-header" coverLink={null} image={blogImage} isHero /> : undefined}
      <CMSContentPage />
    </PublicPageLayout>
  );
};

export { BlogDetailPage };
