import {
  IGetPdfUsageTableDataParams,
  IGetProjectUsageTableDataParams,
  IPdfUsageTableData,
  IProjectUsageTableData,
} from './ReportingPage.interfaces';

const getProjectUsageTableData = ({ projectUsageEdges }: IGetProjectUsageTableDataParams): IProjectUsageTableData[] => {
  return projectUsageEdges.map(
    ({
      node: {
        action,
        apiKeyTitle,
        characters,
        date,
        dateLocaleDateString,
        dateLocaleTimeString,
        operations,
        referer,
        sourceLanguage,
        targetLanguage,
      },
    }): IProjectUsageTableData => ({
      id: apiKeyTitle,
      action,
      apiKeyTitle,
      characters,
      date,
      dateLocaleDateString,
      dateLocaleTimeString,
      operations,
      referer,
      sourceLanguage,
      targetLanguage,
    }),
  );
};

const getPdfUsageTableData = ({ pdfUsageEdges }: IGetPdfUsageTableDataParams): IPdfUsageTableData[] => {
  return pdfUsageEdges.map(
    ({ node: { apiKey, count, date, dateLocaleDateString, url } }): IPdfUsageTableData => {
      const { id } = apiKey
      return {
        id,
        apiKey,
        count,
        date,
        dateLocaleDateString,
        url,
      }
    },
  );
};

export { getPdfUsageTableData, getProjectUsageTableData };
