import { gql } from '@apollo/client';

const GET_STRIPE_PAYMENT_METHODS_QUERY = gql`
  query getStripePaymentMethods($projectId: String!, $product: EProduct!) {
    payments {
      getStripePaymentMethods: getPaymentMethods(projectId: $projectId, product: $product) {
        cardBrand
        cvcPass
        default
        expMonth
        expYear
        lastFourDigits
        stripeSourceId
      }
    }
  }
`;

export { GET_STRIPE_PAYMENT_METHODS_QUERY };
