import { ApiKeyStatusType } from '../../interfaces';

const API_KEY_RESTRICTION_NONE = 'None';

const API_KEY_STATUS_TYPE_STATUS_TEXT_MAP: Record<ApiKeyStatusType, string> = Object.freeze({
  ACTIVATE: 'Activated',
  ARCHIVE: 'Archived',
  DEACTIVATE: 'Deactivated',
});

export { API_KEY_RESTRICTION_NONE, API_KEY_STATUS_TYPE_STATUS_TEXT_MAP };
