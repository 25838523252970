import { GET_PLANS_QUERY } from './useGetPlans.graphql';
import { IGetPlansQueryGraphQLResponse, IHandleGetPlansParams, IUseGetPlans, IUseGetPlansOptions } from './useGetPlans.interfaces';

import { useMuriquiLazyQuery } from '../../apollo';

const useGetPlans = (options?: IUseGetPlansOptions): IUseGetPlans => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPlansOptions);

  const [executeGetPlans, { loading: isLoading }] = useMuriquiLazyQuery<IGetPlansQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          plans: { getPlans: plans },
        } = data;

        onCompleted({
          plans,
        });
      },
      onError,
    },
    query: query ?? GET_PLANS_QUERY,
    token,
  });

  const handleGetPlans = async (params?: IHandleGetPlansParams) => {
    const { currency = 'AUD' } = params ?? {};

    await executeGetPlans({
      variables: {
        currency,
        product: 'KANZI',
      },
    });
  };
  return {
    handleGetPlans,
    isLoading,
  };
};

export { useGetPlans };
