import React from 'react';

import cx from 'classnames';

import { HighlightListItemProps } from './HighlightListItem.interfaces';

const HighlightListItem: React.FC<HighlightListItemProps> = ({ children, isHighlighted }) => {
  return (
    <li
      className={cx('c-highlight-item', {
        'c-highlight-item--selected': isHighlighted,
      })}
    >
      {children}
    </li>
  );
};

export { HighlightListItem };
