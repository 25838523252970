import { EXPORT_USAGE_REPORT_QUERY } from './useExportUsageReport.graphql';
import {
  IExportUsageReportQueryGraphQLResponse,
  IExportUsageReportQueryVariables,
  IHandleExportUsageReportParams,
  IUseExportUsageReport,
  IUseExportUsageReportOptions,
} from './useExportUsageReport.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useExportUsageReport = (options?: IUseExportUsageReportOptions): IUseExportUsageReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseExportUsageReportOptions);

  const [executeExportUsageReport, { loading: isLoading }] = useKanziLazyQuery<
    IExportUsageReportQueryGraphQLResponse,
    IExportUsageReportQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          report: { exportUsageReport: report },
        } = data;

        onCompleted({
          report,
        });
      },
      onError,
    },
    query: query ?? EXPORT_USAGE_REPORT_QUERY,
    token,
  });

  const handleExportUsageReport = ({ from, ianaTimeZone, projectId, to }: IHandleExportUsageReportParams) => {
    executeExportUsageReport({
      variables: {
        from,
        ianaTimeZone,
        projectId,
        to,
      },
    });
  };

  return {
    handleExportUsageReport,
    isLoading,
  };
};

export { useExportUsageReport };
