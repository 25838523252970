import { ChangeEvent } from 'react';

import { IOption } from '@netfront/ui-library';

const getSelectOptions = (data: readonly string[]): IOption[] => {
  const options =  data.map((item, index) => {
    const option: IOption = {
      id: index,
      name: item,
      value: index,
    };

    return option;
  });

  return options;
};

const getSelectedIndexFromHTMLSelectElementChangeEvent = (event: ChangeEvent<HTMLSelectElement>): number => {
  const {
    target: {
      options: { selectedIndex },
    },
  } = event;

  return selectedIndex;
};

export { getSelectedIndexFromHTMLSelectElementChangeEvent, getSelectOptions };
