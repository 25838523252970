import { useLayoutPreviewer } from '../LayoutPreviewer.hooks';
import { PreviewerProps } from '../LayoutPreviewer.interfaces';

const MobilePreviewer = ({ colour, position }: PreviewerProps) => {
  const { ref: mobilePreviewerRef } = useLayoutPreviewer({
    colour: colour === '' ? '#4bc2ca' : colour,
    position,
  });

  return (
    <svg ref={mobilePreviewerRef} height="100%" viewBox="0 0 625 621.46" width="100%" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H625V579H0z" data-name="Rectangle 3458" fill="#fbfbfc"></path>
      <g transform="translate(180 12)">
        <g data-name="Rectangle 3373" fill="#fff" stroke="#ecedf0" strokeWidth="1">
          <rect height="557" rx="10" stroke="none" width="258"></rect>
          <rect fill="none" height="556" rx="9.5" width="257" x="0.5" y="0.5"></rect>
        </g>
        <g data-name="Group 47618" transform="translate(-713 -360.919)">
          <path
            d="M5 0h248a5 5 0 015 5v23H0V5a5 5 0 015-5z"
            data-name="Rectangle 3090"
            fill="#173567"
            transform="translate(713 361)"
          ></path>
        </g>
        <g transform="translate(37 101)">
          <g data-name="Group 46928" transform="translate(-6)">
            <g data-name="Group 46925">
              <rect data-name="Rectangle 3103" fill="#ecedf0" height="112.34" rx="5" transform="translate(6 110.823)" width="183.5"></rect>
              <g fill="#cccfd9" transform="translate(78.024 163.872)">
                <path d="M935.623 351.994l-10.272 16.93H945.9z" data-name="Path 542" transform="translate(-925.346 -344.328)"></path>
                <path d="M964.745 338.07l-14.118 23.268h28.236z" data-name="Path 543" transform="translate(-939.117 -336.742)"></path>
                <circle cx="4.532" cy="4.532" data-name="Ellipse 197" r="4.532"></circle>
              </g>
              <g transform="translate(6)">
                <rect data-name="Rectangle 2728" fill="#ecedf0" height="20.512" rx="10.256" width="89.079"></rect>
              </g>
              <g data-name="skeleton-title-large" transform="translate(6 255)">
                <rect data-name="Rectangle 2728" fill="#ecedf0" height="20.512" rx="10.256" width="89.079"></rect>
              </g>
              <g transform="translate(6 62.766)">
                <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="183.5"></rect>
              </g>
              <g data-name="skeleton-title-medium" transform="translate(6 317.766)">
                <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="183.5"></rect>
              </g>
              <g data-name="skeleton-title-medium" transform="translate(6 38.737)">
                <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="183.5"></rect>
              </g>
              <g data-name="skeleton-title-medium" transform="translate(6 293.737)">
                <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="183.5"></rect>
              </g>
              <g data-name="skeleton-title-medium" transform="translate(6 86.795)">
                <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="164.679"></rect>
              </g>
              <g data-name="skeleton-title-medium" transform="translate(6 341.795)">
                <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="164.679"></rect>
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 47619" fill="none" stroke="#cccfd9" strokeWidth="1" transform="translate(-1012 -357)">
          <g
            className="js-previewer__marker"
            data-name="Rectangle 3096"
            fill="none"
            id="LEFT_TOP"
            stroke="#cccfd9"
            transform="translate(1021 431)"
          >
            <rect height="74" rx="5" stroke="none" width="20" />
            <rect fill="none" height="73" rx="4.5" width="19" x="0.5" y="0.5" />
          </g>
          <g className="js-previewer__marker" data-name="Rectangle 3095" id="LEFT_CENTER" transform="translate(1021 599)">
            <rect height="74" rx="5" stroke="none" width="20"></rect>
            <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
          </g>
          <g
            className="js-previewer__marker"
            data-name="Rectangle 3094"
            fill="none"
            id="LEFT_BOTTOM"
            stroke="#cccfd9"
            transform="translate(1021 767)"
          >
            <rect height="74" rx="5" stroke="none" width="20" />
            <rect fill="none" height="73" rx="4.5" width="19" x="0.5" y="0.5" />
          </g>
        </g>

        <g data-name="Group 47620" fill="none" stroke="#cccfd9" strokeWidth="1" transform="translate(-792 -357)">
          <g
            className="js-previewer__marker"
            data-name="Rectangle 3096"
            fill="none"
            id="RIGHT_TOP"
            stroke="#cccfd9"
            transform="translate(1021 431)"
          >
            <rect height="74" rx="5" stroke="none" width="20" />
            <rect fill="none" height="73" rx="4.5" width="19" x="0.5" y="0.5" />
          </g>
          <g className="js-previewer__marker" data-name="Rectangle 3095" id="RIGHT_CENTER" transform="translate(1021 599)">
            <rect height="74" rx="5" stroke="none" width="20"></rect>
            <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
          </g>
          <g
            className="js-previewer__marker"
            data-name="Rectangle 3094"
            fill="none"
            id="RIGHT_BOTTOM"
            stroke="#cccfd9"
            transform="translate(1021 767)"
          >
            <rect height="74" rx="5" stroke="none" width="20" />
            <rect fill="none" height="73" rx="4.5" width="19" x="0.5" y="0.5" />
          </g>
        </g>
        <g data-name="Group 47621" fill="none" stroke="#cccfd9" strokeWidth="1" transform="rotate(90 589.767 91.233)">
          <g className="js-previewer__marker" data-name="Rectangle 3096" id="BOTTOM_RIGHT" transform="translate(1021 431)">
            <rect height="74" rx="5" stroke="none" width="25.615"></rect>
            <rect height="73" rx="4.5" width="24.615" x="0.5" y="0.5"></rect>
          </g>
          <g className="js-previewer__marker" data-name="Rectangle 3095" id="BOTTOM_CENTER" transform="translate(1021 515)">
            <rect height="74" rx="5" stroke="none" width="25.615"></rect>
            <rect height="73" rx="4.5" width="24.615" x="0.5" y="0.5"></rect>
          </g>
          <g className="js-previewer__marker" data-name="Rectangle 3094" id="BOTTOM_LEFT" transform="translate(1021 599)">
            <rect height="74" rx="5" stroke="none" width="25.615"></rect>
            <rect height="73" rx="4.5" width="24.615" x="0.5" y="0.5"></rect>
          </g>
        </g>
        <g data-name="Group 47645" fill="none" stroke="#cccfd9" strokeWidth="1" transform="rotate(90 832.46 -151.46)">
          <g className="js-previewer__marker" data-name="Rectangle 3096" id="TOP_RIGHT" transform="translate(1021 431)">
            <rect height="74" rx="5" stroke="none" width="20"></rect>
            <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
          </g>
          <g className="js-previewer__marker" data-name="Rectangle 3095" id="TOP_CENTER" transform="translate(1021 515)">
            <rect height="74" rx="5" stroke="none" width="20"></rect>
            <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
          </g>
          <g className="js-previewer__marker" data-name="Rectangle 3094" id="TOP_LEFT" transform="translate(1021 599)">
            <rect height="74" rx="5" stroke="none" width="20"></rect>
            <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { MobilePreviewer };
