import { ChangeEvent, useState } from 'react';

import { Icon, Input } from '@netfront/ui-library';

import { SearchFilterProps } from './SearchFilter.interfaces';

const SearchFilter = ({ onChange, onClear, onInputFocus, onSearch, ...rest }: SearchFilterProps) => {
  const [isClearButtonVisible, setIsClearButtonVisible] = useState<boolean>(false);

  const handleInputClear = () => {
    setIsClearButtonVisible(false);
    onClear();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    const hasSearchInput = Boolean(value.length);

    setIsClearButtonVisible(hasSearchInput);

    if (!hasSearchInput) {
      handleInputClear();
    }

    onChange?.(event);
  };

  const handleSearchOnEnter = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'Enter') {
      event.preventDefault();
      onSearch();
    }
  };

  return (
    <form className="c-search-filter">
      <div className="c-search-filter__input">
        <Icon className="c-search-filter__icon" id="id_search_icon" />
        <Input {...rest} onChange={handleInputChange} onFocus={onInputFocus} onKeyPress={handleSearchOnEnter as () => void} />
        {isClearButtonVisible && (
          <button className="c-search-filter__clear" title="Clear search" type="button" onClick={handleInputClear}>
            <Icon id="id_close_icon" />
          </button>
        )}
      </div>
    </form>
  );
};

export { SearchFilter };
