import { useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls, useProtectedRoute } from '@netfront/gelada-identity-library';
import { InformationBox, LinkButton } from '@netfront/ui-library';

import { BUTTON_CLASSES, WelcomeStepProgress } from '../../../components';
import { PageLayout } from '../../PageLayout';

const LetsStartUsingKanziPage = () => {
  const PAGE_TITLE = "You're all set!";

  const { isDomainReady } = useDomain();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [identityUrl, setIdentityUrl] = useState<string>('');

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(getBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <PageLayout
      additionalClassNames="c-page-layout-template--narrow"
      meta={{
        description: 'Configure your first project to get started using Kanzi',
        metaTitle: PAGE_TITLE,
      }}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {isAuthenticated && (
        <>
          <div className="flex flex-col items-center mb-8">
            <img alt="Kanzi logo" className="w-full max-w-16 mb-6" src="/images/kanzi-logo-icon.svg" />
            <h1 className="h2 mb-2">{PAGE_TITLE}</h1>
          </div>

          <WelcomeStepProgress currentStep={4} />

          <InformationBox
            iconId="id_info_icon"
            message="Once you install the script Kanzi will be up and running. We will monitor the installation and let you know when its successfully set up"
          />

          <div className="py-16 text-center">
            <LinkButton additionalClassNames={`${BUTTON_CLASSES.green}`} text="Go to dashboard" url={`${identityUrl}/dashboard`} />
          </div>
        </>
      )}
    </PageLayout>
  );
};

export { LetsStartUsingKanziPage };
