import { IconDesktop, IconMobile } from '../Icons';
import { Tab } from '../Tabs';

import { DesktopPreviewer } from './DesktopPreviewer';
import { PreviewerProps } from './LayoutPreviewer.interfaces';
import { MobilePreviewer } from './MobilePreviewer';

const LayoutPreviewer = ({ colour, position }: PreviewerProps) => {
  return (
    <>
      <Tab.Group>
        <div className="flex mb-3">
          <h3 className="h4 font-body mb-0 flex-1">Preview</h3>
          <Tab.List>
            <Tab className={({ selected }) => `${selected ? 'color-primary' : 'color-grey-300'} border-none pointer`}>
              <span className="sr-only">Switch to desktop view</span>
              <IconDesktop />
            </Tab>
            <Tab className={({ selected }) => `${selected ? 'color-primary' : 'color-grey-300'} border-none pointer`}>
              <span className="sr-only">Switch to mobile view</span>
              <IconMobile />
            </Tab>
          </Tab.List>
        </div>

        <Tab.Panels>
          <Tab.Panel>
            <DesktopPreviewer colour={colour} position={position} />
          </Tab.Panel>

          <Tab.Panel>
            <MobilePreviewer colour={colour} position={position} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export { LayoutPreviewer };
