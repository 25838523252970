import cx from 'classnames';

interface IIconExpand {
  additionalClassNames?: string;
}

const IconExpand = ({ additionalClassNames }: IIconExpand) => {
  return (
    <svg className={cx(additionalClassNames, 'c-kanzi-expand-block__expand-icon')} viewBox="0 0 7.107 5.083">
      <g id="arrow-icon" transform="translate(1.051 0.75)">
        <path
          d="M0,0,2.5,3.282,5,0"
          data-name="Path 42241"
          fill="none"
          id="Path_42241"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export { IconExpand };
