import { useLayoutPreviewer } from '../LayoutPreviewer.hooks';
import { PreviewerProps } from '../LayoutPreviewer.interfaces';

const DesktopPreviewer = ({ colour, position }: PreviewerProps) => {
  const { ref: desktopPreviewerRef } = useLayoutPreviewer({
    colour: colour === '' ? '#4bc2ca' : colour,
    position,
  });

  return (
    <svg ref={desktopPreviewerRef} height="100%" viewBox="0 0 604 529" width="100%" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Rectangle 3089" fill="#fff" stroke="#ecedf0" strokeWidth="1">
        <rect height="529" rx="5" stroke="none" width="604"></rect>
        <rect fill="none" height="528" rx="4.5" width="603" x="0.5" y="0.5"></rect>
      </g>
      <g data-name="Group 46920" transform="translate(-713 -360.919)">
        <path d="M5 0h594a5 5 0 015 5v23H0V5a5 5 0 015-5z" data-name="Rectangle 3090" fill="#173567" transform="translate(713 361)"></path>
        <g data-name="Group 46919" fill="#4a70af" transform="translate(727 370)">
          <circle cx="5" cy="5" data-name="Ellipse 848" r="5"></circle>
          <circle cx="5" cy="5" data-name="Ellipse 849" r="5" transform="translate(14)"></circle>
          <circle cx="5" cy="5" data-name="Ellipse 850" r="5" transform="translate(28)"></circle>
        </g>
      </g>
      <g transform="translate(103 152)">
        <g data-name="Group 46928">
          <g data-name="Group 46925">
            <rect data-name="Rectangle 3103" fill="#ecedf0" height="99.042" rx="5" transform="translate(226.35)" width="172.359"></rect>
            <g fill="#cccfd9" transform="translate(292.656 38.679)">
              <path d="M935.623 351.994l-10.272 16.93H945.9z" data-name="Path 542" transform="translate(-925.346 -344.328)"></path>
              <path d="M964.745 338.07l-14.118 23.268h28.236z" data-name="Path 543" transform="translate(-939.117 -336.742)"></path>
              <circle cx="4.532" cy="4.532" data-name="Ellipse 197" r="4.532"></circle>
            </g>
            <g>
              <rect data-name="Rectangle 2728" fill="#ecedf0" height="20.512" rx="10.256" width="89.079"></rect>
            </g>
            <g transform="translate(.293 38.738)">
              <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="195.207"></rect>
            </g>
            <g data-name="skeleton-title-medium" transform="translate(.293 62.766)">
              <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="195.207"></rect>
            </g>
            <g data-name="skeleton-title-medium" transform="translate(.293 86.795)">
              <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="164.679"></rect>
            </g>
          </g>
        </g>
        <g data-name="Group 46927" transform="translate(0 154)">
          <g data-name="Group 46926">
            <g data-name="skeleton-title-large">
              <rect data-name="Rectangle 2728" fill="#ecedf0" height="20.512" rx="10.256" width="89.079"></rect>
            </g>
            <g data-name="skeleton-title-medium" transform="translate(.293 38.738)">
              <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="398.416"></rect>
            </g>
            <g data-name="skeleton-title-medium" transform="translate(.293 62.766)">
              <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="398.416"></rect>
            </g>
            <g data-name="skeleton-title-medium" transform="translate(.293 86.795)">
              <rect data-name="Rectangle 2705" fill="#ecedf0" height="12.307" rx="6.153" width="164.679"></rect>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 47651" fill="none" stroke="#cccfd9" strokeWidth="1" transform="translate(19)">
        <g
          className="js-previewer__marker"
          data-name="Rectangle 3458"
          fill="none"
          id="RIGHT_TOP"
          stroke="#cccfd9"
          transform="translate(551 104)"
        >
          <rect height="74" rx="5" stroke="none" width="20" />
          <rect fill="none" height="73" rx="4.5" width="19" x="0.5" y="0.5" />
        </g>
        <g className="js-previewer__marker" data-name="Rectangle 3459" id="RIGHT_CENTER" transform="translate(551 242)">
          <rect height="74" rx="5" stroke="none" width="20"></rect>
          <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
        </g>
        <g
          className="js-previewer__marker"
          data-name="Rectangle 3460"
          fill="none"
          id="RIGHT_BOTTOM"
          stroke="#cccfd9"
          transform="translate(551 380)"
        >
          <rect height="74" rx="5" stroke="none" width="20" />
          <rect fill="none" height="73" rx="4.5" width="19" x="0.5" y="0.5" />
        </g>
      </g>
      <g data-name="Group 47652" fill="none" stroke="#cccfd9" strokeWidth="1" transform="translate(-537)">
        <g
          className="js-previewer__marker"
          data-name="Rectangle 3458"
          fill="none"
          id="LEFT_TOP"
          stroke="#cccfd9"
          transform="translate(551 104)"
        >
          <rect height="74" rx="5" stroke="none" width="20" />
          <rect fill="none" height="73" rx="4.5" width="19" x="0.5" y="0.5" />
        </g>
        <g className="js-previewer__marker" data-name="Rectangle 3459" id="LEFT_CENTER" transform="translate(551 242)">
          <rect height="74" rx="5" stroke="none" width="20"></rect>
          <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
        </g>
        <g
          className="js-previewer__marker"
          data-name="Rectangle 3460"
          fill="none"
          id="LEFT_BOTTOM"
          stroke="#cccfd9"
          transform="translate(551 380)"
        >
          <rect height="74" rx="5" stroke="none" width="20" />
          <rect fill="none" height="73" rx="4.5" width="19" x="0.5" y="0.5" />
        </g>
      </g>
      <g data-name="Group 47653" fill="none" stroke="#cccfd9" strokeWidth="1" transform="rotate(90 545 36)">
        <g className="js-previewer__marker" data-name="Rectangle 3458" id="TOP_RIGHT" transform="translate(551 104)">
          <rect height="74" rx="5" stroke="none" width="20"></rect>
          <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
        </g>
        <g className="js-previewer__marker" data-name="Rectangle 3459" id="TOP_CENTER" transform="translate(551 242)">
          <rect height="74" rx="5" stroke="none" width="20"></rect>
          <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
        </g>
        <g className="js-previewer__marker" data-name="Rectangle 3460" id="TOP_LEFT" transform="translate(551 380)">
          <rect height="74" rx="5" stroke="none" width="20"></rect>
          <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
        </g>
      </g>
      <g data-name="Group 47654" fill="none" stroke="#cccfd9" strokeWidth="1" transform="rotate(90 318.5 262.5)">
        <g className="js-previewer__marker" data-name="Rectangle 3458" id="BOTTOM_RIGHT" transform="translate(551 104)">
          <rect height="74" rx="5" stroke="none" width="20"></rect>
          <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
        </g>
        <g className="js-previewer__marker" data-name="Rectangle 3459" id="BOTTOM_CENTER" transform="translate(551 242)">
          <rect height="74" rx="5" stroke="none" width="20"></rect>
          <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
        </g>
        <g className="js-previewer__marker" data-name="Rectangle 3460" id="BOTTOM_LEFT" transform="translate(551 380)">
          <rect height="74" rx="5" stroke="none" width="20"></rect>
          <rect height="73" rx="4.5" width="19" x="0.5" y="0.5"></rect>
        </g>
      </g>
    </svg>
  );
};

export { DesktopPreviewer };
