import { gql } from '@apollo/client';

const GET_PROJECT_USAGE_QUERY = gql`
  query getProjectUsage(
    $after: String = null
    $filter: String = null
    $first: Int = null
    $from: DateTime = null
    $ianaTimeZone: String!
    $projectId: String!
    $to: DateTime = null
  ) {
    report {
      getProjectUsage: getUsages(
        after: $after
        filter: $filter
        first: $first
        from: $from
        ianaTimeZone: $ianaTimeZone
        to: $to
        projectId: $projectId
      ) {
        edges {
          cursor
          node {
            ...projectUsageFragment
          }
        }
        items {
          ...projectUsageFragment
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }

  fragment projectUsageFragment on ProjectUsageGraphType {
    action
    apiKeyTitle
    characters
    date
    operations
    referer
    sourceLanguage
    targetLanguage
  }
`;

export { GET_PROJECT_USAGE_QUERY };
