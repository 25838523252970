import { gql } from '@apollo/client';

import { API_KEY_FRAGMENT } from '../../../graphql';

const GET_PROJECT_QUERY = gql`
  query getProject($id: String!) {
    project {
      getProject: get(id: $id) {
        allowedTranslate
        allowedSpeech
        allowedPdf
        apiKeys {
          ...apiKeyFragment
        }
        configuration {
          hasDownloadOption
          hasSpeech
          hasStartFrom
          hasTranslate
          hasVolumeControl
          isActiveWordBold
          isActiveWordColored
          isActiveWordUnderlined
          isSentenceUnderlined
          languages
          position
          sentenceBackGroundColor
        }
        isActive
        name
        remainingPdf
        remainingSpeech
        remainingTranslate
      }
    }
  }

  ${API_KEY_FRAGMENT}
`;

export { GET_PROJECT_QUERY };
