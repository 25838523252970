import { createContext } from 'react';

import { IDashboardItemsList } from './DashboardContext.interfaces';

export interface IDashboardContext {
  dashboardItems: IDashboardItemsList[];
  dashboardLink: string | undefined;
}

export const DashboardContext = createContext<IDashboardContext>({
  dashboardItems: [],
  dashboardLink: undefined,
});

// eslint-disable-next-line import/no-default-export
export default DashboardContext;
