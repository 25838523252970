import { gql } from '@apollo/client';

import { API_KEY_FRAGMENT } from '../../../graphql';

const GET_API_KEY_QUERY = gql`
  query getApiKey($id: Int!) {
    apiKey {
      getApiKey: get(id: $id) {
        ...apiKeyFragment
      }
    }
  }

  ${API_KEY_FRAGMENT}
`;

export { GET_API_KEY_QUERY };
