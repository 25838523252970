import { gql } from '@apollo/client';

const STRIPE_CARD_FRAGMENT = gql`
  fragment stripeCardFragment on StripeCard {
    cardBrand
    cvcPass
    default
    expMonth
    expYear
    lastFourDigits
    stripeSourceId
  }
`;

export { STRIPE_CARD_FRAGMENT };
