const IconDesktop = () => {
  return (
    <svg height="22.54" id="desktop-icon" viewBox="0 0 25.358 22.54" width="25.358" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.244,0H2.113A2.114,2.114,0,0,0,0,2.113V16.2a2.114,2.114,0,0,0,2.113,2.113h8.453l-.7,2.113H6.692a1.057,1.057,0,1,0,0,2.113H18.666a1.057,1.057,0,1,0,0-2.113H15.5l-.7-2.113h8.453A2.114,2.114,0,0,0,25.358,16.2V2.113A2.114,2.114,0,0,0,23.244,0Zm-.7,15.5H2.818V2.818H22.54Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { IconDesktop };
