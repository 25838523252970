import { gql } from '@apollo/client';

const HTML_CONFIGURATION_FRAGMENT = gql`
  fragment htmlConfigurationFragment on HtmlConfigurationGraphType {
    apiKeyId
    feature
    id
    property
    type
    value
  }
`;

export { HTML_CONFIGURATION_FRAGMENT };
