import parseISO from 'date-fns/parseISO';

import { IDBProjectUsage, IProjectUsage } from '../../../interfaces';

const getUpdatedProjectUsage = (projectUsage: IDBProjectUsage): IProjectUsage => {
  const { date } = projectUsage;

  return {
    ...projectUsage,
    dateLocaleDateString: parseISO(date).toLocaleDateString(),
    dateLocaleTimeString: parseISO(date).toLocaleTimeString(),
  };
};

export { getUpdatedProjectUsage };
