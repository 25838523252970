import { IBusinessInformationFormFields } from './BusinessInformationForm.interfaces';

const BUSINESS_INFORMATION_FORM_INITIAL_VALUES: IBusinessInformationFormFields = {
  businessAddress: '',
  businessName: '',
  phoneNumber: '',
  mobilePhoneNumber: '',
  geoCode: {
    city: '',
    country: 'AU',
    postcode: '',
    state: '',
    streetName: '',
    streetNumber: '',
  },
};

export { BUSINESS_INFORMATION_FORM_INITIAL_VALUES };
