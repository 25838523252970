import { PageTitleBannerProps } from './PageTitleBanner.interfaces';

const PageTitleBanner = ({ afterTitle, beforeTitle, children, containerClassName, isTitleVisible = true, title }: PageTitleBannerProps) => {
  return (
    <section className={`flex flex-col bg-gradient-wave bg-gradient-wave--minimal ${isTitleVisible ? 'pt-48 pb-32' : 'py-24'}`}>
      <div className={`c-container my-auto text-center fade-in ${containerClassName ? containerClassName : ''}`}>
        {beforeTitle ? beforeTitle : null}
        {isTitleVisible && <h1 className="h1 color-white mb-4">{title}</h1>}
        {afterTitle ? <h2 className="h4 color-white font-body weight-400 mb-4">{afterTitle}</h2> : null}
        {children}
      </div>
    </section>
  );
};

export { PageTitleBanner };
