import { gql } from '@apollo/client';

const GET_PDF_USAGE_QUERY = gql`
  query getPdfUsages(
    $after: String = null
    $filter: String = null
    $first: Int = null
    $from: DateTime = null
    $ianaTimeZone: String!
    $projectId: String!
    $to: DateTime = null
  ) {
    report {
      getPdfUsages(
        after: $after
        filter: $filter
        first: $first
        from: $from
        ianaTimeZone: $ianaTimeZone
        to: $to
        projectId: $projectId
      ) {
        edges {
          cursor
          node {
            apiKey {
              id
              title
            }
            count
            date
            url
          }
        }
        items {
          apiKey {
            guid
          }
          count
          date
          url
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;

export { GET_PDF_USAGE_QUERY };
