import { ChangeEvent, useState } from 'react';

import { IDBDirectory } from '@netfront/ekardo-content-library';
import { Button, InformationBox, Input } from '@netfront/ui-library';

import { FAQPageProps } from './FAQPage.interfaces';

import { PublicPageLayout } from '../../PublicPageLayout';

export const FAQPage = ({ faqDirectories }: FAQPageProps) => {
  const tagSet = [...new Set(faqDirectories.map((faq) => faq.tags))] as string[];

  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredDirectories, setFilteredDirectories] = useState<IDBDirectory[]>(faqDirectories);

  const [tags, setTags] = useState<{ isVisible: boolean; tag: string }[]>(tagSet.map((tag) => ({ tag, isVisible: true })));

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (value === '') {
      setFilteredDirectories(faqDirectories);
      return;
    }

    setSearchValue(value);
  };

  const onTagClick = (tag: string) => {
    const udpatedTags = tags.map((t) => (t.tag.includes(tag) ? { ...t, isVisible: !t.isVisible } : { ...t }));
    setTags(udpatedTags);
  };

  const handleSearch = () => {
    setFilteredDirectories([...faqDirectories].filter((directory) => directory.title.toLowerCase().includes(searchValue.toLowerCase())));
  };

  return (
    <PublicPageLayout
      afterTitle="Find answers to general configuration, billing and security questions"
      hasContainer={false}
      title="Frequently asked questions"
    >
      <div className="c-container py-8">
        <InformationBox
          additionalClassNames="mb-12"
          message="Our FAQs provides answers to commonly asked questions, if there is anything else you need to know use the online chat feature or send us an email via our contact form"
        />

        <div className="mb-8 md:mb-12 md:flex">
          <form
            className="flex"
            onSubmit={(event) => {
              event.preventDefault();
              handleSearch();
            }}
          >
            <Input
              additionalClassNames="max-w-full mb-4 md:mb-0 md:mr-0 md:w-88 mr-4 p-4"
              id="search-faq"
              labelText="Search frequently asked questions"
              name="search faq"
              placeholder="Search frequently asked questions"
              type="text"
              isLabelHidden
              onChange={handleSearchTextChange}
            />
            <Button additionalClassNames="mr-8 my-1" text="Search" type="submit" variant="cta" />
          </form>

          <div className="flex flex-wrap">
            {tags.map(({ tag, isVisible }) => {
              return (
                <Button
                  key={tag}
                  additionalClassNames="mr-2 my-1"
                  text={String(tag)}
                  variant={isVisible ? 'primary' : 'primary-inverse'}
                  onClick={() => onTagClick(String(tag))}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="bg-burgandy-100 py-12">
        <div className="c-container">
          {[...tags].map(({ tag, isVisible }) => {
            if (isVisible) {
              return (
                <>
                  <h2>{tag}</h2>
                  {filteredDirectories.map((faq) => {
                    if (faq.tags === tag) {
                      return (
                        <div key={faq.id} className="mb-6 bg-white p-8 rounded">
                          <h3>{faq.title}</h3>
                          <p className="mb-0">{faq.description}</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </>
              );
            }

            return null;
          })}
        </div>
      </div>
    </PublicPageLayout>
  );
};
