import { GET_ACTIVE_PLANS_QUERY } from './useGetActivePlans.graphql';
import {
  IGetActivePlansQueryGraphQLResponse,
  IGetActivePlans,
  IUseGetActivePlansOptions,
  IHandleGetActivePlansParams,
} from './useGetActivePlans.interfaces';

import { useMuriquiLazyQuery } from '../../apollo';

const useGetActivePlans = (options?: IUseGetActivePlansOptions): IGetActivePlans => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetActivePlansOptions);

  const [executeGetActivePlans, { loading: isLoading }] = useMuriquiLazyQuery<IGetActivePlansQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          plans: { getActivePlans: plans },
        } = data;

        onCompleted({
          plans,
        });
      },
      onError,
    },
    query: query ?? GET_ACTIVE_PLANS_QUERY,
    token,
  });

  const handleGetActivePlans = async ({ projectId, product }: IHandleGetActivePlansParams) => {
    await executeGetActivePlans({
      variables: {
        product,
        projectId,
      },
    });
  };

  return {
    handleGetActivePlans,
    isLoading,
  };
};

export { useGetActivePlans };
