import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Icon } from '@netfront/ui-library';
import { format } from 'date-fns';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ISelectedPlan, PDF_PRODUCT_ID, SPEECH_PRODUCT_ID, TRANSLATE_PRODUCT_ID } from '../ProPlanCard';

import { useGetActivePlans, IGetActivePlansOnCompletedResponse, useToast, useGetMuriquiProject, IDBDowngradeRequest } from '../../hooks';

const SubscriptionDetails = () => {
  const { query } = useRouter();
  const { organisationKey, projectId } = query;

  const [downgradeRequest, setDowngradeRequest] = useState<IDBDowngradeRequest | undefined>();

  const { handleGetMuriquiProject } = useGetMuriquiProject({
    fetchPolicy: 'no-cache',
    onCompleted: ({ project }) => {
      const {
        customerDetail: { subscriptions },
      } = project;
      if (!subscriptions || subscriptions.length === 0) return;
      const subscriptionWithDowngradeRequest = subscriptions.find((r) => Boolean(r.downgradeRequest));
      if (!subscriptionWithDowngradeRequest) return;
      const { downgradeRequest: request } = subscriptionWithDowngradeRequest;
      if (!request) return;
      setDowngradeRequest(request);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleToastError } = useToast();
  const [plans, setPlans] = useState<ISelectedPlan>();

  const handleGetActivePlansCompleted = (data?: IGetActivePlansOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { plans: activePlans = [] } = data;

    setPlans({
      pdf: activePlans.find(({ productId }) => productId === PDF_PRODUCT_ID),
      speech: activePlans.find(({ productId }) => productId === SPEECH_PRODUCT_ID),
      translate: activePlans.find(({ productId }) => productId === TRANSLATE_PRODUCT_ID),
    });
  };

  useEffect(() => {
    handleGetMuriquiProject({ projectId: String(projectId) });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApolloError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetActivePlans } = useGetActivePlans({
    onCompleted: handleGetActivePlansCompleted,
    onError: handleApolloError,
  });

  useEffect(() => {
    handleGetActivePlans({
      projectId: String(projectId),
      product: 'KANZI',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="c-subscription-details">
      {plans && (
        <div aria-label="Current project plans" className="c-subscription-details__plans">
          <div className="c-subscription-details__plan">
            <p>
              <strong>Translate:</strong> {plans.translate?.name}
            </p>
          </div>
          {plans.speech && (
            <div className="c-subscription-details__plan">
              <p>
                <strong>Speech:</strong> {plans.speech.name}
              </p>
            </div>
          )}
          <div className="c-subscription-details__plan">
            <p>
              <strong>PDF:</strong> {plans.pdf?.name}
            </p>
          </div>
        </div>
      )}
      {downgradeRequest && (
        <p>
          <>
            <strong>Downgrade:</strong> {format(new Date(downgradeRequest.date), 'dd/MM/yyy')}
          </>
        </p>
      )}

      <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/payment`}>
        <span 
          className="c-button c-plan-usage__link c-button--cta c-button--xs c-button__text--icon-only" 
          id="change-plan" 
        >
          <span className="c-button__text">
            <Icon className="c-icon c-button__icon" id="id_payment_icon"/>
            <span className="c-button__text--hidden">Change plan</span>
          </span>
        </span>
      </Link>
    </div>
  );
};

export { SubscriptionDetails };
