import { useCookie, useGeladaAccessTokenDecoder } from '@netfront/common-library';

import PermissionContext from './PermissionContext';
import { PermissionContextProps } from './PermissionContext.interfaces';

export type PermissionType = 'LIMITED' | 'NONE' | 'MANAGE_USERS' | 'EDIT' | 'READ' | 'LIMITED' | 'COLLABORATE';
interface IPermission {
  _c: IPermission[];
  id: string;
  p: number;
  t: number;
}
const PERMISSIONS: {[key: number]: PermissionType} = {
  0: 'NONE',
  2: 'MANAGE_USERS',
  4: 'EDIT',
  8: 'COLLABORATE',
  16: 'READ',
  32: 'LIMITED',
};

const PERMISSIONS_MAPPING = {
  LIMITED: ['READ'],
  NONE: [],
  MANAGE_USERS: ['EDIT', 'READ'],
  EDIT: ['READ'],
  COLLABORATE: ['EDIT', 'READ'],
  READ: [],
};

export function PermissionProvider({ children }: PermissionContextProps) {
  const { getDecodedJwt, getJwtClaim } = useGeladaAccessTokenDecoder();
  const { getAccessTokenCookie } = useCookie();

  const getDecodedPermissions = (): IPermission[] | undefined => {
    const token = getAccessTokenCookie();
    if (!token) return undefined;

    const decoded = getDecodedJwt(token);
    const permissions = getJwtClaim(decoded, 'permissions');
    if (!permissions) return undefined;

    return JSON.parse(String(permissions)) as IPermission[];
  };

  const loopThroughPermissions = (
    id: string,
    permissions: IPermission[],
    permission?: number,
  ): PermissionType | undefined => {
    for (let i = 0; i != permissions.length; i++) {
      const right = lookThroughEntityOrSubEntity(id, permissions[i], permission);
      if (right !== undefined) return right;
    }
    return undefined;
  };

  const lookThroughEntityOrSubEntity = (
    targetId: string,
    entity: IPermission,
    inheritedPermission?: number,
  ): PermissionType | undefined => {
    const { id, p: permission, _c: subEntities } = entity;
    if (targetId === id) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return inheritedPermission ? PERMISSIONS[inheritedPermission] : PERMISSIONS[permission];
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (subEntities) return loopThroughPermissions(targetId, subEntities, inheritedPermission ?? permission);

    return undefined;
  };

  const getPermission = (id: string): PermissionType | undefined => {
    const permissions = getDecodedPermissions();
    if (!permissions) return 'NONE';
    return loopThroughPermissions(id, permissions);
  };

  const hasPermission = (id: string, permission: PermissionType): boolean => {
    const permissions = getDecodedPermissions();
    if (!permissions) return false;

    for (let i = 0; i != permissions.length; i++) {
      const right = lookThroughEntityOrSubEntity(id, permissions[i]);
      if (right !== undefined) return right === permission || (PERMISSIONS_MAPPING[right] as string[]).includes(permission);
    }

    return false;
  };

  return <PermissionContext.Provider value={{ getPermission, hasPermission }}>{children}</PermissionContext.Provider>;
}
