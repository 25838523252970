import { ISelectedPlan } from '../../../components';
import { IPlan } from '../../../interfaces';

const getPlanDescription = (planType: string, projectPlan: ISelectedPlan) => {
  const plan: IPlan | undefined = projectPlan[planType as keyof ISelectedPlan];

  if (!plan) {
    return undefined;
  }

  const { description } = plan;

  return description ?? '';
};

export { getPlanDescription };
