import { APPROVE_RESTRICTION_MUTATION } from './useApproveRestriction.graphql';
import {
  IApproveRestrictionMutationGraphQLResponse,
  IApproveRestrictionMutationVariables,
  IHandleApproveRestrictionParams,
  IUseApproveRestrictionOptions,
  IUseApproveRestriction,
} from './useApproveRestriction.interfaces';

import { useKanziMutation } from '../../apollo';

const useApproveRestriction = (options?: IUseApproveRestrictionOptions): IUseApproveRestriction => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseApproveRestrictionOptions);

  const [executeApproveRestriction, { loading: isLoading }] = useKanziMutation<
    IApproveRestrictionMutationGraphQLResponse,
    IApproveRestrictionMutationVariables
  >({
    mutation: mutation ?? APPROVE_RESTRICTION_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          restriction: { ApproveRestriction: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleApproveRestriction = async ({ id, validate }: IHandleApproveRestrictionParams) => {
    await executeApproveRestriction({
      variables: {
        id,
        validate,
      },
    });
  };

  return {
    handleApproveRestriction,
    isLoading,
  };
};

export { useApproveRestriction };
