import { useEffect, useState, useContext } from 'react';

import { useJwtDecoder, useCookie, IGeladaAccessTokenPayload } from '@netfront/common-library';
import { useIdentitySiteUrls, useDomain } from '@netfront/gelada-identity-library';
import { Dropdown, Icon, OrganisationNavigation, OrganisationNavigationItemProps } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { CachingEntitiesContext } from '../../contexts';

const OrganisationMenu = () => {
  const { query } = useRouter();

  const { projectId, organisationKey } = query;

  const { isDomainReady } = useDomain();

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie } = useCookie();

  const { project, organisation } = useContext(CachingEntitiesContext);

  const { getBaseUrl: identityBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isCustomBuild, setIsCustomBuild] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [navigationList, setNavigationList] = useState<OrganisationNavigationItemProps[]>([]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(identityBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!identityUrl) return;

    const dropdownNavigationItems: OrganisationNavigationItemProps[] = [
      {
        isHidden: !organisation,
        header: {
          title: `Current account`,
          name: organisationKey ? organisation?.name  ?? '': 'Organisations',
          iconId: 'id_switch_icon',
          href: !isCustomBuild ? `${identityUrl}/dashboard`: undefined
        },
        options: [
          {
            iconId: 'id_team_icon',
            label: 'Team',
            isHidden: isCustomBuild,
            href: `${identityUrl}/account/${String(organisationKey)}/team`,
          },
        ],
      },
      {
        isHidden: !project,
        header: {
          title: `Current project`,
          name: project?.name ?? '',
          iconId: 'id_switch_icon',
          href: !isCustomBuild ? `${identityUrl}/dashboard`: undefined
        },
        options: [
          {
            iconId: 'id_users_icon',
            label: 'Team',
            isHidden: !project,
            href: `${identityUrl}/project/${String(projectId)}/user`
          },
        ],
      },
    ];

    setNavigationList(dropdownNavigationItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityUrl, projectId, organisationKey, organisation, project]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      return;
    }

    const decoded = getDecodedJwt(accessToken) as IGeladaAccessTokenPayload;
    const claim = decoded.custom_build;

    setIsCustomBuild(Boolean(String(claim).toLowerCase() === 'true'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="c-organisation-menu">
      {organisation && (
        <Dropdown
          dropdownId="organisation-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<><Icon className="c-app-header__item" id="id_organisation_icon" /><span className="h-hide-visually">Toggle organisation menu</span></>}
          onDisplayContent={setSearchIsContentVisible}
        >
          <div className="c-organisation-menu">
            <OrganisationNavigation
              dropdownNavigationItems={navigationList}
            />
          </div>

        </Dropdown>
      )}
    </div>
  );
};

export { OrganisationMenu };
