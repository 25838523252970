import { IPricePlanCard } from './PricePlanCards.interfaces';

const CARD_PALETTE = ['bg-green-400', 'bg-green-500', 'bg-green-600', 'bg-green-700'];

const PRICE_PLANS: IPricePlanCard[] = [
  {
    characters: '20 000',
    link: '/register',
    price: 'Free',
    title: 'Starter',
  },
  {
    characters: '1 million',
    link: '/register',
    paymentCycle: 'per month',
    price: '$15',
    title: 'Text to speech',
  },
  {
    characters: '1 million',
    link: '/register',
    paymentCycle: 'per month',
    price: '$50',
    title: 'Translate',
  },
  {
    characters: '1 million',
    link: '/register',
    paymentCycle: 'per month',
    price: '$50',
    title: 'Translated speech',
  },
];

export { CARD_PALETTE, PRICE_PLANS };
