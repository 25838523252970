import { GET_RESTRICTIONS_BY_API_KEY_QUERY } from './useGetRestrictionsByApiKey.graphql';
import {
  IGetRestrictionsByApiKeyQueryGraphQLResponse,
  IGetRestrictionsByApiKeyQueryVariables,
  IHandleGetRestrictionsByApiKeyParams,
  IUseGetRestrictionsByApiKey,
  IUseGetRestrictionsByApiKeyOptions,
} from './useGetRestrictionsByApiKey.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useGetRestrictionsByApiKey = (options?: IUseGetRestrictionsByApiKeyOptions): IUseGetRestrictionsByApiKey => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetRestrictionsByApiKeyOptions);

  const [executeGetRestrictionsByApiKey, { loading: isLoading }] = useKanziLazyQuery<
    IGetRestrictionsByApiKeyQueryGraphQLResponse,
    IGetRestrictionsByApiKeyQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          restriction: { getRestrictionsByApiKey: restrictions },
        } = data;

        onCompleted({
          restrictions,
        });
      },
      onError,
    },
    query: query ?? GET_RESTRICTIONS_BY_API_KEY_QUERY,
    token,
  });

  const handleGetRestrictionsByApiKey = ({ apiKeyId }: IHandleGetRestrictionsByApiKeyParams) => {
    executeGetRestrictionsByApiKey({
      variables: {
        apiKeyId,
      },
    });
  };

  return {
    handleGetRestrictionsByApiKey,
    isLoading,
  };
};

export { useGetRestrictionsByApiKey };
