import { gql } from '@apollo/client';

import { API_KEY_FRAGMENT } from '../../../graphql';

const CREATE_API_KEY_MUTATION = gql`
  mutation createApiKey($projectId: String!, $title: String!, $type: EApiKeyType!) {
    apiKey {
      createApiKey: add(projectId: $projectId, title: $title, type: $type) {
        ...apiKeyFragment
      }
    }
  }

  ${API_KEY_FRAGMENT}
`;

export { CREATE_API_KEY_MUTATION };
