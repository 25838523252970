import { IRadioButtonItem } from '@netfront/ui-library';
import { RestrictionType } from 'interfaces';

import { RESTRICTION_TYPE_FRIENDLY_LABEL_MAP } from './RestrictionsView.constants';

const getRadioButtonItems = (data: readonly string[]): IRadioButtonItem[] => {
  return data.map((item) => {
    const option = {
      id: item,
      labelText: RESTRICTION_TYPE_FRIENDLY_LABEL_MAP[item as RestrictionType],
      value: item,
    };

    return option;
  });
};

export { getRadioButtonItems };
