import { gql } from '@apollo/client';

const DELETE_API_KEY_MUTATION = gql`
  mutation deleteApiKey($id: Int!) {
    apiKey {
      deleteApiKey: delete(id: $id)
    }
  }
`;

export { DELETE_API_KEY_MUTATION };
