import { useState, useEffect } from 'react';

import { useCookie, useGeladaAccessTokenDecoder } from '@netfront/common-library';
import { useAuthentication, useDomain, useIdentitySiteUrls, useLogout } from '@netfront/gelada-identity-library';
import { Button, SideBar } from '@netfront/ui-library';
import cx from 'classnames';
import { useToast } from 'hooks';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { IconMenu } from '../Icons';
import { LinkButton, BUTTON_CLASSES } from '../LinkButton';

import { PUBLIC_MENU_ITEMS } from './PublicHeader.constants';

/**
 * @todo
 * Swap native buttons for netfront ui. Current library doesn't allow unstyled theme/classnames
 */

const PublicHeader = () => {
  const { push } = useRouter();
  const { handleToastError } = useToast();
  const { isAuthenticated } = useAuthentication();
  const { getAccessTokenCookie, getLastProjectVisitedCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getDecodedJwt, getJwtUserId } = useGeladaAccessTokenDecoder();
  const { handleLogout } = useLogout({
    onCompleted: () => {
      push('/').catch((error) =>
        handleToastError({
          error,
        }),
      );
    },
  });

  const { getLoginUrl, getRegistrationUrl, getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { asPath } = useRouter();

  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [loginUrl, setLoginUrl] = useState<string>();
  const [domain, setDomain] = useState<string>();
  const [registrationUrl, setRegistrationUrl] = useState<string>();
  const [organisationKey, setOrganisationKey] = useState<string>();
  const [projectId, setProjectId] = useState<string>('');

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setLoginUrl(getLoginUrl());
    setDomain(getDomain());
    setRegistrationUrl(getRegistrationUrl());
    setIdentityUrl(getBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    const lastProjectVisited = getLastProjectVisitedCookie();

    if (!lastProjectVisited) {
      return;
    }

    const [lastProjectVisitedOrganisationKey, lastProjectVisitedProjectId, lastProjectVisitedUserId] = lastProjectVisited.split('/');

    const accessToken = getAccessTokenCookie();

    if (accessToken) {
      const decodedAccessToken = getDecodedJwt(accessToken);
      const userId = getJwtUserId(decodedAccessToken);

      if (lastProjectVisitedUserId !== String(userId)) {
        return;
      }
    }

    setOrganisationKey(lastProjectVisitedOrganisationKey);
    setProjectId(lastProjectVisitedProjectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashboardRoute = organisationKey && projectId ? `/dashboard/${organisationKey}/${projectId}` : `${identityUrl}/dashboard`;

  return (
    <header className="absolute w-full z-2">
      <div className="flex items-center c-container c-container--large py-8">
        <div className="flex-1">
          <NextLink href="/">
            <span>
              <span className="sr-only">Link to homepage</span>
              <img alt="Link to homepage" className="max-w-16 md:max-w-18" src="/images/kanzi-logo-full.svg" />
            </span>
          </NextLink>
        </div>

        <div className="none lg:flex">
          <nav aria-label="Main navigation" className="flex items-center">
            <ul className="flex items-center list-none mb-0">
              {PUBLIC_MENU_ITEMS.map(({ href, label }, key) => (
                <li key={`${label}-${key}`} className="ml-12 mb-0">
                  <LinkButton
                    additionalClassNames={cx(`${BUTTON_CLASSES['subtle-link']} min-w-auto color-white text-lg hover:opacity-1 transition`, {
                      'opacity-75': asPath !== href,
                      'opacity-1': asPath === href,
                    })}
                    text={label}
                    url={href}
                  />
                </li>
              ))}
            </ul>
          </nav>

          <nav aria-label={!isAuthenticated ? 'Login and registration' : 'Logout and return to dashboard'} className="flex ml-20">
            {!isAuthenticated ? (
              <>
                {registrationUrl && (
                  <LinkButton additionalClassNames={`${BUTTON_CLASSES.green} mr-3`} text="Sign up for free" url={registrationUrl} />
                )}
                {loginUrl && <LinkButton additionalClassNames={`${BUTTON_CLASSES.white}`} text="Sign in" url={loginUrl} />}
              </>
            ) : (
              <div className="flex gap-4">
                <LinkButton additionalClassNames={`${BUTTON_CLASSES.green}`} text="Return to dashboard" url={dashboardRoute} />
                {domain && (
                  <Button
                    additionalClassNames={`${BUTTON_CLASSES.white}`}
                    text="Log out"
                    onClick={() => {
                      handleLogout({
                        domain,
                      });
                    }}
                  />
                )}
              </div>
            )}
          </nav>
        </div>

        <div className="flex lg:none">
          <button aria-label="Main navigation button" className="border-none bg-transparent p-0 pointer" onClick={toggleSideMenu}>
            <IconMenu />
          </button>
          <SideBar isSideBarOpen={isSideMenuOpen} isCloseButtonVisible onClose={toggleSideMenu}>
            <div className="text-center mb-24">
              <NextLink href="/">
                <span>
                  <span className="sr-only">Link to homepage</span>
                  <img alt="Link to homepage" loading="lazy" src="/images/kanzi-logo-full-dark.svg" />
                </span>
              </NextLink>
            </div>

            <nav aria-label="Main mobile navigation" className="mb-12">
              <ul className="list-none mb-0 p-0 text-center">
                {PUBLIC_MENU_ITEMS.map(({ href, label }, key) => (
                  <li key={`${label}-${key}`} className="mb-8 ml-0">
                    <LinkButton
                      additionalClassNames={cx(`${BUTTON_CLASSES['subtle-link']} color-black  text-lg hover:opacity-1 transition`, {
                        'opacity-75': asPath !== href,
                        'opacity-1': asPath === href,
                      })}
                      text={label}
                      url={href}
                    />
                  </li>
                ))}
              </ul>
            </nav>
            <nav
              aria-label={!isAuthenticated ? 'Login and registration' : 'Logout and return to dashboard'}
              className="flex flex-col items-center ml-0"
            >
              {!isAuthenticated ? (
                <>
                  {registrationUrl && (
                    <LinkButton additionalClassNames={`${BUTTON_CLASSES.green} mb-4`} text="Sign up for free" url={registrationUrl} />
                  )}

                  {loginUrl && <LinkButton additionalClassNames={`${BUTTON_CLASSES.white}`} text="Sign in" url={loginUrl} />}
                </>
              ) : (
                <>
                  <LinkButton additionalClassNames={`${BUTTON_CLASSES.green}`} text="Return to dashboard" url={dashboardRoute} />
                  {domain && (
                    <Button
                      additionalClassNames={`${BUTTON_CLASSES.white}`}
                      text="Log out"
                      onClick={() => {
                        handleLogout({
                          domain,
                        });
                      }}
                    />
                  )}{' '}
                </>
              )}
            </nav>
          </SideBar>
        </div>
      </div>
    </header>
  );
};

export { PublicHeader };
