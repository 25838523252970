import { ITab } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IApiKeyTableData } from './IntegrationsPage.interfaces';

import { AnalyticsView, GeneralView, RestrictionsView, RulesView, VoicesView } from '../../ApiMenu';

const PAGE_TITLE = 'Install codes';

const SIDE_BAR_TABS: ITab[] = [
  {
    id: 'id_general_tab',
    label: 'General',
    iconId: 'id_general_tab_icon',
    view: GeneralView,
  },
  {
    id: 'id_restrictions_tab',
    label: 'Restrictions',
    iconId: 'id_seo_tab_icon',
    view: RestrictionsView,
  },
  {
    id: 'id_voices_tab',
    label: 'Voices',
    iconId: 'id_audio_icon',
    view: VoicesView,
  },
  {
    id: 'id_rules_tab',
    label: 'Rules',
    iconId: 'id_navigation_tab_icon',
    view: RulesView,
  },
  {
    id: 'id_analytics_tab',
    label: 'Analytics',
    iconId: 'id_seo_tab_icon',
    view: AnalyticsView,
  },
];

const TABLE_COLUMNS: ReadonlyArray<Column<IApiKeyTableData>> = [
  {
    accessor: 'title',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left">Name</div>,
    width: '20%',
  },
  {
    accessor: 'serializedRestrictions',
    Cell: ({ value }) => (
      <div className="text-left w-full word-break-all">
        {String(value).split(', ').length > 1 ? `${String(value).split(', ')[0]}...` : value}
      </div>
    ),
    Header: () => <div className="text-left">Restrictions</div>,
    width: '20%',
  },
  {
    accessor: 'creationDateLocaleDateString',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left">Created</div>,
    width: '20%',
  },
  {
    accessor: 'guid',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left">Key</div>,
    width: '50%',
  },
  {
    accessor: 'settingsButton',
    Cell: ({ value }) => <div className="flex justify-end">{value}</div>,
    Header: '',
  },
];

const INTEGRATION_PAGE_CONSTANTS = Object.freeze({
  sideBarTabs: SIDE_BAR_TABS,
  tableColumns: TABLE_COLUMNS,
  pageTitle: PAGE_TITLE,
});

export { INTEGRATION_PAGE_CONSTANTS };
