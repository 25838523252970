/* eslint-disable @typescript-eslint/indent */
import { GET_STRIPE_PAYMENT_METHODS_QUERY } from './useGetStripePaymentMethods.graphql';
import {
  IGetStripePaymentMethodsQueryGraphQLResponse,
  IGetStripePaymentMethodsQueryVariables,
  IHandleGetStripePaymentMethodsParams,
  IUseGetStripePaymentMethods,
  IUseGetStripePaymentMethodsOptions,
} from './useGetStripePaymentMethods.interfaces';

import { useMuriquiLazyQuery } from '../../apollo';

const useGetStripePaymentMethods = (options?: IUseGetStripePaymentMethodsOptions): IUseGetStripePaymentMethods => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetStripePaymentMethodsOptions);

  const [executeGetStripePaymentMethods, { loading: isLoading }] = useMuriquiLazyQuery<
    IGetStripePaymentMethodsQueryGraphQLResponse,
    IGetStripePaymentMethodsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          payments: { getStripePaymentMethods: stripePaymentMethods },
        } = data;

        onCompleted({
          stripePaymentMethods,
        });
      },
      onError,
    },
    query: query ?? GET_STRIPE_PAYMENT_METHODS_QUERY,
    token,
  });

  const handleGetStripePaymentMethods = async ({ projectId, product }: IHandleGetStripePaymentMethodsParams) => {
    await executeGetStripePaymentMethods({
      variables: {
        projectId,
        product,
      },
    });
  };

  return {
    handleGetStripePaymentMethods,
    isLoading,
  };
};

export { useGetStripePaymentMethods };
