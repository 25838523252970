import { IOption } from '@netfront/ui-library';

import { ToolTheme } from '../../interfaces';

const POSITION_OPTIONS: IOption[] = [
  {
    id: 1,
    name: 'Bottom center',
    value: 'BOTTOM_CENTER',
  },
  {
    id: 2,
    name: 'Bottom left',
    value: 'BOTTOM_LEFT',
  },
  {
    id: 3,
    name: 'Bottom right',
    value: 'BOTTOM_RIGHT',
  },
  {
    id: 4,
    name: 'Left top',
    value: 'LEFT_TOP',
  },
  {
    id: 5,
    name: 'Left center',
    value: 'LEFT_CENTER',
  },
  {
    id: 6,
    name: 'Left bottom',
    value: 'LEFT_BOTTOM',
  },
  {
    id: 7,
    name: 'Right top',
    value: 'RIGHT_TOP',
  },
  {
    id: 8,
    name: 'Right center',
    value: 'RIGHT_CENTER',
  },
  {
    id: 9,
    name: 'Right bottom',
    value: 'RIGHT_BOTTOM',
  },
  {
    id: 10,
    name: 'Top center',
    value: 'TOP_CENTER',
  },
  {
    id: 11,
    name: 'Top left',
    value: 'TOP_LEFT',
  },
  {
    id: 12,
    name: 'Top right',
    value: 'TOP_RIGHT',
  },
];

const SIZE_OPTIONS: IOption[] = [
  {
    id: 1,
    name: 'Small',
    value: 'SMALL',
  },
  {
    id: 2,
    name: 'Medium',
    value: 'MEDIUM',
  },
  {
    id: 3,
    name: 'Large',
    value: 'LARGE',
  },
];

const THEME_OPTIONS: IOption[] = [
  {
    id: 1,
    name: 'Light',
    value: ToolTheme.LIGHT,
  },
  {
    id: 2,
    name: 'Dark',
    value: ToolTheme.DARK,
  },
];

export { POSITION_OPTIONS, SIZE_OPTIONS, THEME_OPTIONS };
