import { gql } from '@apollo/client';

const DELETE_RESTRICTION_MUTATION = gql`
  mutation deleteRestriction($id: Int!) {
    restriction {
      deleteRestriction: remove(id: $id)
    }
  }
`;

export { DELETE_RESTRICTION_MUTATION };
