/* eslint-disable @typescript-eslint/indent */
import { useMuriquiMutation } from '../../apollo';

import { ACTIVATE_PRODUCT } from './useActivateProduct.graphql';
import {
  IActivateProductMutationGraphQLResponse,
  IActivateProductMutationVariables,
  IHandleActivateProductParams,
  IUseActivateProductOptions,
  IUseActivateProduct,
} from './useActivateProduct.interfaces';

const useActivateProduct = (options?: IUseActivateProductOptions): IUseActivateProduct => {
  const { onCompleted, onError, product, token } = options ?? ({} as IUseActivateProductOptions);

  const [executeActivateProduct, { loading: isLoading }] = useMuriquiMutation<
    IActivateProductMutationGraphQLResponse,
    IActivateProductMutationVariables
  >({
    mutation: ACTIVATE_PRODUCT,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          projects: {
            activateProduct: {
              result: { result },
            },
          },
        } = data;

        onCompleted({
          result,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleActivateProduct = async ({ projectId, name, organisationId }: IHandleActivateProductParams) => {
    await executeActivateProduct({
      variables: {
        projectId,
        name,
        organisationId,
      },
    });
  };

  return {
    handleActivateProduct,
    isLoading,
  };
};

export { useActivateProduct };
