import { useEffect } from 'react';

import { CMSContentPage, useContentPageContext } from '@netfront/ekardo-content-library';
import { useRouter } from 'next/router';

import { ContentPageProps } from './ContentPage.interfaces';

import { PublicPageLayout } from '../../PublicPageLayout';

const ContentPage = ({ contentPage }: ContentPageProps) => {
  const { dispatch } = useContentPageContext();

  const { seoDescription, seoTitle, title } = contentPage;

  const {
    query: { pageUrl },
  } = useRouter();

  useEffect(() => {
    if (!pageUrl) {
      return;
    }

    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUrl]);

  return (
    <PublicPageLayout meta={{ description: seoDescription, metaTitle: seoTitle }} title={title}>
      <CMSContentPage />
    </PublicPageLayout>
  );
};

export { ContentPage };
