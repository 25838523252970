import { ACTIVATE_PROJECT_MUTATION } from './useActivateProject.graphql';
import {
  IActivateProjectMutationGraphQLResponse,
  IActivateProjectMutationVariables,
  IHandleActivateProjectParams,
  IUseActivateProjectOptions,
  IUseActivateProject,
} from './useActivateProject.interfaces';

import { useKanziMutation } from '../../apollo';

const useActivateProject = (options?: IUseActivateProjectOptions): IUseActivateProject => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseActivateProjectOptions);

  const [executeActivateProject, { loading: isLoading }] = useKanziMutation<
    IActivateProjectMutationGraphQLResponse,
    IActivateProjectMutationVariables
  >({
    mutation: mutation ?? ACTIVATE_PROJECT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { activateProject: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleActivateProject = async ({ id, name }: IHandleActivateProjectParams) => {
    await executeActivateProject({
      variables: {
        id,
        name,
      },
    });
  };

  return {
    handleActivateProject,
    isLoading,
  };
};

export { useActivateProject };
