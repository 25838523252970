/* eslint-disable @typescript-eslint/indent */
import { UPDATE_PROJECT_SUBSCRIPTION_MUTATION } from './useUpdateProjectSubscription.graphql';
import {
  IHandleUpdateProjectSubscriptionParams,
  IUpdateProjectSubscriptionMutationGraphQLResponse,
  IUpdateProjectSubscriptionMutationVariables,
  IUseUpdateProjectSubscriptionOptions,
  IUseUpdateProjectSubscription,
} from './useUpdateProjectSubscription.interfaces';

import { useMuriquiMutation } from '../../apollo';

const useUpdateProjectSubscription = (options?: IUseUpdateProjectSubscriptionOptions): IUseUpdateProjectSubscription => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateProjectSubscriptionOptions);

  const [executeUpdateProjectSubscription, { loading: isLoading }] = useMuriquiMutation<
    IUpdateProjectSubscriptionMutationGraphQLResponse,
    IUpdateProjectSubscriptionMutationVariables
  >({
    mutation: mutation ?? UPDATE_PROJECT_SUBSCRIPTION_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          subscriptions: {
            updateSubscriptions: { result },
          },
        } = data;

        onCompleted({
          result,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateProjectSubscription = async ({ planIds, projectId, product }: IHandleUpdateProjectSubscriptionParams) => {
    await executeUpdateProjectSubscription({
      variables: {
        planIds,
        product,
        projectId,
      },
    });
  };

  return {
    handleUpdateProjectSubscription,
    isLoading,
  };
};

export { useUpdateProjectSubscription };
