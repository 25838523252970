import { gql } from '@apollo/client';

const SEND_CONTACT_FORM_MUTATION = gql`
  mutation sendContactForm(
    $email: Email!
    $firstName: String!
    $lastName: String!
    $organisationName: String!
    $phoneNumber: PhoneNumber!
    $value: String!
    $templateId: Int
  ) {
    user {
      sendContactForm(
        email: $email
        firstname: $firstName
        lastname: $lastName
        organisationName: $organisationName
        phoneNumber: $phoneNumber
        value: $value
        templateId: $templateId
      )
    }
  }
`;

export { SEND_CONTACT_FORM_MUTATION };
