import { gql } from '@apollo/client';

const EXPORT_PDF_REPORT_QUERY = gql`
  query exportPdfReport($from: DateTime, $ianaTimeZone: String!, $projectId: String!, $to: DateTime) {
    report {
      exportPdfReport: exportPdf(from: $from, ianaTimeZone: $ianaTimeZone, projectId: $projectId, to: $to) {
        presignedUrl
      }
    }
  }
`;

export { EXPORT_PDF_REPORT_QUERY };
