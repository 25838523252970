import { DELETE_RESTRICTION_MUTATION } from './useDeleteRestriction.graphql';
import {
  IDeleteRestrictionMutationGraphQLResponse,
  IDeleteRestrictionMutationVariables,
  IHandleDeleteRestrictionParams,
  IUseDeleteRestrictionOptions,
  IUseDeleteRestriction,
} from './useDeleteRestriction.interfaces';

import { useKanziMutation } from '../../apollo';

const useDeleteRestriction = (options?: IUseDeleteRestrictionOptions): IUseDeleteRestriction => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteRestrictionOptions);

  const [executeDeleteRestriction, { loading: isLoading }] = useKanziMutation<
    IDeleteRestrictionMutationGraphQLResponse,
    IDeleteRestrictionMutationVariables
  >({
    mutation: mutation ?? DELETE_RESTRICTION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          restriction: { deleteRestriction: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteRestriction = async ({ id }: IHandleDeleteRestrictionParams) => {
    await executeDeleteRestriction({
      variables: {
        id,
      },
    });
  };

  return {
    handleDeleteRestriction,
    isLoading,
  };
};

export { useDeleteRestriction };
