import { useEffect } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import { PageLayout } from 'components/PageLayout';
import { PublicFormCard } from 'components/PublicFormCard';

const LoginPage = () => {
  const { isDomainReady } = useDomain();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError } = useToast();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    push(getLoginUrl()).catch((error) =>
      handleToastError({
        error,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <PageLayout title="Login">
      <section className="flex flex-col min-h-screen bg-gradient-wave bg-gradient-wave--whole pt-40">
        <div className="c-container">
          <PublicFormCard
            description="Login to continue"
            image={<img alt="Login into Kanzi" className="md:max-w-24" loading="lazy" src="/images/kanzi-logo-full-dark.svg" />}
            title="Login"
          />
        </div>
      </section>
    </PageLayout>
  );
};

export { LoginPage };
