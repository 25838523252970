import { IClientLogo } from './ClientsBanner.interfaces';

const CLIENT_LIST: IClientLogo[] = [
  {
    alt: 'Department of health logo',
    id: 1,
    src: '/images/department-of-health-logo.svg',
  },
  {
    alt: 'Univeristy of Newcastle logo',
    id: 2,
    src: '/images/university-of-newcastle-australia-logo.svg',
  },
  {
    alt: 'University of Sydney logo',
    id: 3,
    src: '/images/university-of-sydney-logo.svg',
  },
  {
    alt: 'Univeristy of New South Wales logo',
    id: 4,
    src: '/images/unsw-logo.svg',
  },
];

export { CLIENT_LIST };
