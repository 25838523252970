import { gql } from '@apollo/client';

const UPDATE_API_KEY_VOICES_MUTATION = gql`
  mutation updateApiKeyVoices($apiKeyId: Int!, $voiceIds: [Int] = null) {
    apiKeyConfigurationVoice {
      updateApiKeyVoices: update(apiKeyId: $apiKeyId, voices: $voiceIds)
    }
  }
`;

export { UPDATE_API_KEY_VOICES_MUTATION };
