import { InformationBox } from '@netfront/ui-library';

import { FeaturesPageProps } from './FeaturesPage.interfaces';

import { PublicPageLayout } from '../../PublicPageLayout';

export const FeaturesPage = ({ featuresDirectory }: FeaturesPageProps) => {
  return (
    <PublicPageLayout
      afterTitle="A rich feature set to broaden and improve communication to your audience"
      hasContainer={false}
      meta={{
        description: 'Kanzi provides loads of customisation and features. Use the feature list to see what is included',
        metaTitle: "Kanzi is a Feature-rich tool. See what's included",
      }}
      title="Features"
    >
      <div className="c-container py-8">
        <InformationBox
          additionalClassNames="mb-12"
          message="Kanzi features are growing, If there is anything you would like to see included in the Kanzi feature set get in touch with us and let us know."
        />
      </div>

      <div className="bg-burgandy-100">
        <div className="c-container py-12">
          {featuresDirectory.map(({ description, id, title }) => (
            <div key={id} className="mb-6 bg-white p-8 rounded">
              <h3>{title}</h3>
              <p className="mb-0">{description}</p>
            </div>
          ))}
        </div>
      </div>
    </PublicPageLayout>
  );
};
