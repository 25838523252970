import { useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { Button, InformationBox } from '@netfront/ui-library';

import {
  ClientsBanner,
  FeaturesTable,
  PageTitleBanner,
  PDF_FEATURES,
  ProPlanCard,
  SUPPORT_FEATURES,
  TEXT_TO_SPEECH_FEATURES,
  TRANSLATE_FEATURES,
} from '../../../components';
import { IGetPlansOnCompletedResponse, useGetPlans, useToast } from '../../../hooks';
import { CurrencyType, IPlan } from '../../../interfaces';
import { PageLayout } from '../../PageLayout';

const PricingPage = () => {
  const { handleToastError } = useToast();

  const { isDomainReady } = useDomain();
  const { getRegistrationUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [plans, setPlans] = useState<IPlan[]>();
  const [registrationUrl, setRegistrationUrl] = useState<string>();

  const { handleGetPlans, isLoading: isGetPlansLoading = false } = useGetPlans({
    onCompleted: ({ plans: returnedPlans }: IGetPlansOnCompletedResponse) => {
      setPlans([...returnedPlans].sort((a, b) => Number(a.sort) - Number(b.sort)));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUpdateCurrency = (currency: CurrencyType) => {
    void handleGetPlans({ currency, product: 'KANZI' });
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setRegistrationUrl(getRegistrationUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    void handleGetPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      isPreloaderVisible={isGetPlansLoading}
      meta={{
        description: 'Kanzi provides a simple calculator to understand pricing if not using the free tier',
      }}
      title="Translate and speech pricing made simple"
    >
      <PageTitleBanner
        afterTitle="Trusted by businesses of all sizes to provide an easy way to speak to the world."
        containerClassName="c-container--xs"
        title="Translate and speech pricing made simple"
      />

      <div className="c-container c-container--small text-center pt-8 pb-8">
        <InformationBox
          additionalClassNames="mb-12"
          message="Kanzi offers a free tier to everyone who signs up. If you have a larger audience and think you may exceed free tier limits, use the calculator below to estimate costs. You can always cap your usage, so you don’t exceed your budget."
        />
      </div>

      {plans && (
        <>
          <div className="max-w-xs mx-auto py-16 pt-8">
            {
              <>
                <ProPlanCard plans={plans} hasCurrencySelection onUpdateCurrency={handleUpdateCurrency} />
              </>
            }
          </div>

          <div className="py-16 bg-burgandy-100">
            <div className="c-container">
              <h3 className="h2 text-uppercase text-center mb-12 lg:mb-24">Features</h3>

              <div className="flex flex-col lg:flex-row">
                <div className="text-center mb-0 lg:mr-24 lg:mt-12 mb-8 md:mb-4 md:w-1/5">
                  <img alt="Speech icon" className="mb-6" src="/images/text-to-speech-coloured-icon.svg" />
                  <h4 className="text-4xl text-uppercase">Text-to-speech</h4>
                </div>
                <div className="flex-1">
                  <FeaturesTable data={TEXT_TO_SPEECH_FEATURES} />
                </div>
              </div>
            </div>
          </div>

          <div className="py-16">
            <div className="c-container">
              <div className="flex flex-col lg:flex-row">
                <div className="text-center mb-0 lg:mr-24 lg:mt-12 mb-8 md:mb-4 md:w-1/5">
                  <img alt="Translate icon" className="mb-6" src="/images/translate-coloured-icon.svg" />
                  <h4 className="text-4xl text-uppercase">Translate</h4>
                </div>
                <div className="flex-1">
                  <FeaturesTable data={TRANSLATE_FEATURES} />
                </div>
              </div>
            </div>
          </div>

          <div className="py-16 bg-burgandy-100">
            <div className="c-container">
              <div className="flex flex-col lg:flex-row">
                <div className="text-center mb-0 lg:mr-24 lg:mt-12 mb-8 md:mb-4 md:w-1/5">
                  <img alt="PDF icon" className="mb-6" src="/images/pdf-coloured-icon.svg" />
                  <h4 className="text-4xl text-uppercase">Pdf</h4>
                </div>
                <div className="flex-1">
                  <FeaturesTable data={PDF_FEATURES} />
                </div>
              </div>
            </div>
          </div>

          <div className="py-16">
            <div className="c-container">
              <div className="flex flex-col lg:flex-row">
                <div className="text-center mb-0 lg:mr-24 lg:mt-12 mb-8 md:mb-4 md:w-1/5">
                  <img alt="PDF icon" className="mb-6" src="/images/support-coloured-icon.svg" />
                  <h4 className="text-4xl text-uppercase">Support</h4>
                </div>
                <div className="flex-1">
                  <FeaturesTable data={SUPPORT_FEATURES} />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center pb-20">
            {registrationUrl && (
              <Button linkButton={{ id: 'register', url: registrationUrl }} text="Sign up now for free" variant="primary" />
            )}
          </div>
        </>
      )}

      <ClientsBanner />
    </PageLayout>
  );
};

export { PricingPage };
