import { gql } from '@apollo/client';

const UPDATE_PROJECT_CONFIGURATION_MUTATION = gql`
  mutation updateProjectConfiguration(
    $colour: String
    $cornerRadius: Decimal
    $hasAccessibility:Boolean
    $hasPdf: Boolean
    $hasSpeech: Boolean
    $hasTranslate: Boolean
    $hasVolumeControl: Boolean
    $id: Int!
    $isCollapse: Boolean
    $isDraggable: Boolean
    $isSticky: Boolean
    $languages: [ECountry]
    $offset: Decimal
    $position: EPlayerPosition
    $size: ESize
    $theme: EToolTheme
  ) {
    projectConfiguration {
      updateProjectConfiguration: update(
        colour: $colour
        cornerRadius: $cornerRadius
        hasAccessibility:$hasAccessibility
        hasPdf: $hasPdf
        hasSpeech: $hasSpeech
        hasTranslate: $hasTranslate
        hasVolumeControl: $hasVolumeControl
        id: $id
        isCollapse: $isCollapse
        isDraggable: $isDraggable
        isSticky: $isSticky
        languages: $languages
        offset: $offset
        position: $position
        size: $size
        theme: $theme
      )
    }
  }
`;

export { UPDATE_PROJECT_CONFIGURATION_MUTATION };
