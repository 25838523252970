import { gql } from '@apollo/client';

import { HTML_CONFIGURATION_FRAGMENT } from '../../../graphql';

const CREATE_HTML_CONFIGURATION_MUTATION = gql`
  mutation createHtmlConfiguration(
    $apiKeyId: Int!
    $feature: EHtmlConfigurationFeature!
    $property: EHtmlConfigurationProperty!
    $type: EHtmlConfigurationType!
    $value: String!
  ) {
    htmlConfiguration {
      createHtmlConfiguration: add(apiKeyId: $apiKeyId, feature: $feature, property: $property, type: $type, value: $value) {
        ...htmlConfigurationFragment
      }
    }
  }

  ${HTML_CONFIGURATION_FRAGMENT}
`;

export { CREATE_HTML_CONFIGURATION_MUTATION };
