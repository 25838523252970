import { gql } from '@apollo/client';

const SEND_MAILS_MUTATION = gql`
  mutation sendMails($notifications: [NotificationInputType]!, $projectId: String!, $subject: String!, $templateId: Int!) {
    notification {
      sendMails(notificationGroup: { notifications: $notifications, projectId: $projectId, templateId: $templateId, subject: $subject }) {
        id
      }
    }
  }
`;

export { SEND_MAILS_MUTATION };
