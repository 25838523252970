import { gql } from '@apollo/client';

import { PROJECT_CONFIGURATION_FRAGMENT } from '../../../graphql';

const GET_PROJECT_CONFIGURATION_BY_PROJECT_QUERY = gql`
  query getProjectConfigurationByProject($projectId: String!) {
    projectConfiguration {
      getProjectConfigurationByProject: getByProject(projectId: $projectId) {
        ...projectConfigurationFragment
      }
    }
  }

  ${PROJECT_CONFIGURATION_FRAGMENT}
`;

export { GET_PROJECT_CONFIGURATION_BY_PROJECT_QUERY };
