import { gql } from '@apollo/client';

const RESTRICTION_FRAGMENT = gql`
  fragment restrictionFragment on RestrictionGraphType {
    id
    type
    value
  }
`;

export { RESTRICTION_FRAGMENT };
