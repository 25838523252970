import { useState, useEffect, ChangeEvent } from 'react';

import { ApolloError } from '@apollo/client';
import { DateRangePicker, Button, IOption, Select, Table, Toolbar, Spinner } from '@netfront/ui-library';
import { addMonths } from 'date-fns';
import { useRouter } from 'next/router';

import { PdfTableViewProps } from './PdfTableView.interfaces';

import { Pagination, SearchFilter } from '../../../../../components';
import { useExportPdfReport, useGetPdfUsage, useToast } from '../../../../../hooks';
import { REPORTING_PAGE_CONSTANTS } from '../../ReportingPage.constants';
import { IPdfUsageTableData, ITablePageNumberPageInfo } from '../../ReportingPage.interfaces';

const { numberOfPageSizeOptions, pageSize, tableColumnsPdf, tableSelectionItems } = REPORTING_PAGE_CONSTANTS;

const PdfTableView = ({ selectedTable, tableData, onPdfUserTableData, onTableChange }: PdfTableViewProps) => {
  const { query } = useRouter();
  const { handleToastError } = useToast();

  const { projectId } = query;

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [currentPageNumberPageInfos, setCurrentPageNumberPageInfos] = useState<ITablePageNumberPageInfo[]>([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState<boolean>(true);
  const [nextCursor, setNextCursor] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date[]>([addMonths(new Date(), -1), addMonths(new Date(), 1)]);
  const [tablePageSize, setTablePageSize] = useState<number>(pageSize);

  const [tablePageSizeOptions] = useState<IOption[]>(
    (Array.from({ length: numberOfPageSizeOptions }).fill(tablePageSize) as number[]).map((item, index) => {
      const value = item * (index + 1);

      const option: IOption = {
        id: index,
        name: value.toString(),
        value,
      };

      return option;
    }),
  );
  const [totalTablePages, setTotalTablePages] = useState<number>(0);

  const getNextCursor = (currentPage: number): string | undefined => {
    if (!currentPage) {
      return undefined;
    }

    if (currentPage === 1) {
      return undefined;
    }

    const tablePageNumberPageInfo = currentPageNumberPageInfos.find(
      (currentTablePageNumberPageInfo) => currentTablePageNumberPageInfo.pageNumber === currentPage - 1,
    );

    if (!tablePageNumberPageInfo) {
      return undefined;
    }

    const {
      pageInfo: { endCursor },
    } = tablePageNumberPageInfo;

    return endCursor;
  };

  const handleApolloError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setSearchValue(value);
  };

  const handleClearSearchFilter = () => {
    setSearchValue('');

    handleGetPdfUsage({
      after: nextCursor,
      first: tablePageSize,
      from: selectedDate[0],
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId: String(projectId),
      to: selectedDate[1],
    });
  };

  const handleDisplayTableClick = () => {
    setNextCursor(undefined);
    handleGeneratePdfReport();
  };

  const handleDownloadReport = (selectedDates: Date[]) => {

    handleExportPdfReport({
      from: selectedDates[0],
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId: String(projectId),
      to: selectedDates[1],
    });
  };

  const handleGeneratePdfReport = () => {
    handleGetPdfUsage({
      after: nextCursor,
      filter: searchValue,
      first: tablePageSize,
      from: selectedDate[0],
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      projectId: String(projectId),
      to: selectedDate[1],
    });
  };

  const handleNextButtonClick = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    setIsPreviousButtonDisabled(pageNumber === 1);
    setNextCursor(getNextCursor(pageNumber));
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setCurrentPageNumber(1);
    setIsPreviousButtonDisabled(true);
    setNextCursor(undefined);
    setTablePageSize(Number(value));
  };

  const handlePreviousButtonClick = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    setIsPreviousButtonDisabled(pageNumber === 1);
    setNextCursor(getNextCursor(pageNumber));
  };

  const { handleExportPdfReport, isLoading: isExportUsageReportLoading = false } = useExportPdfReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      window.open(String(presignedUrl), '_blank');
    },
    onError: (error) => {
      handleApolloError(error);
    },
  });

  const { handleGetPdfUsage, isLoading: isGetPdfUsageLoading = false } = useGetPdfUsage({
    fetchPolicy: 'no-cache',
    onCompleted: ({ edges, pageInfo, totalCount = 0 }) => {
      const { hasNextPage } = pageInfo;

      setCurrentPageNumberPageInfos((currentValues) => {
        const pageNumberPageInfo: ITablePageNumberPageInfo = {
          pageNumber: currentValues.length ? currentPageNumber : 1,
          pageInfo,
        };

        if (!currentValues.length) {
          return [pageNumberPageInfo];
        }

        const tablePageNumberPageInfo = currentValues.find(({ pageNumber }) => pageNumber === currentPageNumber);

        if (tablePageNumberPageInfo) {
          return currentValues.map((currentValue) => {
            const { pageNumber } = currentValue;
            return pageNumber === pageNumberPageInfo.pageNumber ? pageNumberPageInfo : currentValue;
          });
        }

        return [...currentValues, pageNumberPageInfo];
      });

      setIsNextButtonDisabled(!hasNextPage);

      onPdfUserTableData(edges);

      setTotalTablePages(Math.ceil(totalCount / tablePageSize));
    },
    onError: (error) => {
      handleApolloError(error);
    },
  });

  useEffect(() => {
    if (currentPageNumber === 1 && !tableData) {
      return;
    }

    handleGeneratePdfReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, tablePageSize]);

  const isLoading = isExportUsageReportLoading || isGetPdfUsageLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />

      <div className="px-4 w-full">
        <Toolbar
          additionalClassNames="c-reporting-page__table-toolbar"
          childrenEnd={
            <div className="flex gap-2">
              <Button size="xs" text="Display" type="button" onClick={handleDisplayTableClick} />
              <Button size="xs" text="Download" type="button" onClick={() => handleDownloadReport(selectedDate)} />
            </div>
          }
          childrenMiddle={
            <div className="c-reporting-page__table-toolbar__middle flex flex-1 gap-4">
              <DateRangePicker
                dateInputProps={{
                  labelText: 'Select date range',
                  id: 'id_date_selector',
                  name: 'date_range_selector',
                  type: 'text',
                  isLabelHidden: true,
                  hasPadding: false,
                }}
                selectedDates={selectedDate}
                onSelected={setSelectedDate}
              />
              <Select
                id="Select table"
                labelText=""
                name="Select table"
                options={tableSelectionItems}
                value={selectedTable}
                isLabelHidden
                onChange={(event: ChangeEvent<HTMLSelectElement>) => onTableChange(event)}
              />
            </div>
          }
          childrenStart={
            <SearchFilter
              additionalClassNames="c-search-filter"
              id="search"
              labelText="Pdf filter"
              name="search"
              placeholder="Search"
              type="text"
              value={searchValue}
              isLabelHidden
              onChange={handleChangeSearchValue}
              onClear={handleClearSearchFilter}
              onSearch={handleGeneratePdfReport}
            />
          }
        />

        {tableData ? (
          <>
            {!tableData.length && <p className="mt-8">There&apos;s no pdf usage data available yet</p>}

            {Boolean(tableData.length) && (
              <>
                <div className="mt-8">
                  <Table<IPdfUsageTableData> columns={tableColumnsPdf} data={tableData} />
                </div>

                <div className="c-reporting-page__pagination mt-8">
                  <Pagination
                    isNextButtonDisabled={isNextButtonDisabled}
                    isPreviousButtonDisabled={isPreviousButtonDisabled}
                    tablePageNumber={currentPageNumber}
                    tablePageSize={tablePageSize}
                    tablePageSizeOptions={tablePageSizeOptions}
                    totalTablePages={totalTablePages}
                    onNextButtonClick={() => handlePreviousButtonClick(currentPageNumber - 1)}
                    onPageSizeChange={handlePageSizeChange}
                    onPreviousButtonClick={() => handleNextButtonClick(currentPageNumber + 1)}
                  />
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export { PdfTableView };
