export type RegisterCustomFieldFeature = 'Translate' | 'Speech' | 'PDF';
export type RegisterCustomFieldTier = 'Free' | 'Paid';

export interface IAddUserToEmailSubscriptionParams {
  customFields?: Record<RegisterCustomFieldFeature, RegisterCustomFieldTier>;
  emailAddress: string;
  listId: string;
  name: string;
  onError?: (statusText: string) => void;
  onSuccess?: () => void;
}

export const addUserToEmailSubscription = ({
  emailAddress,
  listId,
  name,
  customFields,
  onError,
  onSuccess,
}: IAddUserToEmailSubscriptionParams) => {
  const Http = new XMLHttpRequest();
  const url = 'https://00y089pbdi.execute-api.ap-southeast-2.amazonaws.com/default/email-subscriber';

  Http.open('POST', url);

  Http.send(
    JSON.stringify({
      SendApiKey: process.env.REACT_APP_CREATE_SEND_API_KEY,
      SendListId: listId,
      emailAddress,
      name,
      customFields,
    }),
  );

  Http.onreadystatechange = () => {
    if (Http.readyState === 4) {
      if (Http.status === 200) {
        if (onSuccess) {
          onSuccess();
          return true;
        }
      } else {
        if (onError) {
          onError(Http.statusText);
          return false;
        }
      }
    }

    return false;
  };
};
