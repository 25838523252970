import { useEffect, useState } from 'react';

import { createAccessTokenCookie, createRefreshTokenCookie, useCookie } from '@netfront/common-library';
import {
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  IGeladaProject,
  IGetGeladaProjectOnCompletedResponse,
  IUpdateGeladaProjectOnCompletedResponse,
  useCreateGeladaOrganisation,
  useCreateGeladaProject,
  useDomain,
  useGetGeladaProject,
  useProtectedRoute,
  useRefreshToken,
  useUpdateGeladaProject,
} from '@netfront/gelada-identity-library';
import { InformationBox } from '@netfront/ui-library';
import { kebabCase } from 'lodash';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';

import { WelcomeStepProgress } from '../../../components';
import { useToast } from '../../../hooks';
import { ApiKeyType } from '../../../interfaces';
import { extractRootDomain } from '../../../utils';
import { CreateProjectForm, ICreateProjectFormFields } from '../../Forms';
import { PageLayout } from '../../PageLayout';
import { Tab, TabRound } from '../../Tabs';

const PluginInstalLocationPage = () => {
  const PAGE_TITLE = 'Where is Kanzi being installed?';

  const { isSecureCookie } = useCookie();
  const { getDomain } = useDomain();
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [existingProject, setExistingProject] = useState<IGeladaProject | null>();
  const [projectName, setProjectName] = useState<string>();
  const [projectType, setProjectType] = useState<ApiKeyType>('WEB');
  const [shouldRenderForm, setShouldRenderForm] = useState<boolean>(false);

  const handleIndividualWelcomeFormSubmit = async ({ project }: ICreateProjectFormFields) => {
    const validProjectName = projectType === 'WEB' ? extractRootDomain(project) : project;

    if (existingProject) {
      handleUpdateGeladaProject({
        description: String(existingProject.description),
        name: validProjectName,
        projectId: existingProject.id,
        url: String(existingProject.url),
      });

      push('/welcome/install-instructions').catch((error) => {
        handleToastError({
          error,
        });
      });

      return;
    }

    setProjectName(validProjectName);

    const guid = uuidv4();

    await handleCreateGeladaOrganisationBusiness({
      name: guid,
      description: '',
      key: guid,
    });
  };

  const {
    handleCreateGeladaOrganisation: handleCreateGeladaOrganisationBusiness,
    isLoading: isCreateGeladaOrganisationBusinessLoading = false,
  } = useCreateGeladaOrganisation({
    onCompleted: ({ organisation: { id } }) => {
      void handleCreateGeladaProject({
        isCustomBuild: false,
        name: String(projectName),
        organisationId: id,
        key: kebabCase(String(projectName)),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCreateGeladaProject, isLoading: isCreateProjectLoading = false } = useCreateGeladaProject({
    onCompleted: ({ project: { id } }) => {
      localStorage.setItem('projectId', id);

      void handleRefreshToken();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleRefreshToken, isLoading: isRefreshTokenLoading = false } = useRefreshToken({
    onCompleted: ({ accessToken, refreshToken }) => {
      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      if (accessToken) {
        try {
          createAccessTokenCookie({
            optionalCookieAttributesInput: {
              domain,
              secure: isSecure,
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
            },
            value: accessToken,
          });
        } catch (error) {
          handleToastError({
            error: error as Error,
            shouldUseFriendlyErrorMessage: true,
          });

          return;
        }
      }

      if (refreshToken) {
        createRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
          },
          value: refreshToken,
        });
      }

      localStorage.setItem('integrationType', projectType);

      push('/welcome/install-instructions').catch((error) => {
        handleToastError({
          error,
        });
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleGetGeladaProjectCompleted = (data?: IGetGeladaProjectOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { geladaProject } = data;
    setExistingProject(geladaProject);
    setShouldRenderForm(true);
  };

  const { handleGetGeladaProject } = useGetGeladaProject({
    onCompleted: handleGetGeladaProjectCompleted,
    onError: () => setShouldRenderForm(true),
  });

  const handleUpdateGeladaProjectCompleted = (data?: IUpdateGeladaProjectOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { project } = data;
    setExistingProject(project);
  };

  const { handleUpdateGeladaProject } = useUpdateGeladaProject({
    onCompleted: handleUpdateGeladaProjectCompleted,
  });

  useEffect(() => {
    const previousProjectIdStored = localStorage.getItem('projectId');

    if (previousProjectIdStored) {
      handleGetGeladaProject({
        projectId: previousProjectIdStored,
      });

      return;
    }

    setShouldRenderForm(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isCreateGeladaOrganisationBusinessLoading || isCreateProjectLoading || isRefreshTokenLoading;

  return (
    <PageLayout
      additionalClassNames="c-page-layout-template--narrow"
      isPreloaderVisible={isLoading}
      meta={{
        description: 'Let us know where you want to install the plugin',
        metaTitle: PAGE_TITLE,
      }}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {isAuthenticated && (
        <>
          <div className="flex flex-col items-center mb-8">
            <img alt="Kanzi logo" className="w-full max-w-16 mb-6" src="/images/kanzi-logo-icon.svg" />
            <h1 className="h2 mb-2 text-center">{PAGE_TITLE}</h1>
          </div>

          <WelcomeStepProgress currentStep={1} />

          <InformationBox
            iconId="id_info_icon"
            message="To use Kanzi to translate, convert text to speech and read your content aloud or create multilingual PDFs the tool needs to be installed on a website or hybrid mobile app. Where will your first installation be? If you have more than one installation, you can create more later."
          />

          <div className="py-16 text-center">
            {shouldRenderForm && (
              <Tab.Group
                defaultIndex={existingProject?.hasMobileApplication ? 1 : 0}
                onChange={(index) => setProjectType(index === 0 ? 'WEB' : 'MOBILE')}
              >
                <Tab.List className="mb-12 bg-grey-200 rounded-full inline-flex">
                  <TabRound>Website</TabRound>
                  <TabRound>App</TabRound>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel>
                    <>
                      <strong>Website URL where Kanzi is being installed</strong>
                      <p>Restrict Kanzi usage requests to the specified website URL added below</p>
                      <CreateProjectForm
                        defaultValue={existingProject?.name ?? ''}
                        isSubmitting={isLoading}
                        label="Website restriction"
                        placeholder="e.g. yourwebsite.com"
                        onSubmit={async ({ project }) => {
                          setProjectType('WEB');
                          await handleIndividualWelcomeFormSubmit({ project });
                        }}
                      />
                    </>
                  </Tab.Panel>
                  <Tab.Panel>
                    <>
                      <strong>App package name where Kanzi is being installed</strong>
                      <p>Restrict Kanzi usage requests to the specified app package added below</p>
                      <CreateProjectForm
                        defaultValue={existingProject?.name ?? ''}
                        isSubmitting={isLoading}
                        label="App package name"
                        placeholder="e.g. com.yourappbundle"
                        isApp
                        onSubmit={async ({ project }) => {
                          setProjectType('MOBILE');
                          await handleIndividualWelcomeFormSubmit({ project });
                        }}
                      />
                    </>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            )}
          </div>
        </>
      )}
    </PageLayout>
  );
};

export { PluginInstalLocationPage };
