import { gql } from '@apollo/client';

const GET_AVAILABLE_VOICES_QUERY = gql`
  query getAvailableVoices {
    availableVoice {
      getAvailableVoices: getAll {
        dialect
        gender
        id
        isChild
        language
        name
      }
    }

    countryEnums: __type(name: "ECountry") {
      enumValues {
        key: name
        value: description
      }
    }
  }
`;

export { GET_AVAILABLE_VOICES_QUERY };
