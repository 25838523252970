export type FeatureItemList = Array<ITextFeatureItem | ICheckboxFeatureItem>;

export enum FeatureItemType {
  checked = 'checked',
  text = 'text',
}

export interface FeaturesTableProps {
  data: FeatureItemList;
}

export interface ICheckboxFeatureItem extends IFeatureItem {
  free: boolean;
  plan: boolean;
  type: FeatureItemType.checked;
}

export interface IFeatureItem {
  feature: string;
  id: number;
  tooltip: string;
}

export interface ITextFeatureItem extends IFeatureItem {
  free: string;
  plan: string;
  type: FeatureItemType.text;
}
