import { gql } from '@apollo/client';

const APPROVE_RESTRICTION_MUTATION = gql`
  mutation ($id: Int!, $validate: Boolean!) {
    restriction {
      approveOrDeclineUnapproved(id: $id, validate: $validate)
    }
  }
`;

export { APPROVE_RESTRICTION_MUTATION };
