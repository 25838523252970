import { gql } from '@apollo/client';

import { API_KEY_FRAGMENT } from '../../../graphql';

const UPDATE_API_KEY_MUTATION = gql`
  mutation updateApiKey(
    $googleMeasurementKey: String
    $id: Int!
    $maintainTranslationOnNavigation: Boolean
    $isAccessibilityMaintainedOnNavigation: Boolean
    $packageName: String
    $status: EApiKeyStatus
    $title: String
    $type: EApiKeyType
  ) {
    apiKey {
      updateApiKey: update(
        googleMeasurementKey: $googleMeasurementKey
        id: $id
        maintainTranslationOnNavigation: $maintainTranslationOnNavigation
        isAccessibilityMaintainedOnNavigation: $isAccessibilityMaintainedOnNavigation
        packageName: $packageName
        status: $status
        title: $title
        type: $type
      ) {
        ...apiKeyFragment
      }
    }
  }

  ${API_KEY_FRAGMENT}
`;

export { UPDATE_API_KEY_MUTATION };
