import { gql } from '@apollo/client';

const ACTIVATE_PROJECT_MUTATION = gql`
  mutation activateProject($id: String!, $name: String!) {
    project {
      activateProject: activate(id: $id, name: $name)
    }
  }
`;

export { ACTIVATE_PROJECT_MUTATION };
