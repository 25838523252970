import { ReactNode, useContext, useEffect, useState } from 'react';

import { useMediaQuery } from '@netfront/common-library';
import { SidebarNavigation, NavigationItemProps, PageContainer } from '@netfront/ui-library';
import { PageLayout } from 'components';
import { DashboardContext } from 'contexts';
import Link from 'next/link';

import { PageTemplateProps } from './PageTemplate.interfaces';



const PageTemplate = ({
  activePage,
  activeSubPage,
  isLoading,
  pageTitle,
  spotlightComponent,
  children,
  metaData,
  geladaProject,
}: PageTemplateProps) => {

  const isMobile = useMediaQuery({ size: 'small' });
  const { dashboardItems, dashboardLink } = useContext(DashboardContext);

  const [isDefaultOpen, setIsDefaultOpen] = useState(true);

  useEffect(() => {
    setIsDefaultOpen(!isMobile);
  }, [isMobile]);

  return (
    <PageLayout
      additionalClassNames="c-table-page-template"
      geladaProject={geladaProject}
      isPreloaderVisible={isLoading}
      meta={metaData}
      title={pageTitle}
      hasPrivateLayout
    >
      {spotlightComponent}

      <SidebarNavigation
        activePage={activePage}
        activeSubPage={activeSubPage}
        dashboardLink={dashboardLink}
        dashboardLinkWrapper={({ children: wrapperChildren }: { children: ReactNode }) => <Link href={String(dashboardLink)}>{wrapperChildren}</Link>}
        isDefaultOpen={isDefaultOpen}
        navigationItems={dashboardItems.map((item) => {
          const { iconId: itemIcon, title: itemTitle, id, href, subItems, isInternal = false } = item;
          return {
            iconId: itemIcon,
            label: itemTitle,
            id,
            href: !isInternal ? href : undefined,
            wrapperComponent: isInternal ? ({ children: wrapperChildren }: { children: ReactNode }) => <Link href={href}>{wrapperChildren}</Link>: undefined,
            subItems: subItems ? subItems.map((subItem) => {
              const { iconId: subIconId, title: subTitle, id: subId, href: subHref, isInternal: isSubInternal = false } = subItem;
              return {
                iconId: subIconId,
                label: subTitle,
                id: subId,
                href: !isSubInternal ? subHref: undefined,
                wrapperComponent: isSubInternal ? ({ children: wrapperChildren }: { children: ReactNode }) => <Link href={subHref}>{wrapperChildren}</Link>: undefined,
              };
            }): undefined
          } as NavigationItemProps;
        })}
      >
        <PageContainer>
          {children}
        </PageContainer>
      </SidebarNavigation>
    </PageLayout>
  );
};

export { PageTemplate };
