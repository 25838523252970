import { useEffect, useState } from 'react';

import { useHasSeenSpotlight } from '@netfront/gelada-identity-library';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';

import { spotLightConstants } from './Spotlight.constants';
import { ISpotlight, SpotlightProps } from './Spotlight.interfaces';

const Spotlight = ({ disableOverlayClose, disableCloseOnEsc, hideCloseButton, spotlightItem, onSpotLightData }: SpotlightProps) => {
  const [spotlightTour, setSpotlightTour] = useState<ISpotlight>(spotlightItem);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const { spotLightDisplayTimeoutInMilliseconds } = spotLightConstants;

  const { handleHasSeenSpotlight } = useHasSeenSpotlight({
    onCompleted: ({ hasSeenSpotlight }) => {
      if (hasSeenSpotlight) {
        return;
      }

      setTimeout(() => {
        handleSpotlightStart();
      }, spotLightDisplayTimeoutInMilliseconds);
    },
  });

  const handleSpotlightCallback = (data: CallBackProps) => {
    const { status } = data;

    onSpotLightData(data);

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setSpotlightTour({
        ...spotlightTour,
        run: true,
      });
    }
  };

  const handleSpotlightStart = () => {
    setSpotlightTour({
      ...spotlightTour,
      run: true,
    });
  };

  useEffect(() => {
    handleHasSeenSpotlight({ url: window.location.pathname });
    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMounted ? (
    <div>
      <Joyride
        callback={handleSpotlightCallback}
        continuous={true}
        disableCloseOnEsc={disableCloseOnEsc}
        disableOverlayClose={disableOverlayClose}
        hideCloseButton={hideCloseButton}
        run={spotlightTour.run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={spotlightTour.steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
    </div>
  ) : null;
};

export { Spotlight };
