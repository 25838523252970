import { UPDATE_API_KEY_VOICES_MUTATION } from './useUpdateApiKeyVoices.graphql';
import {
  IUpdateApiKeyVoicesMutationGraphQLResponse,
  IUpdateApiKeyVoicesMutationVariables,
  IHandleUpdateApiKeyVoicesParams,
  IUseUpdateApiKeyVoicesOptions,
  IUseUpdateApiKeyVoices,
} from './useUpdateApiKeyVoices.interfaces';

import { useKanziMutation } from '../../apollo';

const useUpdateApiKeyVoices = (options?: IUseUpdateApiKeyVoicesOptions): IUseUpdateApiKeyVoices => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateApiKeyVoicesOptions);

  const [executeUpdateApiKeyVoices, { loading: isLoading }] = useKanziMutation<
    IUpdateApiKeyVoicesMutationGraphQLResponse,
    IUpdateApiKeyVoicesMutationVariables
  >({
    mutation: mutation ?? UPDATE_API_KEY_VOICES_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          apiKeyConfigurationVoice: { updateApiKeyVoices: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateApiKeyVoices = async ({ apiKeyId, voiceIds = null }: IHandleUpdateApiKeyVoicesParams) => {
    await executeUpdateApiKeyVoices({
      variables: {
        apiKeyId,
        voiceIds,
      },
    });
  };

  return {
    handleUpdateApiKeyVoices,
    isLoading,
  };
};

export { useUpdateApiKeyVoices };
