export * from './IconBin';
export * from './IconCross';
export * from './IconDesktop';
export * from './IconExpand';
export * from './IconMenu';
export * from './IconMenuLeft';
export * from './IconMobile';
export * from './IconPdf';
export * from './IconSpeech';
export * from './IconTick';
export * from './IconTranslate';
export * from './IconVolume';
