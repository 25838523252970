import { IOption } from '@netfront/ui-library';

export const DEFAULT_PDF_PLAN_ID = 11;
export const DEFAULT_SPEECH_PLAN_ID = 1;
export const DEFAULT_TRANSLATE_PLAN_ID = 6;

export const PDF_PRODUCT_ID = 4;
export const SPEECH_PRODUCT_ID = 3;
export const TRANSLATE_PRODUCT_ID = 2;

export const UNLIMITED_PDF_PLAN_ID = 18;
export const UNLIMITED_SPEECH_PLAN_ID = 6;
export const UNLIMITED_TRANSLATE_PLAN_ID = 12;

export const CURRENCY_OPTIONS: IOption[] = [
  {
    id: 1,
    name: 'AUD',
    value: 'AUD',
  },
  {
    id: 2,
    name: 'USD',
    value: 'USD',
  },
  {
    id: 3,
    name: 'EUR',
    value: 'EUR',
  },
  {
    id: 4,
    name: 'YEN',
    value: 'YEN',
  },
  {
    id: 5,
    name: 'YUAN',
    value: 'YUAN',
  },
  {
    id: 6,
    name: 'GBP',
    value: 'GBP',
  },
];
