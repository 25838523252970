export const emailMarkupToASCII = (markup: string) => {
  return markup
    .replaceAll('<script', '&lt;script')
    .replaceAll('</script>', '&lt;/script&gt;')
    .replaceAll('<html', '&lt;html')
    .replaceAll('</html>', '&lt;/html&gt;')
    .replaceAll('<body', '&lt;body')
    .replaceAll('</body>', '&lt;/body&gt;')
    .replaceAll('\n', '<br />')
    .replaceAll('\r', '<br />');
};

export const handleGetScriptMessage = (fullName?: string, guid?: string, projectName?: string, isApp?: boolean) => {
  if (!(fullName && guid)) {
    return;
  }

  return `Hi,

I'm in the process of installing Kanzi embeddable translate, speech and PDF conversion tool for our ${isApp ? 'app' : 'website'} ${
  projectName ? projectName : ''
}, and I need your help with the installation. Could you please add the following code before the </body> tag on all pages of our ${
  isApp ? 'app' : 'website'
} ${projectName ? projectName : ''}.

<script id="kanzi" data-defaultlanguage="en" data-apikey="${guid}" src="https://plugin.kanzi.io/lib/kanzi.js" async></script>

If we are using a website builder (such as Shopify or others), you can view help docs on the Kanzi site <a href="https://kanzi.io/docs/installation">help docs</a> with installation instructions.

Get in touch with the Kanzi team by sending an email to supprt@kanzi.io or through live chat on the Kanzi website by selecting the chat icon in the bottom right of the website.

Thanks so much!
${fullName}`;
};
