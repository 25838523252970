import { BUTTON_CLASSES, LinkButton } from '../LinkButton';

import { CARD_PALETTE, PRICE_PLANS } from './PricePlanCards.constants';

const PricePlanCards = () => {
  return (
    <ul className="flex flex-wrap -mx-4 pl-0">
      {PRICE_PLANS.map(({ characters, link, paymentCycle, price, title }, key) => (
        <li key={key} className="w-1/2 md:w-1/4 p-4 list-none">
          <div className="relative rounded bg-grey-200 h-full">
            <h4 className="bg-yellow-400 rounded-full py-2 px-4 absolute left-1/2 -translate-1/2 no-wrap">{title}</h4>
            <div className={`p-4 pt-6 rounded-t color-white ${CARD_PALETTE[key]}`}>
              <p className="h2 font-header weight-600 text-uppercase mb-0">{price}</p>
              <span className="font-header">{paymentCycle ?? <br />}</span>
            </div>
            <div className="p-4 text-center">
              <p className="text-4xl weight-700 font-header mb-0">{characters}</p>
              <span className="text-uppercase font-header">Characters</span>
            </div>
            <div className="p-4 rounded-b">
              <LinkButton additionalClassNames={`${BUTTON_CLASSES.green} w-full`} text="Get started" url={link ?? '/register'} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export { PricePlanCards };
