import { CREATE_HTML_CONFIGURATION_MUTATION } from './useCreateHtmlConfiguration.graphql';
import {
  ICreateHtmlConfigurationMutationGraphQLResponse,
  ICreateHtmlConfigurationMutationVariables,
  IHandleCreateHtmlConfigurationParams,
  IUseCreateHtmlConfigurationOptions,
  IUseCreateHtmlConfiguration,
} from './useCreateHtmlConfiguration.interfaces';

import { useKanziMutation } from '../../apollo';

const useCreateHtmlConfiguration = (options?: IUseCreateHtmlConfigurationOptions): IUseCreateHtmlConfiguration => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateHtmlConfigurationOptions);

  const [executeCreateHtmlConfiguration, { loading: isLoading }] = useKanziMutation<
    ICreateHtmlConfigurationMutationGraphQLResponse,
    ICreateHtmlConfigurationMutationVariables
  >({
    mutation: mutation ?? CREATE_HTML_CONFIGURATION_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          htmlConfiguration: { createHtmlConfiguration: htmlConfiguration },
        } = data;

        onCompleted({
          htmlConfiguration,
        });
      },
      onError,
    },
    token,
  });

  const handleCreateHtmlConfiguration = async ({ apiKeyId, feature, property, type, value }: IHandleCreateHtmlConfigurationParams) => {
    await executeCreateHtmlConfiguration({
      variables: {
        apiKeyId,
        feature,
        property,
        type,
        value,
      },
    });
  };

  return {
    handleCreateHtmlConfiguration,
    isLoading,
  };
};

export { useCreateHtmlConfiguration };
