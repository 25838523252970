import { useEffect } from 'react';

import { useProtectedRoute } from '@netfront/gelada-identity-library';
import { InformationBox, LinkButton } from '@netfront/ui-library';

import { BUTTON_CLASSES, WelcomeStepProgress } from '../../../components';
import { PageLayout } from '../../PageLayout';

const WelcomeNamingPage = () => {
  const PAGE_TITLE = 'Thanks for registering with Kanzi';

  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
  }, [isAuthenticated]);

  return (
    <PageLayout
      additionalClassNames="c-page-layout-template--narrow"
      meta={{
        description: 'Configure your first project to get started using Kanzi',
        metaTitle: PAGE_TITLE,
      }}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {isAuthenticated && (
        <>
          <div className="flex flex-col items-center mb-8">
            <img alt="Kanzi logo" className="w-full max-w-16 mb-6" src="/images/kanzi-logo-icon.svg" />
            <h1 className="h2 mb-2">{PAGE_TITLE}</h1>
          </div>

          <WelcomeStepProgress currentStep={0} />

          <InformationBox
            iconId="id_info_icon"
            message="Welcome! You're only a few steps away from gaining access to Kanzi instant translation, speech and PDF services for your website or app. To finalise account activation, check your registered email and click the activation link. This will enable your account for future access."
          />

          <div className="py-16 text-center">
            <LinkButton additionalClassNames={`${BUTTON_CLASSES.green}`} text="Let's get started" url="/welcome/plugin-install-location" />
          </div>
        </>
      )}
    </PageLayout>
  );
};

export { WelcomeNamingPage };
