import cx from 'classnames';

import { FeaturesTableCheckProps } from './FeaturesTableCheck.interfaces';

import { IconTick } from '../../Icons';

const FeaturesTableCheck = ({ isChecked }: FeaturesTableCheckProps) => {
  return (
    <div
      className={cx('flex items-center justify-center w-5 h-5 rounded-circle mx-auto p-0.5', {
        'bg-primary color-white': isChecked,
        'bg-grey-300': !isChecked,
      })}
    >
      <span className="sr-only">{isChecked ? 'Feature is included' : 'Feature is not included'}</span>
      {isChecked && <IconTick />}
    </div>
  );
};

export { FeaturesTableCheck };
