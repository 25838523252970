import { useEffect, useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { CountriesType, useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { Button, Input, LinkButton } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import { BUSINESS_INFORMATION_FORM_INITIAL_VALUES } from './BusinessInformationForm.constants';
import { IBusinessInformationFormFields, BusinessInformationFormProps } from './BusinessInformationForm.interfaces';
import styles from './BusinessInformationForm.module.css';

import { BUTTON_CLASSES } from '../../../components';

const BusinessInformationForm = ({ isSubmitting, onSubmit }: BusinessInformationFormProps) => {
  const { control, handleSubmit } = useForm<IBusinessInformationFormFields>({
    defaultValues: BUSINESS_INFORMATION_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { isDomainReady } = useDomain();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [identityUrl, setIdentityUrl] = useState<string>('');

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const getAddressDetails = (address: string) => {
    return geocodeByAddress(address)
      .then((results) => {
        const resultsArray = results[0].address_components;
        const city = String(resultsArray.find(({ types }) => types.includes('locality'))?.long_name);
        const country = resultsArray.find(({ types }) => types.includes('country'))?.short_name as CountriesType;
        const streetNumber = String(resultsArray.find(({ types }) => types.includes('street_number'))?.long_name);
        const streetName = String(resultsArray.find(({ types }) => types.includes('route'))?.long_name);
        const postcode = String(resultsArray.find(({ types }) => types.includes('postal_code'))?.long_name);
        const state = String(resultsArray.find(({ types }) => types.includes('administrative_area_level_1'))?.short_name);

        return {
          country,
          city,
          streetNumber,
          streetName,
          postcode,
          state,
        };
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(getBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <form
      className={styles['c-business-information-form']}
      onSubmit={handleSubmit(async ({ businessAddress, businessName, phoneNumber, mobilePhoneNumber }: IBusinessInformationFormFields) => {
        const geoCode = await getAddressDetails(businessAddress);

        if (!geoCode) {
          return;
        }

        onSubmit({
          businessAddress,
          businessName,
          geoCode: {
            ...geoCode,
          },
          phoneNumber,
          mobilePhoneNumber,
        });
      })}
    >
      <div className={styles['c-business-information-form__body']}>
        <div className={styles['c-business-information-form__field']}>
          <Controller
            control={control}
            name="businessName"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="businessName"
                isDisabled={isSubmitting}
                labelText="Business name"
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </div>
        <div className={styles['c-business-information-form__field']}>
          <Controller
            control={control}
            name="businessAddress"
            render={({ field, fieldState }) => (
              <PlacesAutocomplete {...field}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <Input
                      errorMessage={getFormFieldErrorMessage(fieldState)}
                      id="businessAddress"
                      labelText="Business address"
                      name="businessAddress"
                      {...getInputProps({
                        autoComplete: 'new-password',
                        className: 'c-input',
                        onBlur: field.onBlur,
                      })}
                      isRequired
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            )}
          />
        </div>

        <div className={styles['c-business-information-form__field']}>
          <div className="flex items-center flex-wrap -mx-2">
            <div className="w-1/2 px-2">
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <Input
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="phoneNumber"
                    isDisabled={isSubmitting}
                    labelText="Phone number"
                    type="tel"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="w-1/2 px-2">
              <Controller
                control={control}
                name="mobilePhoneNumber"
                render={({ field, fieldState }) => (
                  <Input
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="mobilePhoneNumber"
                    isDisabled={isSubmitting}
                    labelText="Mobile number"
                    type="tel"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <LinkButton
          additionalClassNames={`${BUTTON_CLASSES['subtle-link']} mb-8 text-center color-primary`}
          text="Skip"
          url={`${identityUrl}/dashboard?shouldRedirect=true`}
        />

        <div>
          <LinkButton additionalClassNames={`${BUTTON_CLASSES.white} mr-4`} text="Back" url="/welcome/install-instructions" />
          <Button isDisabled={isSubmitting} text="Confirm" type="submit" variant="primary" />
        </div>
      </div>
    </form>
  );
};

export { BusinessInformationForm };
