import { EFooterMenuCategory } from './PublicFooter.interfaces';

import { IMenuItem } from '../../interfaces';

const PUBLIC_FOOTER_MENU_ITEMS: IMenuItem[] = [
  { href: '/about', label: 'About', category: EFooterMenuCategory.KANZI },
  { href: '/partners', label: 'Partners', category: EFooterMenuCategory.KANZI },
  { href: '/blog', label: 'Blog', category: EFooterMenuCategory.KANZI },
  { href: '/contact', label: 'Contact', category: EFooterMenuCategory.KANZI },
  { href: '/features', label: 'Features', category: EFooterMenuCategory.PRODUCT },
  { href: '/pricing', label: 'Pricing', category: EFooterMenuCategory.PRODUCT },
  { href: '/docs', label: 'Docs', category: EFooterMenuCategory.PRODUCT },
  { href: '/faq', label: 'FAQ', category: EFooterMenuCategory.PRODUCT },
  { href: '/terms-and-conditions', label: 'Terms and conditions', category: EFooterMenuCategory.LEGAL },
  { href: '/privacy-policy', label: 'Privacy policy', category: EFooterMenuCategory.LEGAL },
];

export { PUBLIC_FOOTER_MENU_ITEMS };
