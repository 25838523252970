import { GET_PRODUCTS_QUERY } from './useGetProducts.graphql';
import { IGetProductsQueryGraphQLResponse, IUseGetProducts, IUseGetProductsOptions } from './useGetProducts.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useGetProducts = (options?: IUseGetProductsOptions): IUseGetProducts => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetProductsOptions);

  const [executeGetProducts, { loading: isLoading }] = useKanziLazyQuery<IGetProductsQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          product: { getProducts: products },
        } = data;

        onCompleted({
          products,
        });
      },
      onError,
    },
    query: query ?? GET_PRODUCTS_QUERY,
    token,
  });

  const handleGetProducts = () => {
    executeGetProducts();
  };

  return {
    handleGetProducts,
    isLoading,
  };
};

export { useGetProducts };
