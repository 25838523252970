import { gql } from '@apollo/client';

const GET_PARTNER_LOGO_BRAND_QUERY = gql`
  query GetPartnerLogoBrand($id: String!) {
    partner {
      getForProject(id: $id) {
        brandAccentPrimary
        logo {
          assetId
          fileName
          s3Key
          presignedUrl
        }
      }
    }
  }
`;

export { GET_PARTNER_LOGO_BRAND_QUERY };
