import cx from 'classnames';

import { IconTick } from '../Icons';

import { STEP_DATA } from './WelcomeStepProgress.constants';
import { WelcomeStepProgressProps } from './WelcomeStepProgress.interfaces';

const WelcomeStepProgress = ({ currentStep }: WelcomeStepProgressProps) => {
  const StepLine = ({ className }: { className: string }) => (
    <div
      className={cx('absolute h-1 top-1/2 -translate-y-1/2 w-1/2', {
        [className]: className,
      })}
      role="presentation"
    />
  );

  return (
    <div className="c-welcome-step-progress">
      {STEP_DATA.map(({ label, step }) => {
        return (
          <div key={step} className="flex-1 text-center">
            <div className="relative">
              {step !== 1 && (
                <StepLine
                  className={cx('left-0', {
                    'bg-primary': currentStep >= step || step === currentStep + 1,
                    'bg-grey-200': step > currentStep && step !== currentStep + 1,
                  })}
                />
              )}
              <div
                className={cx('inline-flex relative z-2 w-8 h-8 color-white border-2 rounded-circle p-1', {
                  'bg-primary border-primary': currentStep >= step,
                  'bg-white border-primary': step === currentStep + 1,
                  'bg-white border-grey-200': step > currentStep && step !== currentStep + 1,
                })}
              >
                <IconTick />
              </div>
              {step !== STEP_DATA.length && (
                <StepLine
                  className={cx('right-0', {
                    'bg-primary': currentStep >= step,
                    'bg-grey-200': step > currentStep && step !== currentStep - 1,
                  })}
                />
              )}
            </div>
            <span className="c-welcome-step-progress__label block mt-2">{label}</span>
          </div>
        );
      })}
    </div>
  );
};

export { WelcomeStepProgress };
