const useLastProjectVisited = () => {
  const getLastProjectVisitedPath = (organisationKey: string | string[], projectId: string | string[], userId: number): string => {
    return `${String(organisationKey)}/${String(projectId)}/${userId}`;
  };

  return {
    getLastProjectVisitedPath,
  };
};

export { useLastProjectVisited };
