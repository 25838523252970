import { getEkardoApolloClient } from '@netfront/common-library';
import {
  DBContentGroupTypeType,
  IDBContentGroup,
  ISearchContentGroupsQueryGraphQLResponse,
  ISearchContentGroupsQueryVariables,
  SEARCH_CONTENT_GROUPS_QUERY,
} from '@netfront/ekardo-content-library';

const getContentGroupByType = async (type: DBContentGroupTypeType): Promise<IDBContentGroup[]> => {
  const client = getEkardoApolloClient({
    product: 'KANZI',
  });

  const {
    data: {
      contentGroup: { searchContentGroups },
    },
  } = await client.query<ISearchContentGroupsQueryGraphQLResponse, ISearchContentGroupsQueryVariables>({
    query: SEARCH_CONTENT_GROUPS_QUERY,
    variables: {
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      type,
    },
  });

  return searchContentGroups;
};

export { getContentGroupByType };
