import { gql } from '@apollo/client';

const PLAN_FRAGMENT = gql`
  fragment planFragment on PlanGraphType {
    id
    productId
    active
    product
    description
    name
    price
    stripePlanId
    sort
    createdAtUtc
    value
  }
`;

export { PLAN_FRAGMENT };
