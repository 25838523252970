import { IContactFormFields } from './ContactForm.interfaces';

const CONTACT_FORM_BLOCK_CSS_IDENTIFIERS = Object.freeze({
  form: 'c-contact-form',
});

const CONTACT_FORM_DEFAULTS = Object.freeze({
  heading: 'Contact',
});

const CONTACT_FORM_INITIAL_VALUES: IContactFormFields = {
  email: '',
  firstName: '',
  lastName: '',
  message: '',
  organisationName: '',
  phoneNumber: '',
};

export { CONTACT_FORM_BLOCK_CSS_IDENTIFIERS, CONTACT_FORM_DEFAULTS, CONTACT_FORM_INITIAL_VALUES };
