import { gql } from '@apollo/client';

import { RESTRICTION_FRAGMENT } from '../../../graphql';

const GET_RESTRICTIONS_BY_API_KEY_QUERY = gql`
  query getRestrictionsByApiKey($apiKeyId: Int!) {
    restriction {
      getRestrictionsByApiKey: get(apiKeyId: $apiKeyId) {
        ...restrictionFragment
      }
    }
  }

  ${RESTRICTION_FRAGMENT}
`;

export { GET_RESTRICTIONS_BY_API_KEY_QUERY };
