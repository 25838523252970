import React, { useState } from 'react';

import { getBEMClassName, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, Input } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';

import { SUBSCRIBE_FORM_BLOCK_CSS_IDENTIFIERS, SUBSCRIBE_FORM_INITIAL_VALUES, DEFAULT_LIST_ID } from './SubscribeForm.constants';
import { SubscribeFormProps, ISubscribeFormFields } from './SubscribeForm.interfaces';

import 'yup-phone';

import { useToast } from '../../../hooks';
import { addUserToEmailSubscription } from '../../../utils';

const SubscribeForm = ({ listId = DEFAULT_LIST_ID }: SubscribeFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    email: { id: emailId, label: emailLabel, placeholder: emailPlaceholder },
    firstName: { id: firstNameId, label: firstNameLabel, placeholder: firstNamePlaceholder },
    lastName: { id: lastNameId, label: lastNameLabel, placeholder: lastNamePlaceholder },
  } = FORM_FIELDS;

  const { form: formBlockCssId } = SUBSCRIBE_FORM_BLOCK_CSS_IDENTIFIERS;

  const { control, handleSubmit, reset } = useForm<ISubscribeFormFields>({
    defaultValues: SUBSCRIBE_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const { handleToastError, handleToastSuccess } = useToast();
  return (
    <>
      <form
        className={getBEMClassName(formBlockCssId, formElementCssId)}
        onSubmit={
          handleSubmit(({ email, firstName, lastName }: ISubscribeFormFields) => {
            const fullName = `${String(firstName)} ${String(lastName)}`;
            setIsSubmitting(true);

            addUserToEmailSubscription({
              emailAddress: email,
              listId,
              name: fullName,
              onSuccess: () => {
                reset(SUBSCRIBE_FORM_INITIAL_VALUES);
                setIsSubmitting(false);
                handleToastSuccess({
                  message: `Successfully subscribed: ${email}`,
                  durationInMilliseconds: 4000,
                });
              },
              onError: (errorText: string) => {
                setIsSubmitting(false);
                handleToastError({
                  error: errorText,
                });
              },
            });
          })
        }
      >
        <div className="c-form__header" />
        <div className="c-form__body mb-6">
          <div className="flex flex-wrap items-center -mx-2">
            <div className="w-full lg:w-1/2 px-2">
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={`${getBEMClassName(formBlockCssId, firstNameId)} bg-white`}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${firstNameId}-subscribe`}
                    labelText={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    type="text"
                    isLabelHidden
                    isRequired
                    {...field}
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 px-2">
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={`${getBEMClassName(formBlockCssId, lastNameId)} bg-white`}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${lastNameId}-subscribe`}
                    labelText={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    type="text"
                    isLabelHidden
                    isRequired
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          <div className="c-form__field w-full">
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <Input
                  additionalClassNames={`${getBEMClassName(formBlockCssId, emailId)} bg-white`}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id={`${emailId}-subscribe`}
                  labelText={emailLabel}
                  placeholder={emailPlaceholder}
                  type="email"
                  isLabelHidden
                  isRequired
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="c-form__footer flex flex-col items-start">
          <Button isDisabled={isSubmitting} text="Subscribe" type="submit" />
        </div>
      </form>
    </>
  );
};

export { SubscribeForm };
