const getPlanUsageWithCeiling = (allowedTranslate: number, remainingTranslate: number) => {
  return remainingTranslate > allowedTranslate ? allowedTranslate : allowedTranslate - remainingTranslate;
};

export const getPlanUsagePercentage = (allowedTranslate: number, remainingTranslate: number) => {
  return (getPlanUsageWithCeiling(allowedTranslate, remainingTranslate) / allowedTranslate) * 100;
};

export const getPlanUsageText = (allowedTranslate: number, remainingTranslate: number) => {
  return `${getPlanUsageWithCeiling(allowedTranslate, remainingTranslate)} of ${allowedTranslate}`;
};
