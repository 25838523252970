import { GET_PROJECT_QUERY } from './useGetProject.graphql';
import {
  IGetProjectQueryGraphQLResponse,
  IGetProjectQueryVariables,
  IHandleGetProjectParams,
  IUseGetProject,
  IUseGetProjectOptions,
} from './useGetProject.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useGetProject = (options?: IUseGetProjectOptions): IUseGetProject => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetProjectOptions);

  const [executeGetProject, { loading: isLoading }] = useKanziLazyQuery<IGetProjectQueryGraphQLResponse, IGetProjectQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { getProject: project },
        } = data;

        onCompleted({
          project,
        });
      },
      onError,
    },
    query: query ?? GET_PROJECT_QUERY,
    token,
  });

  const handleGetProject = ({ id }: IHandleGetProjectParams) => {
    executeGetProject({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetProject,
    isLoading,
  };
};

export { useGetProject };
