import { gql } from '@apollo/client';

const PROJECT_CONFIGURATION_FRAGMENT = gql`
  fragment projectConfigurationFragment on ProjectConfigurationGraphType {
    colour
    cornerRadius
    hasAccessibility
    hasDownloadOption
    hasPdf
    hasSpeech
    hasStartFrom
    hasPdf
    hasTranslate
    hasVolumeControl
    id
    isActiveWordBold
    isActiveWordColored
    isActiveWordUnderlined
    isCollapse
    isDraggable
    isSentenceUnderlined
    isSticky
    languages
    offset
    position
    sentenceBackGroundColor
    size
    theme
  }
`;

export { PROJECT_CONFIGURATION_FRAGMENT };
