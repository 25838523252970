import { gql } from '@apollo/client';

const GET_PLANS_QUERY = gql`
  query getPlans($currency: ECurrency, $product: EProduct!) {
    plans {
      getPlans: getAll(product: $product) {
        id
        productId
        active
        product {
          product
          id
        }
        description
        name
        price(currency: $currency)
        stripePlanId
        sort
        createdAtUtc
        value
      }
    }
  }
`;

export { GET_PLANS_QUERY };
