import parseISO from 'date-fns/parseISO';

import { IDBPdfUsage, IPdfUsage } from '../../../interfaces';

const getUpdatedPdfUsage = (pdfUsage: IDBPdfUsage): IPdfUsage => {
  const { date } = pdfUsage;

  return {
    ...pdfUsage,
    dateLocaleDateString: parseISO(date).toLocaleDateString(),
  };
};

export { getUpdatedPdfUsage };
