import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { SEND_MAILS_MUTATION } from './useSendMails.graphql';
import {
  ISendMailsMutationGraphQLResponse,
  ISendMailsMutationVariables,
  IUseSendMailsOptions,
  IUseSendMail,
} from './useSendMails.interfaces';

import { IDBSendMailsNotificationGroup } from '../../../interfaces';

const useSendMails = (options?: IUseSendMailsOptions): IUseSendMail => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSendMailsOptions);

  const [executeSendMails, { loading: isLoading }] = useHowlerMutation<ISendMailsMutationGraphQLResponse, ISendMailsMutationVariables>({
    mutation: mutation ?? SEND_MAILS_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data: ISendMailsMutationGraphQLResponse) => {
        if (!onCompleted) {
          return;
        }

        const {
          notification: {
            sendMails: { id },
          },
        } = data;

        onCompleted({
          id,
        });
      },
      onError,
    },
    token,
  });

  const handleSendMails = async ({ notifications, projectId, subject, templateId }: IDBSendMailsNotificationGroup) => {
    await executeSendMails({
      variables: {
        notifications,
        projectId,
        subject,
        templateId,
      },
    });
  };

  return {
    handleSendMails,
    isLoading,
  };
};

export { useSendMails };
