const IconMenu = () => {
  return (
    <svg height="21" viewBox="0 0 30 21" width="30" xmlns="http://www.w3.org/2000/svg">
      <g id="hamburger-menu-icon" transform="translate(1.5 1.5)">
        <path
          d="M4.5,18H18"
          data-name="Path 41521"
          fill="none"
          id="Path_41521"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          transform="translate(9 -9)"
        />
        <path
          d="M4.5,9h27"
          data-name="Path 41522"
          fill="none"
          id="Path_41522"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          transform="translate(-4.5 -9)"
        />
        <path
          d="M4.5,27h27"
          data-name="Path 41523"
          fill="none"
          id="Path_41523"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          transform="translate(-4.5 -9)"
        />
      </g>
    </svg>
  );
};

export { IconMenu };
