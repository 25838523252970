import { useEffect, useState } from 'react';

import { Input, Spinner, Tooltip } from '@netfront/ui-library';

import { useApiKeyContext } from '../../../../contexts';
import { useToast, useUpdateApiKey } from '../../../../hooks';
import { IApiKey } from '../../../../interfaces';

const AnalyticsView = () => {
  const { dispatch, state: apiKeys = [] } = useApiKeyContext();
  const { handleToastError } = useToast();

  const [selectedApiKey, setSelectedApiKey] = useState<IApiKey | undefined>(apiKeys.find(({ isSelected }) => isSelected));

  const { handleUpdateApiKey, isLoading: isUpdateApiKeyLoading = false } = useUpdateApiKey({
    onCompleted: ({ apiKey: returnedApiKey }) => {
      setSelectedApiKey(returnedApiKey);

      dispatch({
        payload: {
          apiKey: returnedApiKey,
        },
        type: 'updateApiKey',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!apiKeys.length) {
      return;
    }

    setSelectedApiKey(apiKeys.find(({ isSelected }) => isSelected));
  }, [apiKeys]);

  if (!selectedApiKey) {
    return null;
  }

  const isLoading = isUpdateApiKeyLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />

      <div className="flex flex-col p-4">
        <section className="flex flex-1 flex-col pb-8">
          <div className="flex flex-row">
            <div className="flex flex-1">
              <h3 className="mb-2">Google Measurement Id</h3>
            </div>

            <Tooltip
              additionalClassNames="c-style-settings__tooltip"
              iconId="id_tooltip_icon"
              placement="left"
              text="Add in the Google Measurement Id to start tracking different feature usage in your project Google Analytics account"
            />
          </div>

          <Input
            id="google-measurement-id"
            labelText=""
            name="google-measurement-id"
            type="text"
            value={selectedApiKey.googleMeasurementKey}
            onChange={({ target: { value } }) => {
              handleUpdateApiKey({
                id: selectedApiKey.id,
                googleMeasurementKey: value,
                useDebounce: true,
              });
            }}
          />
        </section>
      </div>
    </>
  );
};

export { AnalyticsView };
