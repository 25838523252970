import { Tab } from '@headlessui/react';

import { TabButtonProps } from './TabRound.interfaces';

const TabRound: React.FC<TabButtonProps> = ({ children }) => {
  return (
    <Tab
      className={({ selected }) =>
        `font-body weight-600 py-3 px-7 rounded-full border-none pointer outline-none ${
          selected ? 'bg-primary scale-2' : 'bg-grey-200 color-grey-800'
        }`
      }
    >
      {children}
    </Tab>
  );
};

export { TabRound };
