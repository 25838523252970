import { gql } from '@apollo/client';

import { API_KEY_FRAGMENT } from '../../../graphql';

const GET_API_KEYS_BY_PROJECT_QUERY = gql`
  query getApiKeysByProject($projectId: String!) {
    apiKey {
      getApiKeysByProject: getByProject(projectId: $projectId) {
        ...apiKeyFragment
      }
    }
  }

  ${API_KEY_FRAGMENT}
`;

export { GET_API_KEYS_BY_PROJECT_QUERY };
