import { gql } from '@apollo/client';

const UPDATE_DEFAULT_PAYMENT_METHOD_MUTATION = gql`
  mutation updateDefaultPaymentMethod($cardId: String!, $projectId: String!, $product: EProduct!) {
    subscriptions {
      changeDefaultPaymentMethod(input: { cardId: $cardId, projectId: $projectId, product: $product }) {
        result
      }
    }
  }
`;

export { UPDATE_DEFAULT_PAYMENT_METHOD_MUTATION };
