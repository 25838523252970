import { gql } from '@apollo/client';

const GET_ACTIVE_PLANS_QUERY = gql`
  query getActivePlans($projectId: String!, $product: EProduct!, $currency: ECurrency) {
    plans {
      getActivePlans: getActive(projectId: $projectId, product: $product) {
        id
        productId
        active
        product {
          product
        }
        description
        name
        price(currency: $currency)
        stripePlanId
        sort
        createdAtUtc
        value
      }
    }
  }
`;

export { GET_ACTIVE_PLANS_QUERY };
