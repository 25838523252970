import React from 'react';

import { PublicFormCardProps } from './PublicFormCard.interfaces';

const PublicFormCard: React.FC<PublicFormCardProps> = ({ children, description, image, title }) => {
  return (
    <div className="relative flex flex-col bg-white mt-16 rounded p-8 pt-24 w-full md:max-w-xl mx-auto fade-in">
      <div className="absolute left-1/2 top-0 -translate-y-1/2">
        <div className="-translate-x-1/2">{image}</div>
      </div>
      <div className="text-center mb-8">
        <h1 className="h2 text-uppercase mb-2">{title}</h1>
        <p>{description}</p>
      </div>
      {children}
      <footer className="mt-auto text-center">
        <small>&copy;{new Date().getFullYear()} All Rights Reserved</small>
      </footer>
    </div>
  );
};

export { PublicFormCard };
