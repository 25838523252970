import { IApolloConnectionEdge } from '@netfront/common-library';

import { GET_PROJECT_USAGE_QUERY } from './useGetProjectUsage.graphql';
import { getUpdatedProjectUsage } from './useGetProjectUsage.helpers';
import {
  IGetProjectUsageQueryGraphQLResponse,
  IGetProjectUsageQueryVariables,
  IHandleGetProjectUsageParams,
  IUseGetProjectUsage,
  IUseGetProjectUsageOptions,
} from './useGetProjectUsage.interfaces';

import { IProjectUsage } from '../../../interfaces';
import { useKanziLazyQuery } from '../../apollo';

const useGetProjectUsage = (options?: IUseGetProjectUsageOptions): IUseGetProjectUsage => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetProjectUsageOptions);

  const [executeGetProjectUsage, { loading: isLoading }] = useKanziLazyQuery<
    IGetProjectUsageQueryGraphQLResponse,
    IGetProjectUsageQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          report: {
            getProjectUsage: { edges, pageInfo, totalCount },
          },
        } = data;

        const updatedEdges = edges.map((edge) => {
          const { node } = edge;

          const updatedEdge: IApolloConnectionEdge<IProjectUsage> = {
            ...edge,
            node: getUpdatedProjectUsage(node),
          };

          return updatedEdge;
        });

        onCompleted({
          edges: updatedEdges,
          pageInfo,
          totalCount,
        });
      },
      onError,
    },
    query: query ?? GET_PROJECT_USAGE_QUERY,
    token,
  });

  const handleGetProjectUsage = ({ after, filter, first, from, ianaTimeZone, projectId, to }: IHandleGetProjectUsageParams) => {
    executeGetProjectUsage({
      variables: {
        after,
        filter,
        first,
        from,
        ianaTimeZone,
        projectId,
        to,
      },
    });
  };

  return {
    handleGetProjectUsage,
    isLoading,
  };
};

export { useGetProjectUsage };
