import { ChangeEvent, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, Form, Input, Spacing, Textarea } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';

import { emailMarkupToASCII, handleGetScriptMessage } from './InstructionsEmailForm.helpers';

import {
  KANZI_SUPPORT_EMAIL_ADDRESS,
  ScriptInstructionsProps,
  INSTALLATION_GUIDE_EMAIL_TEMPLATE_ID,
  InstructionsEmailFormProps,
} from '../../../components';
import { ISendMailsOnCompletedResponse, useSendMails, useToast } from '../../../hooks';

const InstructionsEmailForm = ({ apiKeyGuid, loggedUser, onSuccess, isApp, projectName }: InstructionsEmailFormProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [scriptInstructions, setScriptInstructions] = useState<ScriptInstructionsProps>({
    email: '',
    message: '',
    subject: '',
  });

  const handleSendMailsCompleted = (data?: ISendMailsOnCompletedResponse) => {
    if (!data) {
      return;
    }

    handleToastSuccess({
      message: 'Email sent successfully',
    });

    if (onSuccess) {
      onSuccess();
    }
  };

  const handleChangeScriptInstruction = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    setScriptInstructions({ ...scriptInstructions, [name]: value });
  };

  const handleSendScriptEmail = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { subject, message } = scriptInstructions;

    const emailMessage = emailMarkupToASCII(String(message));

    handleSendMails({
      notifications: [
        { receiver: `${String(scriptInstructions.email)}`, placeholders: [{ key: '[body]', value: emailMessage }] },
        { receiver: KANZI_SUPPORT_EMAIL_ADDRESS, placeholders: [{ key: '[body]', value: emailMessage }] },
      ],
      subject: String(subject),
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      templateId: INSTALLATION_GUIDE_EMAIL_TEMPLATE_ID,
    });
  };

  const handleSendMailsError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
    });
  };

  const { handleSendMails, isLoading: isSendMailsLoading = false } = useSendMails({
    onCompleted: handleSendMailsCompleted,
    onError: handleSendMailsError,
  });

  useEffect(() => {
    if (isEmpty(loggedUser) && !apiKeyGuid) {
      return;
    }

    setScriptInstructions({
      email: loggedUser.credential.email,
      subject: 'Kanzi installation instructions',
      message: handleGetScriptMessage(`${loggedUser.firstName} ${loggedUser.lastName}`, apiKeyGuid, projectName, isApp),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, apiKeyGuid]);

  return (
    <Form className="c-general-view__instructions-modal-form" onSubmit={handleSendScriptEmail}>
      <Spacing>
        <div className="c-general-view__instructions-modal-form-item c-general-view__instructions-modal-form-item__input">
          <Input
            id="email"
            labelText="To"
            name="email"
            type="text"
            value={scriptInstructions.email}
            onChange={handleChangeScriptInstruction}
          />
        </div>
      </Spacing>
      <Spacing>
        <div className="c-general-view__instructions-modal-form-item c-general-view__instructions-modal-form-item__input">
          <Input
            id="subject"
            labelText="Subject"
            name="subject"
            type="text"
            value={scriptInstructions.subject}
            onChange={handleChangeScriptInstruction}
          />
        </div>
      </Spacing>
      <Spacing>
        <div className="c-general-view__instructions-modal-form-item c-general-view__instructions-modal-form-item__textarea">
          <Textarea
            id="message"
            labelText="Message"
            name="message"
            value={scriptInstructions.message}
            onChange={handleChangeScriptInstruction}
          />
        </div>
      </Spacing>
      <div className="c-general-view__instructions-modal-form-item c-general-view__instructions-modal-form-item__button">
        <div />
        <Button isDisabled={isSendMailsLoading} text="Send email" type="submit" />
      </div>
    </Form>
  );
};

export { InstructionsEmailForm };
