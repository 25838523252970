import { ProjectConfigurationLanguageType } from '../../interfaces';

const LANGUAGES: Record<ProjectConfigurationLanguageType, string> = {
  AR: 'ARABIC',
  CY: 'WELSH',
  DA: 'DANISH',
  DE: 'GERMAN',
  EN: 'ENGLISH',
  ES: 'SPANISH',
  FR: 'FRENCH',
  HI: 'HINDI',
  IS: 'ICELANDIC',
  IT: 'ITALIAN',
  JA: 'JAPANESE',
  KO: 'KOREAN',
  NO: 'NORWEGIAN',
  NL: 'DUTCH',
  PL: 'POLISH',
  PT: 'PORTUGESE',
  RO: 'ROMANIAN',
  RU: 'RUSSIAN',
  SV: 'SWEDISH',
  TR: 'TURKISH',
  VI: 'VIETNAMESE',
  ZH: 'CHINESE',
};

export { LANGUAGES };
