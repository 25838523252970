import { gql } from '@apollo/client';

const DELETE_PAYMENT_METHOD_MUTATION = gql`
  mutation deletePaymentMethod($projectId: String!, $cardId: String!, $product: EProduct!) {
    subscriptions {
      deletePaymentMethod(input: { projectId: $projectId, cardId: $cardId, product: $product }) {
        result
        __typename
      }
      __typename
    }
  }
`;

export { DELETE_PAYMENT_METHOD_MUTATION };
