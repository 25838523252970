import { getUpdatedApiKey } from '../apiKey.helpers';

import { CREATE_API_KEY_MUTATION } from './useCreateApiKey.graphql';
import {
  ICreateApiKeyMutationGraphQLResponse,
  ICreateApiKeyMutationVariables,
  IHandleCreateApiKeyParams,
  IUseCreateApiKeyOptions,
  IUseCreateApiKey,
} from './useCreateApiKey.interfaces';

import { useKanziMutation } from '../../apollo';

const useCreateApiKey = (options?: IUseCreateApiKeyOptions): IUseCreateApiKey => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateApiKeyOptions);

  const [executeCreateApiKey, { loading: isLoading }] = useKanziMutation<
    ICreateApiKeyMutationGraphQLResponse,
    ICreateApiKeyMutationVariables
  >({
    mutation: mutation ?? CREATE_API_KEY_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          apiKey: { createApiKey: apiKey },
        } = data;

        onCompleted({
          apiKey: getUpdatedApiKey(apiKey),
        });
      },
      onError,
    },
    token,
  });

  const handleCreateApiKey = async ({ projectId, title, type }: IHandleCreateApiKeyParams) => {
    await executeCreateApiKey({
      variables: {
        projectId,
        title,
        type,
      },
    });
  };

  return {
    handleCreateApiKey,
    isLoading,
  };
};

export { useCreateApiKey };
