import { IOption } from '@netfront/ui-library';

import { ISelectedPlan } from './ProPlanCard.interfaces';

import { IPlan } from '../../interfaces';

const getPlanById = (planId: number, plans: IPlan[]): IPlan | undefined => {
  return plans.find((plan) => plan.id === planId);
};

const getPlanOptions = (plans: IPlan[], productId: number): IOption[] => {
  const options =  plans
    .filter(({ productId: planProductId }) => planProductId === productId)
    .map(({ description, id, price }) => {
      const option: IOption = {
        id,
        name: String(description),
        value: price,
      };

      return option;
    });

  return options
};

const getTotalPrice = (productPlan: ISelectedPlan): number => {
  return Number(
    Object.values(productPlan).reduce((accumulator: number, plan: IPlan | undefined) => {
      const price = plan ? plan.price : 0;
      return accumulator + price;
    }, 0),
  );
};

const CurrencySymbol = {
  AUD: '$',
  EUR: '€',
  USD: '$',
  YEN: '¥',
  YUAN: '¥',
  GBP: '£',
};

export { CurrencySymbol, getPlanById, getPlanOptions, getTotalPrice };
