import { ChangeEvent, useState } from 'react';

import { Button, ColorPickerWrapper, Input, Select, Spacing, ToggleSwitch } from '@netfront/ui-library';

import { KanziPlugin } from '../KanziPlugin';

import { POSITION_OPTIONS, SIZE_OPTIONS, THEME_OPTIONS } from './StyleSettings.constants';
import { StyleSettingsProps } from './StyleSettings.interfaces';

const StyleSettings = ({ onChange, onSave, projectConfiguration, styles }: StyleSettingsProps) => {
  const { colour = '#4bc2ca', cornerRadius = 0, isCollapse = false, isSticky = false, offset = 0, position, size, theme } = styles;

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: isChecked, name, value, type },
    } = event;

    onChange(name, type === 'checkbox' ? isChecked : value);

    setIsSaveButtonDisabled(false);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;

    onChange(name, value);

    setIsSaveButtonDisabled(false);
  };

  const handleColorPickerChange = (newColor: string) => {
    onChange('colour', newColor);
    setIsSaveButtonDisabled(false);
  };

  const handleOnSave = () => {
    onSave();
    setIsSaveButtonDisabled(true);
  };

  return (
    <div className="c-style-settings">
      <ul className="c-style-settings__list">
        <li className="c-style-settings__item">
          <Select
            hasPadding={false}
            id="position"
            labelText="Position"
            name="position"
            options={POSITION_OPTIONS}
            tooltipPosition="start"
            tooltipText="Control the location of where Kanzi will be displayed to the user on their device"
            value={position}
            isLabelSideBySide
            onChange={handleSelectChange}
          />
        </li>
        <li className="c-style-settings__item">
          <ToggleSwitch
            id="isSticky"
            isChecked={isSticky}
            labelText="Sticky"
            name="isSticky"
            tooltipPosition="start"
            tooltipText="Control whether the Kanzi will be sticky on the device"
            isLabelSideBySide
            onChange={handleInputChange}
          />
        </li>
        <li className="c-style-settings__item">
          <ToggleSwitch
            id="isCollapse"
            isChecked={isCollapse}
            labelText="Collapsed"
            name="isCollapse"
            tooltipPosition="start"
            tooltipText="Minimise the Kanzi tool to reduce the amount of space the tool takes up on screen"
            isLabelSideBySide
            onChange={handleInputChange}
          />
        </li>
        <li className="c-style-settings__item">
          <Select 
            hasPadding={false} 
            id="size" 
            labelText="Size" 
            name="size" 
            options={SIZE_OPTIONS} 
            tooltipPosition="start" 
            tooltipText="Control the size of Kanzi on the screen"
            value={size}
            isLabelSideBySide
            onChange={handleSelectChange}
          />
        </li>
        <li className="c-style-settings__item">
          <ColorPickerWrapper
            color={colour}
            id="colour"
            label="Colour"
            pickerPosition="left"
            tooltipText="Theme the tool to match your primary brand colour"
            type="hex"
            isLabelSideBySide
            onHexChange={handleColorPickerChange}
          />
        </li>
        <li className="c-style-settings__item">
          <Select 
            hasPadding={false} 
            id="theme" 
            labelText="Theme" 
            name="theme" 
            options={THEME_OPTIONS}
            tooltipPosition="start" 
            tooltipText="Choose from a light or dark theme"
            value={theme}
            isLabelSideBySide
            onChange={handleSelectChange}
            />
        </li>
        <li className="c-style-settings__item">
          <Input
            hasPadding={false}
            id="cornerRadius"
            labelText="Corner radius"
            name="cornerRadius"
            tooltipPosition="start"
            tooltipText="Control the curve of the tool edges"
            type="number"
            value={cornerRadius}
            isLabelSideBySide
            onChange={handleInputChange}
          />
        </li>
        <li className="c-style-settings__item">
          <Input
            hasPadding={false}
            id="offset"
            labelText="Offset"
            name="offset"
            tooltipPosition="start"
            tooltipText="Control small adjustments to the control location on the page"
            type="number"
            value={offset}
            isLabelSideBySide
            onChange={handleInputChange}
          />
        </li>
      </ul>

      <Spacing>
        <KanziPlugin projectConfiguration={projectConfiguration} styleSettings={styles} />
      </Spacing>

      <div className="c-style-settings__footer">
        <Button isDisabled={isSaveButtonDisabled} size="xs" text="Save changes" variant="cta" onClick={handleOnSave} />
      </div>
    </div>
  );
};

export { StyleSettings };
