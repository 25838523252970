import { FeaturesTableCheck } from '../FeaturesTableCheck';

import { FeaturesTableProps } from './FeaturesTable.interfaces';

const FeaturesTable = ({ data }: FeaturesTableProps) => {
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="w-1/2">&nbsp;</th>
          <th>
            <div className="relative rounded">
              <p className="h4 font-header bg-yellow-400 rounded-full py-2 px-4 absolute left-1/2 -translate-1/2 no-wrap">Free</p>
              <div className="p-4 pt-6 rounded-t color-white bg-green-400" role="presentation"></div>
            </div>
          </th>
          <th>
            <div className="relative rounded">
              <p className="h4 font-header bg-red-400 color-white rounded-full py-2 px-4 absolute left-1/2 -translate-1/2 no-wrap">
                Plan <span className="none md:inline-block">- recommended</span>
              </p>
              <div className="p-4 pt-6 rounded-t color-white bg-burgundy-400" role="presentation"></div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          const { feature, id, type } = item;

          return (
            <tr key={id}>
              <td className="p-4 border-b border-l border-r border-grey-200">{feature}</td>
              <td className="p-4 text-center border-b border-r border-grey-200">
                {type === 'text' ? item.free : <FeaturesTableCheck isChecked={item.free} />}
              </td>
              <td className="p-4 text-center border-b border-r border-grey-200">
                {type === 'text' ? item.plan : <FeaturesTableCheck isChecked={item.plan} />}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { FeaturesTable };
