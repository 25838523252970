import { FeatureItemList, FeatureItemType } from './FeaturesTable.interfaces';

const PDF_FEATURES: FeatureItemList = [
  {
    feature: 'PDF creation per month',
    free: '10 pages',
    id: 1,
    plan: 'Plan limit',
    tooltip: '',
    type: FeatureItemType.text,
  },
  {
    feature: 'Realtime webpage conversion to PDF',
    free: true,
    id: 2,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: `Accessible PDF's`,
    free: true,
    id: 3,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Fast and affordable',
    free: true,
    id: 4,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
];

const SUPPORT_FEATURES: FeatureItemList = [
  {
    feature: 'Team members',
    free: '1 member',
    id: 1,
    plan: 'Unlimited',
    tooltip: '',
    type: FeatureItemType.text,
  },
  {
    feature: 'Online chat',
    free: true,
    id: 2,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Email support',
    free: true,
    id: 3,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Installation support and education',
    free: false,
    id: 4,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Phone support',
    free: '-',
    id: 5,
    plan: 'Enterprise',
    tooltip: '',
    type: FeatureItemType.text,
  },
];

const TEXT_TO_SPEECH_FEATURES: FeatureItemList = [
  {
    feature: 'Character count per month',
    free: '20k',
    id: 1,
    plan: 'Plan limit',
    tooltip: 'Character count per month',
    type: FeatureItemType.text,
  },
  {
    feature: 'Real-time text-to-speech',
    free: true,
    id: 2,
    plan: true,
    tooltip: 'Real-time text-to-speech',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Enhanced learning',
    free: true,
    id: 3,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Multilingual voice selection',
    free: '1 voice',
    id: 4,
    plan: 'Unlimited',
    tooltip: '',
    type: FeatureItemType.text,
  },
  {
    feature: 'Visual voice tracker',
    free: true,
    id: 5,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Increased engagement',
    free: true,
    id: 6,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Accessibility benefits',
    free: true,
    id: 7,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Fast and affordable',
    free: true,
    id: 8,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
];

const TRANSLATE_FEATURES: FeatureItemList = [
  {
    feature: 'Character count per month',
    free: '20k',
    id: 1,
    plan: 'Plan limit',
    tooltip: '',
    type: FeatureItemType.text,
  },
  {
    feature: 'Multilingual translation',
    free: true,
    id: 2,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Configure 20+ languages',
    free: '3 languages',
    id: 3,
    plan: 'Unlimited',
    tooltip: '',
    type: FeatureItemType.text,
  },
  {
    feature: 'Neural network-based',
    free: false,
    id: 4,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Real-time translation',
    free: true,
    id: 5,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Increased engagement',
    free: true,
    id: 6,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Accessibility benefits',
    free: true,
    id: 7,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
  {
    feature: 'Fast and affordable',
    free: true,
    id: 8,
    plan: true,
    tooltip: '',
    type: FeatureItemType.checked,
  },
];

export { PDF_FEATURES, SUPPORT_FEATURES, TEXT_TO_SPEECH_FEATURES, TRANSLATE_FEATURES };
