import { IAvailableVoice } from 'interfaces';
import orderBy from 'lodash.orderby';

import { GET_AVAILABLE_VOICES_QUERY } from './useGetAvailableVoices.graphql';
import {
  IGetAvailableVoicesQueryGraphQLResponse,
  IUseGetAvailableVoices,
  IUseGetAvailableVoicesOptions,
} from './useGetAvailableVoices.interfaces';

import { useKanziLazyQuery } from '../../apollo';

const useGetAvailableVoices = (options?: IUseGetAvailableVoicesOptions): IUseGetAvailableVoices => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAvailableVoicesOptions);

  const [executeGetAvailableVoices, { loading: isLoading }] = useKanziLazyQuery<IGetAvailableVoicesQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          availableVoice: { getAvailableVoices: availableVoices },
          countryEnums: { enumValues },
        } = data;

        const languageKeyValueMap: Record<string, string> = enumValues.reduce(
          (accumulator, { key, value }) => ({
            ...accumulator,
            [key]: value,
          }),
          {},
        );

        const updatedAvailableVoices = availableVoices.map((availableVoice) => {
          const { language } = availableVoice;

          const updatedAvailableVoice: IAvailableVoice = {
            ...availableVoice,
            language: languageKeyValueMap[language],
          };

          return updatedAvailableVoice;
        });

        const sortedAvailableVoices = orderBy(updatedAvailableVoices, 'language');

        onCompleted({
          availableVoices: sortedAvailableVoices,
        });
      },
      onError,
    },
    query: query ?? GET_AVAILABLE_VOICES_QUERY,
    token,
  });

  const handleGetAvailableVoices = () => {
    executeGetAvailableVoices();
  };

  return {
    handleGetAvailableVoices,
    isLoading,
  };
};

export { useGetAvailableVoices };
