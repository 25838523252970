import { gql } from '@apollo/client';

export const ACTIVATE_PRODUCT = gql`
  mutation activateProduct($projectId: String!, $name: String!, $organisationId: Int!) {
    projects {
      activateProduct(input: { product: KANZI, projectId: $projectId, organisationId: $organisationId, name: $name }) {
        result {
          result
        }
      }
    }
  }
`;
