import React from 'react';

import { Button, Select } from '@netfront/ui-library';

import { PaginationProps } from './Pagination.interfaces';

const Pagination = ({
  isNextButtonDisabled,
  isPreviousButtonDisabled,
  onNextButtonClick,
  onPageSizeChange,
  onPreviousButtonClick,
  tablePageNumber,
  tablePageSize,
  tablePageSizeOptions,
  totalTablePages,
}: PaginationProps) => {
  return (
    <>
      {totalTablePages > 0 && (
        <div className="c-pagination">
          <div className="flex gap-2">
            <div className="flex">
              <Button
                isDisabled={isPreviousButtonDisabled}
                size="xs"
                text="Previous page"
                type="button"
                onClick={() => onNextButtonClick(tablePageNumber + 1)}
              />
            </div>
            <div className="flex">
              <Button
                isDisabled={isNextButtonDisabled}
                size="xs"
                text="Next page"
                type="button"
                onClick={() => onPreviousButtonClick(tablePageNumber - 1)}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <span>
              Page <strong>{tablePageNumber}</strong> of <strong>{totalTablePages}</strong>
            </span>
          </div>
          <div className="flex">
            <Select
              id="page-sizes"
              labelText="Page size"
              name="page-sizes"
              options={tablePageSizeOptions}
              value={tablePageSize}
              onChange={onPageSizeChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { Pagination };
