import { gql } from '@apollo/client';

import { STRIPE_CARD_FRAGMENT } from 'graphql/fragments/stripeCard.fragment';

const CREATE_PAYMENT_METHOD_MUTATION = gql`
  mutation createPaymentMethod($projectId: String!, $token: String!, $product: EProduct!) {
    subscriptions {
      addPaymentMethod: addPaymentMethod(input: { projectId: $projectId, token: $token, product: $product }) {
        card {
          ...stripeCardFragment
        }
        __typename
      }
      __typename
    }
  }

  ${STRIPE_CARD_FRAGMENT}
`;

export { CREATE_PAYMENT_METHOD_MUTATION };
