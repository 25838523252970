const PrivateFooter = () => {
  return (
    <footer className="bg-grey-900 color-white py-4 px-6">
      <div className="flex flex-col items-center md:flex-row">
        <div className="flex-1">
          <small className="mb-0">&copy; {new Date().getFullYear()} Kanzi</small>
        </div>
      </div>
    </footer>
  );
};

export { PrivateFooter };
