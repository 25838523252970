import { ISubscribeFormFields } from './SubscribeForm.interfaces';

const SUBSCRIBE_FORM_BLOCK_CSS_IDENTIFIERS = Object.freeze({
  form: 'c-subscribe-form',
});

const SUBSCRIBE_FORM_DEFAULTS = Object.freeze({
  heading: 'Subscribe',
});

const SUBSCRIBE_FORM_INITIAL_VALUES: ISubscribeFormFields = {
  email: '',
  firstName: '',
  lastName: '',
};

const DEFAULT_LIST_ID = String(process.env.REACT_APP_CREATE_SEND_LIST_ID_REGISTERED);

export { SUBSCRIBE_FORM_BLOCK_CSS_IDENTIFIERS, SUBSCRIBE_FORM_DEFAULTS, SUBSCRIBE_FORM_INITIAL_VALUES, DEFAULT_LIST_ID };
