import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Button, Input } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import { isValidUrl } from 'utils';
import * as yup from 'yup';

import { ICreateProjectFormFields, CreateProjectFormProps } from './CreateProjectForm.interfaces';
import styles from './CreateProjectForm.module.css';

const CreateProjectForm = ({
  defaultValue,
  isApp = false,
  isSubmitting,
  label = 'Project name',
  onSubmit,
  placeholder,
}: CreateProjectFormProps) => {
  const { control, handleSubmit } = useForm<ICreateProjectFormFields>({
    defaultValues: { project: defaultValue },
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        project: yup.string().test('test-url', '', (value, context) => {
          if (!isApp && !isValidUrl(String(value))) {
            return context.createError({ message: 'A valid URL is required' });
          } else {
            return true;
          }
        }),
      }),
    ),
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <form
      className={styles['c-create-project-form']}
      onSubmit={handleSubmit(({ project }: ICreateProjectFormFields) => {
        onSubmit({
          project,
        });
      })}
    >
      <div className={styles['c-create-project-form__body']}>
        <div className={styles['c-create-project-form__field']}>
          <Controller
            control={control}
            name="project"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="project"
                isDisabled={isSubmitting}
                labelText={label}
                placeholder={placeholder}
                type="text"
                isLabelHidden
                isRequired
                {...field}
              />
            )}
          />
        </div>
      </div>
      <Button isDisabled={isSubmitting} text="Next" type="submit" variant="primary" />
    </form>
  );
};

export { CreateProjectForm };
