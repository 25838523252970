import { IIcon } from './Icons.interfaces';

const IconPdf = ({ color }: IIcon) => {
  return (
    <svg aria-hidden="true" className="c-kanzi-action-button__icon" id="kanzi-pdf-icon" style={{ color }} viewBox="0 0 21.127 24.648">
      <path
        d="M22.762,7.43a3.2,3.2,0,0,1,.66,1.045,3.174,3.174,0,0,1,.275,1.21V25.531a1.315,1.315,0,0,1-1.32,1.32H3.891a1.315,1.315,0,0,1-1.32-1.32V3.524A1.315,1.315,0,0,1,3.891,2.2H16.215a3.175,3.175,0,0,1,1.21.275,3.2,3.2,0,0,1,1.045.66ZM16.655,4.074V9.246h5.172a1.5,1.5,0,0,0-.3-.564L17.219,4.377a1.5,1.5,0,0,0-.564-.3Zm5.282,21.017V11.006H16.215a1.315,1.315,0,0,1-1.32-1.32V3.964H4.331V25.091H21.937Zm-7.07-8.156a10.465,10.465,0,0,0,1.155.77,13.649,13.649,0,0,1,1.609-.1q2.022,0,2.435.674a.665.665,0,0,1,.028.715.04.04,0,0,1-.014.028l-.028.028v.014q-.083.523-.977.523a5.674,5.674,0,0,1-1.582-.275,10.029,10.029,0,0,1-1.788-.729,24.767,24.767,0,0,0-5.392,1.142q-2.1,3.6-3.329,3.6a.8.8,0,0,1-.385-.1l-.33-.165q-.014-.014-.083-.069a.568.568,0,0,1-.083-.5,2.987,2.987,0,0,1,.77-1.259,6.647,6.647,0,0,1,1.816-1.327A.2.2,0,0,1,9.008,20a.079.079,0,0,1,.028.055q.715-1.169,1.472-2.71a20.993,20.993,0,0,0,1.431-3.6,11.119,11.119,0,0,1-.42-2.194A5.348,5.348,0,0,1,11.607,9.8q.151-.55.578-.55h.3a.58.58,0,0,1,.481.206,1.1,1.1,0,0,1,.124.935.3.3,0,0,1-.055.11.358.358,0,0,1,.014.11v.413a18.011,18.011,0,0,1-.193,2.641,6.959,6.959,0,0,0,2.008,3.274ZM6.945,22.588a6.073,6.073,0,0,0,1.884-2.173,7.841,7.841,0,0,0-1.2,1.155A4.572,4.572,0,0,0,6.945,22.588ZM12.419,9.934a4.09,4.09,0,0,0-.028,1.816q.014-.1.1-.605,0-.041.1-.591a.309.309,0,0,1,.055-.11.04.04,0,0,1-.014-.028.028.028,0,0,0-.007-.021.028.028,0,0,1-.007-.021.792.792,0,0,0-.179-.5.04.04,0,0,1-.014.028v.028Zm-1.706,9.092a20.175,20.175,0,0,1,3.906-1.114,2.075,2.075,0,0,1-.179-.131,2.464,2.464,0,0,1-.22-.186,7.286,7.286,0,0,1-1.747-2.421,18.38,18.38,0,0,1-1.142,2.71q-.413.77-.619,1.142Zm8.885-.22a3.292,3.292,0,0,0-1.926-.33,5.2,5.2,0,0,0,1.706.385,1.334,1.334,0,0,0,.248-.014q0-.014-.028-.041Z"
        data-name="Icon metro-file-pdf"
        fill="currentColor"
        id="Icon_metro-file-pdf"
        transform="translate(-2.571 -2.204)"
      />
    </svg>
  );
};

export { IconPdf };
