import React from 'react';

import { Icon, Tooltip } from '@netfront/ui-library';
import cx from 'classnames';

import { ExternalLinkProps } from './ExternalLink.interfaces';

const ExternalLink = ({
  additionalClassNames,
  href,
  isLinkVisible,
  isTooltipVisible = false,
  tooltipText = 'Click to open the link',
}: ExternalLinkProps) => {
  return (
    <div className={cx(additionalClassNames, 'c-external-link')}>
      {isLinkVisible && <span>{href}</span>}
      <a className="c-external-link__tooltip-icon-link" href={href} rel="noopener noreferrer" target="_blank" title="Referrer">
        {isTooltipVisible && (
          <Tooltip additionalClassNames="c-external-link__tooltip" iconId="id_tooltip_icon" placement="right" text={tooltipText} />
        )}
        <Icon className="c-external-link__new-window-icon" id="id_new_window_icon" />
      </a>
    </div>
  );
};

export { ExternalLink };
