import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PARTNER_LOGO_BRAND_QUERY } from './useGetPartnerLogoBrand.graphql';
import {
  IGetPartnerLogoBrandQueryGraphQLResponse,
  IGetPartnerLogoBrandQueryVariables,
  IHandleGetPartnerLogoBrandParams,
  IUseGetPartnerLogoBrand,
  IUseGetPartnerLogoBrandOptions,
} from './useGetPartnerLogoBrand.interfaces';

const useGetPartnerLogoBrand = (options?: IUseGetPartnerLogoBrandOptions): IUseGetPartnerLogoBrand => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetPartnerLogoBrandOptions);

  const [executeGetPartnerLogoBrand, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetPartnerLogoBrandQueryGraphQLResponse,
    IGetPartnerLogoBrandQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          partner: { getForProject },
        } = data;

        onCompleted({
          partnerBrand: getForProject,
        });
      },
      onError,
    },
    query: query ?? GET_PARTNER_LOGO_BRAND_QUERY,
    token,
  });

  const handleGetPartnerLogoBrand = async ({ id }: IHandleGetPartnerLogoBrandParams) => {
    await executeGetPartnerLogoBrand({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetPartnerLogoBrand,
    isLoading,
  };
};

export { useGetPartnerLogoBrand };
