import { gql } from '@apollo/client';

const GET_PLAN_QUERY = gql`
  query getPlan($id: Int!, $currency: ECurrency) {
    plans {
      getPlan: get(id: $id) {
        id
        productId
        active
        product {
          product
        }
        description
        name
        price(currency: $currency)
        stripePlanId
        sort
        createdAtUtc
        value
      }
    }
  }
`;

export { GET_PLAN_QUERY };
