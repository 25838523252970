/* eslint-disable @typescript-eslint/indent */
import { DELETE_PAYMENT_METHOD_MUTATION } from './useDeletePaymentMethod.graphql';
import {
  IDeletePaymentMethodMutationGraphQLResponse,
  IDeletePaymentMethodMutationVariables,
  IHandleDeletePaymentMethodParams,
  IUseDeletePaymentMethodOptions,
  IUseDeletePaymentMethod,
} from './useDeletePaymentMethod.interfaces';

import { useMuriquiMutation } from '../../apollo';

const useDeletePaymentMethod = (options?: IUseDeletePaymentMethodOptions): IUseDeletePaymentMethod => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeletePaymentMethodOptions);

  const [executeDeletePaymentMethod, { loading: isLoading }] = useMuriquiMutation<
    IDeletePaymentMethodMutationGraphQLResponse,
    IDeletePaymentMethodMutationVariables
  >({
    mutation: mutation ?? DELETE_PAYMENT_METHOD_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          subscriptions: { deletePaymentMethod },
        } = data;

        onCompleted({});
      },
      onError,
    },
    token,
  });

  const handleDeletePaymentMethod = async ({ cardId, projectId, product }: IHandleDeletePaymentMethodParams) => {
    cardId = cardId;
    await executeDeletePaymentMethod({
      variables: {
        cardId,
        projectId,
        product,
      },
    });
  };

  return {
    handleDeletePaymentMethod,
    isLoading,
  };
};

export { useDeletePaymentMethod };
