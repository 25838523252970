import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { IDBContentPage, useSearchContentGroups } from '@netfront/ekardo-content-library';
import { Button, CardList, CoverLink, IImage, PagePreviewCard } from '@netfront/ui-library';
import cx from 'classnames';
import NextImage from 'next/image';
import { useRouter } from 'next/router';

import { PARTNERS_EMAIL_TEMPLATE_ID } from './PartnersPage.constants';
import styles from './PartnersPage.module.css';

import { ISendContactFormOnCompletedResponse, useSendContactForm, useToast } from '../../../hooks';
import { ClientsBanner } from '../../ClientsBanner';
import { ContactForm } from '../../Forms';
import { PageTitleBanner } from '../../PageTitleBanner';
import { PublicPageLayout } from '../../PublicPageLayout';

const PartnersPage = () => {
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [blogPages, setBlogPages] = useState<IDBContentPage[]>();

  const handleSendContactFormCompleted = (data?: ISendContactFormOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { isCompleted } = data;

    if (isCompleted) {
      push(`/thank-you`).catch((error) => {
        handleToastError({
          error,
        });
      });

      return;
    }

    const {
      MESSAGES: {
        ERROR: { UNEXPECTED },
      },
    } = COMMON_LIBRARY_CONSTANTS;

    handleToastError({
      error: new Error(UNEXPECTED),
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleSendContactFormError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleSendContactForm, isLoading: isHandleSendContactFormLoading = false } = useSendContactForm({
    onCompleted: handleSendContactFormCompleted,
    onError: handleSendContactFormError,
  });

  const { handleSearchContentGroups, isLoading: isSearchContentGroupsLoading = false } = useSearchContentGroups({
    onCompleted: ({ contentGroups }) => {
      const [contentPages] = contentGroups.map((contentGroup) => contentGroup.contentPages);

      setBlogPages(contentPages);
    },
    onError: (error: ApolloError) => {
      console.error(error);
    },
  });

  useEffect(() => {
    void handleSearchContentGroups({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      shouldIncludeAssetPresignedUrl: true,
      type: 'BLOG',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blogItems = blogPages?.slice(0, 3).map(({ asset, title, url, tags }) => {
    const { presignedUrl } = asset ?? {};

    const blogImage: IImage = {
      altText: title,
      src: String(presignedUrl),
    };

    return (
      <PagePreviewCard
        key={title}
        coverLink={<CoverLink href={`/blog/${url}`} supportiveText={`Go to article about ${title}`} />}
        image={blogImage}
        isHero={tags?.find((r) => r.name === 'Top Blog') !== undefined}
        subTitle={tags?.[0]?.name}
        title={title}
      />
    );
  });

  const isLoading = isSearchContentGroupsLoading || isHandleSendContactFormLoading;

  return (
    <PublicPageLayout
      hasContainer={false}
      isPreloaderVisible={isLoading}
      meta={{
        description: 'Expand your digital offering and help your clients communicate with a wider audience all in one line of code',
        metaTitle: 'Join the Kanzi Partner Program',
      }}
    >
      <PageTitleBanner
        afterTitle="Help your clients marketing thrive with seamless dynamic QR code generation and reporting"
        containerClassName="c-container--xs"
        title="Join the partner program"
      >
        <Button
          additionalClassNames={styles['c-partner-page__join-partner-button']}
          linkButton={{
            id: 'partner-request-button',
            url: '#partner-sign-up',
          }}
          text="Partner request"
          variant="secondary"
        />
      </PageTitleBanner>
      <section className="bg-white" id="learn-more">
        <div className="c-container ">
          <div className={styles['c-partners-page__benefits-wrapper']}>
            <div className={styles['c-partner-page__scan-graphic-container']}>
              <NextImage
                alt="Configure screen"
                className={styles['c-partner-page__scan-graphic']}
                layout="fill"
                loading="lazy"
                src="/images/partners-mac-icon.svg"
              />
            </div>
            <div className=" sm:w-2/3 pb-8">
              <p className="text-uppercase weight-500 mb-6">Help your client while earning revenue</p>
              <h2 className={styles['c-partner-page__section-heading']}>Benefits of becoming a partner</h2>
              <p>An easy, hassle-free partnership with major benefits for you and your clients.</p>
              <div className="flex flex-col md:flex-row mb-12">
                <ul className="pl-6 mb-0 mr-8">
                  <li className="ml-0">Instant page translation</li>
                  <li className="ml-0">Instant text-to-speech</li>
                  <li className="ml-0">Speech &amp; tracking highlighting</li>
                  <li className="ml-0">Mobile friendly</li>
                  <li className="ml-0">Cost-effective pricing</li>
                  <li className="ml-0">Track your usage</li>
                </ul>
                <ul className="pl-6 mb-0">
                  <li className="ml-0">Multilingual pdf generation</li>
                  <li className="ml-0">Multiple languages</li>
                  <li className="ml-0">Language appropriate voices</li>
                  <li className="ml-0">Manage multiple projects</li>
                  <li className="ml-0">Flexible customisations</li>
                  <li className="ml-0">Drag and drop</li>
                </ul>
              </div>
              <Button
                additionalClassNames={styles['c-partner-page__join-partner-button']}
                linkButton={{
                  id: 'start-today-button',
                  url: '#partner-sign-up',
                }}
                text="Start today"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-wave bg-gradient-wave--minimal py-16 md:py-32 relative color-white">
        <div className="c-container">
          <div className="flex flex-wrap">
            <div className={styles['c-partner-page__feature-section']}>
              <h3 className="h2 font-header">Speech &amp; Translate</h3>
              <p>
                Instant page translation and text to speech option from the one easy to use mobile freidly customisable tool. Translated
                content is spoken in a language appropriate voice.
              </p>
            </div>
            <div className={styles['c-partner-page__feature-section']}>
              <h3 className="h2 font-header">Pricing &amp; Reporting</h3>
              <p>
                Friendly tiered pricing with a free tier for low usage. Pay for what you use and see real time updates of usage. Full usage
                tracking included and added to your monthly invoice.
              </p>
            </div>
            <div className={cx(styles['c-partner-page__feature-section'], styles['c-partner-page__feature-section--has-no-border'])}>
              <h3 className="h2 font-header">Projects &amp; Security</h3>
              <p>
                Restrict access to your Kanzi player to selected domains using URL restrictions. Set up multiple projects to track and bill
                independent of the project.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="c-container">
          <p className={styles['c-partner-page__section-intro-text']}>Accessibility with the click of a button</p>
          <div className={styles['c-partner-page__agency-wrapper']}>
            <div className={styles['c-partner-page__agency-content']}>
              <h2 className={styles['c-partner-page__section-heading']}>Benefits of Kanzi</h2>
              <div className="flex flex-col md:flex-row mb-6 md:mb-0">
                <p>
                  Not only are you giving your customers a better browsing experience on your website, but you&#39;re also gaining access to
                  an entirely new market. Accessibility online is a big deal nowadays, and the more inclusive you are with your website, the
                  more inclusive your brand becomes. The more inclusive your brand becomes, the more likely you are to receive higher
                  traffic numbers and more organic leads.
                </p>
              </div>
              <Button
                additionalClassNames={styles['c-partner-page__join-partner-button']}
                linkButton={{
                  id: 'partner-sign-up-button',
                  url: '#partner-sign-up',
                }}
                text="Partner sign up"
              />
            </div>
            <div>
              <NextImage
                alt="Qr code"
                className={styles['c-partner-page__qr-code-icon']}
                height={300}
                loading="lazy"
                src="/images/partners-icon.svg"
                width={300}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-wave bg-gradient-wave--minimal relative color-white" id="partner-sign-up">
        <div className="c-container py-20">
          <div className="text-center mb-16">
            <h3 className="h2 font-header">Request to become a partner</h3>
            <p>Become a partner QuickCodes team using the partner form below.</p>
          </div>
          <div className="max-w-lg mx-auto">
            <ContactForm
              isSubmitting={isLoading}
              onSend={({ email, firstName, lastName, message: value, organisationName, phoneNumber }) =>
                handleSendContactForm({
                  email,
                  firstName,
                  lastName,
                  organisationName,
                  phoneNumber,
                  value,
                  templateId: PARTNERS_EMAIL_TEMPLATE_ID,
                })
              }
            />
          </div>
        </div>
      </section>

      <section>
        <div className="c-container py-20 text-center">
          <h3 className="h2 font-header">Learn more through our blogs</h3>
          {blogItems ? <CardList items={blogItems} /> : null}
        </div>
      </section>

      <ClientsBanner />
    </PublicPageLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export { PartnersPage };
