import { gql } from '@apollo/client';

const EXPORT_USAGE_REPORT_QUERY = gql`
  query exportUsageReport($from: DateTime, $ianaTimeZone: String!, $projectId: String!, $to: DateTime) {
    report {
      exportUsageReport: exportUsage(from: $from, ianaTimeZone: $ianaTimeZone, projectId: $projectId, to: $to) {
        presignedUrl
      }
    }
  }
`;

export { EXPORT_USAGE_REPORT_QUERY };
