import { useEffect, useState } from 'react';

import { Button, ButtonIconOnly, Icon } from '@netfront/ui-library';
import { useApproveRestriction, useGetApiKey } from 'hooks';
import { IDBApiKey, IDBRestriction } from 'interfaces';

import { PollingBannerProps } from './PollingBanner.interfaces';

type PolledStatusType = 'PENDING_INSTALLATION' | 'INSTALLATION_SUCCESS' | 'VERIFIED' | null;

const PollingBanner = ({ project }: PollingBannerProps) => {
  const [apiKey, setApiKey] = useState<IDBApiKey>();
  const [approvedRestriction, setApprovedRestriction] = useState<IDBRestriction>();
  const [isPendingRestrictionApproved, setIsPendingRestrictionApproved] = useState<boolean>(false);
  const [isPollingBannerVisible, setIsPollingBannerVisible] = useState<boolean>(true);
  const [pendingRestriction, setPendingRestriction] = useState<IDBRestriction>();
  const [polledStatus, setPolledStatus] = useState<PolledStatusType>();

  const { handleGetApiKey } = useGetApiKey({
    onCompleted: ({ apiKey: { monitoringStatus, id, restrictions } }) => {
      const restrictionApproved = restrictions?.find((r) => r.hasBeenApproved);
      const restrictionPending = restrictions?.find((r) => !r.hasBeenApproved);

      setApprovedRestriction(restrictionApproved);
      setPendingRestriction(restrictionPending);
      setPolledStatus(monitoringStatus);

      if (monitoringStatus === 'INSTALLATION_SUCCESS' || monitoringStatus === 'PENDING_INSTALLATION') {
        setTimeout(() => {
          handleGetApiKey({ id });
        }, 60000);
      }
    },
  });

  const { handleApproveRestriction } = useApproveRestriction({
    onCompleted: () => {
      setIsPendingRestrictionApproved(true);
    },
  });

  useEffect(() => {
    if (!project) {
      return;
    }

    const { apiKeys } = project;
    const apiKeyItem = apiKeys?.[0];

    if (!apiKeyItem) {
      return;
    }

    const approvedRestrictionItem = apiKeyItem.restrictions?.find((r) => r.hasBeenApproved);
    const pendingRestrictionItem = apiKeyItem.restrictions?.find((r) => !r.hasBeenApproved);

    setApiKey(apiKeyItem);
    setApprovedRestriction(approvedRestrictionItem);
    setPendingRestriction(pendingRestrictionItem);
  }, [project]);

  useEffect(() => {
    if (!apiKey) {
      return;
    }

    const { monitoringStatus, id } = apiKey;

    if (monitoringStatus === 'INSTALLATION_SUCCESS' || monitoringStatus === 'PENDING_INSTALLATION') {
      handleGetApiKey({
        id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey]);

  if (!project || !apiKey || !isPollingBannerVisible) {
    return <></>;
  }

  const { monitoringStatus } = apiKey;

  switch (polledStatus) {
    case 'VERIFIED':
      return (
        <div className="c-border-installation-success py-4 px-2">
          <div className="c-container flex gap-4">
            <Icon className="c-polling-banner__icon" id="id_success_icon" />
            <div className="flex flex-col flex-1 mt-1">
              <span className="h4 font-body bold weight-700 mb-0">Installation complete!</span>
              <span className="mt-1">Kanzi has successfully been installed!</span>
            </div>
            <ButtonIconOnly
              additionalClassNames="c-banner-polling-close-button"
              iconId="id_close_icon"
              isIconBorderVisible={false}
              text=""
              onClick={() => setIsPollingBannerVisible(false)}
            />
          </div>
        </div>
      );

    case 'INSTALLATION_SUCCESS':
      return (
        <div className="c-border-installation-success py-4 px-2">
          <div className="c-container flex gap-4">
            <Icon className="c-polling-banner__icon" id="id_success_icon" />
            <div className="flex flex-col flex-1 mt-1">
              <span className="h4 font-body bold weight-700 mb-0">Installation complete!</span>
              {(isPendingRestrictionApproved || !pendingRestriction) && (
                <span className="mt-1">Kanzi has successfully been installed onto your site!</span>
              )}
              {!isPendingRestrictionApproved && pendingRestriction && (
                <>
                  <span>
                    Well done! you are one step closer to using Kanzi. We noticed you configured {approvedRestriction?.value} to use Kanzi
                    however we can see the plugin has been loaded on {pendingRestriction.value}. If you own {pendingRestriction.value},
                    click approve and your Kanzi setup is complete alternatively decline the request.
                  </span>
                  <Button text="Approve" onClick={() => handleApproveRestriction({ id: pendingRestriction.id, validate: true })} />
                  <Button
                    text="Decline"
                    variant="danger"
                    onClick={() => handleApproveRestriction({ id: pendingRestriction.id, validate: false })}
                  />
                </>
              )}
            </div>
            <ButtonIconOnly
              additionalClassNames="c-banner-polling-close-button"
              iconId="id_close_icon"
              isIconBorderVisible={false}
              text=""
              onClick={() => setIsPollingBannerVisible(false)}
            />
          </div>
        </div>
      );
  }

  switch (monitoringStatus) {
    case 'PENDING_INSTALLATION':
      return (
        <div className="c-border-pending-installation">
          <div className="flex gap-4">
            <Icon className="c-polling-banner__icon" id="id_warning_icon" />
            <div className="flex flex-col flex-1">
              <span className="h4 font-body bold weight-700 mb-0">Installation pending!</span>
              <span className="mt-1">
                Your plugging is all set up and ready for installation, we are monitoring the script and will let you know when it&apos;s
                set up correctly!
              </span>
            </div>
            <ButtonIconOnly
              additionalClassNames="c-banner-polling-close-button"
              iconId="id_close_icon"
              isIconBorderVisible={false}
              text=""
              onClick={() => setIsPollingBannerVisible(false)}
            />
          </div>
        </div>
      );
  }

  return <></>;
};

export { PollingBanner };
