import { useEffect, useState } from 'react';

import {
  IGeladaOrganisation,
  IGeladaProject,
  IGetGeladaOrganisationsForConnectedUserOnCompletedResponse,
  IGetGeladaProjectsOnCompletedResponse,
  IUpdateGeladaOrganisationOnCompletedResponse,
  useGetGeladaOrganisationsForConnectedUser,
  useGetGeladaProjects,
  useProtectedRoute,
  useUpdateGeladaOrganisation,
} from '@netfront/gelada-identity-library';
import { InformationBox } from '@netfront/ui-library';
import { maxBy } from 'lodash';
import { useRouter } from 'next/router';

import { BusinessInformationForm, WelcomeStepProgress } from '../../../components';
import { useToast } from '../../../hooks';
import { PageLayout } from '../../PageLayout';

const BusinessDetailsPage = () => {
  const PAGE_TITLE = 'Tell us about you';

  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [organisation, setOrganisation] = useState<IGeladaOrganisation>();
  const [project, setProject] = useState<IGeladaProject>();

  const handleUpdateGeladaOrganisationCompleted = (data?: IUpdateGeladaOrganisationOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const {
      organisation: { key },
    } = data;

    push(`/dashboard/${key}/${String(project?.id)}`).catch((error) => {
      handleToastError({
        error,
      });
    });
  };

  const handleGetGeladaOrganisationsForConnectedUserCompleted = (data?: IGetGeladaOrganisationsForConnectedUserOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { geladaOrganisations } = data;

    const latestOrganisation = maxBy(geladaOrganisations, 'id');
    setOrganisation(latestOrganisation);

    handleGetGeladaProjects({
      organisationKey: String(latestOrganisation?.key),
    });
  };

  const handleGetGeladaProjectsCompleted = (data?: IGetGeladaProjectsOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { geladaProjects } = data;
    const currentProject = maxBy(geladaProjects, 'id');
    setProject(currentProject);
  };

  const { handleGetGeladaOrganisationsForConnectedUser } = useGetGeladaOrganisationsForConnectedUser({
    onCompleted: handleGetGeladaOrganisationsForConnectedUserCompleted,
  });

  const { handleUpdateGeladaOrganisation, isLoading: isUpdateGeladaOrganisationLoading = false } = useUpdateGeladaOrganisation({
    onCompleted: handleUpdateGeladaOrganisationCompleted,
  });

  const { handleGetGeladaProjects } = useGetGeladaProjects({
    onCompleted: handleGetGeladaProjectsCompleted,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetGeladaOrganisationsForConnectedUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <PageLayout
      additionalClassNames="c-page-layout-template--narrow"
      isPreloaderVisible={isUpdateGeladaOrganisationLoading}
      meta={{
        description: 'Configure your first project to get started using Kanzi',
        metaTitle: PAGE_TITLE,
      }}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {isAuthenticated && (
        <>
          <div className="flex flex-col items-center mb-8">
            <img alt="Kanzi logo" className="w-full max-w-16 mb-6" src="/images/kanzi-logo-icon.svg" />
            <h1 className="h2 mb-2">{PAGE_TITLE}</h1>
          </div>

          <WelcomeStepProgress currentStep={3} />

          <InformationBox
            additionalClassNames="mb-8"
            iconId="id_info_icon"
            message="We would love to get some basic info about your business. If you want to do this later just click &lsquo;Skip&rsquo;."
          />

          {organisation && (
            <BusinessInformationForm
              isSubmitting={isUpdateGeladaOrganisationLoading}
              onSubmit={async ({
                geoCode: { city, postcode, state, country },
                businessAddress,
                businessName,
                mobilePhoneNumber,
                phoneNumber,
              }) => {
                const { description, id, tag, url } = organisation;
                await handleUpdateGeladaOrganisation({
                  address: {
                    city,
                    postcode,
                    state,
                    country,
                    line1: businessAddress,
                    line2: '',
                    line3: '',
                  },
                  description,
                  id,
                  name: businessName,
                  tag,
                  url,
                  mobilePhoneNumber,
                  phoneNumber,
                });
              }}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export { BusinessDetailsPage };
