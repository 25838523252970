import React, { createContext, useContext, useReducer } from 'react';

import { IContextProvider } from '@netfront/common-library';

import { IApiKeyContext } from './ApiKeyContext.interfaces';
import { apiKeyReducer } from './ApiKeyContext.reducer';

import { IApiKey } from '../../interfaces';

const ApiKeyContext = createContext<IApiKeyContext | undefined>(undefined);

const ApiKeyProvider = ({ children, initialState }: IContextProvider<IApiKey[]>) => {
  const [state, dispatch] = useReducer(apiKeyReducer, initialState);

  const value = { dispatch, state };

  return <ApiKeyContext.Provider value={value}>{children}</ApiKeyContext.Provider>;
};

const useApiKeyContext = (): IApiKeyContext => {
  const apiKeyContext = useContext(ApiKeyContext);
  if (!apiKeyContext) {
    throw new Error('useApiKeyContext must be used within a ApiKeyProvider');
  }

  return apiKeyContext;
};

export { ApiKeyProvider, useApiKeyContext };
