const IconMobile = () => {
  return (
    <svg height="22.54" id="mobile-switch" viewBox="0 0 14.088 22.54" width="14.088" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.974,0H2.113A2.114,2.114,0,0,0,0,2.113V20.427A2.114,2.114,0,0,0,2.113,22.54h9.861a2.114,2.114,0,0,0,2.113-2.113V2.113A2.114,2.114,0,0,0,11.974,0ZM7.044,21.131a1.409,1.409,0,1,1,1.409-1.409A1.407,1.407,0,0,1,7.044,21.131Zm4.931-4.755a.53.53,0,0,1-.528.528h-8.8a.53.53,0,0,1-.528-.528V2.641a.53.53,0,0,1,.528-.528h8.8a.53.53,0,0,1,.528.528Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { IconMobile };
