import { YouTubeVideoProps } from './YouTubeVideo.interfaces';
import styles from './YouTubeVideo.module.css';

const YouTubeVideo = ({ src }: YouTubeVideoProps) => {
  return (
    <div className={styles['c-video-wrapper']}>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        height="315"
        src={src}
        title="YouTube video player"
        width="560"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export { YouTubeVideo };
