import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Button, Input } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';

import { AGENCY_WELCOME_FORM_INITIAL_VALUES } from './AgencyWelcomeForm.constants';
import { IAgencyWelcomeFormFields, AgencyWelcomeFormProps } from './AgencyWelcomeForm.interfaces';

const AgencyWelcomeForm = ({ isSubmitting, onSubmit }: AgencyWelcomeFormProps) => {
  const { control, handleSubmit } = useForm<IAgencyWelcomeFormFields>({
    defaultValues: AGENCY_WELCOME_FORM_INITIAL_VALUES,
    mode: 'onBlur',

    reValidateMode: 'onChange',
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <form
      className="c-agency-welcome-form"
      onSubmit={
        handleSubmit(({ organisation, project }: IAgencyWelcomeFormFields) => {
          onSubmit({
            organisation,
            project,
          });
        })
      }
    >
      <div className="c-agency-welcome-form__body">
        <div className="c-agency-welcome-form__field">
          <Controller
            control={control}
            name="organisation"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="organisation"
                isDisabled={isSubmitting}
                labelText="Business name"
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </div>

        <div className="c-agency-welcome-form__field">
          <Controller
            control={control}
            name="project"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="project"
                isDisabled={isSubmitting}
                labelText="Project name"
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </div>
      </div>
      <Button isDisabled={isSubmitting} text="Next" type="submit" variant="primary" />
    </form>
  );
};

export { AgencyWelcomeForm };
